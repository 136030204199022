import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { hasPermissionAccess, hasRoleAccess, roleDashboardPage } from '@/security/access';
import axios from 'axios';
import { useBillingStore } from '@/stores/billing';

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/base/Base.vue'),
    children: [
      {
        path: 'serviceprovider',
        name: 'serviceprovider',
        component: () => import('../views/pages/admin/ServiceProviderControlPanel.vue'),
        meta: {
          permission: 'show_service_provider',
        },
      },
      {
        path: 'serviceprovider/emails/:slug',
        name: 'adminEmailFolder',
        component: () => import('@/views/pages/admin/EmailFolder.vue'),
      },
      {
        path: 'serviceprovider/email-template/:id',
        name: 'editEmailTemplate',
        component: () => import('@/views/pages/admin/EditEmailTemplate.vue'),
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: () => import('@/views/pages/ChangePassword.vue'),
      },
      {
        path: 'new-contact',
        name: 'new-contact',
        component: () => import('../views/pages/admin/NewContact.vue'),
        meta: {
          permission: 'show_new_contact',
        },
      },
      {
        path: 'new-contact/add-pdmr',
        name: 'add-contact-pdmr',
        component: () => import('@/views/pages/admin/AddPdmr.vue'),
        meta: {
          permission: 'show_new_contact',
        },
      },
      {
        path: 'edit-contact/:id',
        name: 'edit-contact',
        component: () => import('../views/pages/admin/NewContact.vue'),
        meta: {
          permission: 'show_new_contact',
        },
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/pages/InventorContactDashboard.vue'),
        meta: {
          permission: 'show_dashboard',
        },
      },
      {
        path: 'pdmr',
        name: 'pdmr',
        component: () => import('../views/pages/pdmr-pca/PDMR.vue'),
        meta: {
          permission: 'show_pdmr',
        },
      },
      {
        path: '/pdmr-fill-missing-data',
        name: 'pdmr-fill-missing-data',
        component: () => import('@/views/pages/pdmr-pca/FinishMissingData.vue'),
      },
      {
        // TODO fix uploading document with an insider info
        path: 'pca',
        name: 'pca',
        component: () => import('../views/pages/pdmr-pca/PCA.vue'),
        meta: {
          permission: 'show_pca',
        },
      },
      {
        path: 'control-panel-insider-list',
        name: 'control-panel-insider-list',
        component: () => import('../views/pages/InsiderListControlPanel.vue'),
        meta: {
          permission: 'show_control_panel_insider_list',
        },
      },
      {
        path: 'control-panel-data-room',
        name: 'control-panel-data-room',
        component: () => import('../views/pages/DataRoomControlPanel.vue'),
        meta: {
          permission: 'show_control_panel_data_room',
        },
      },
      {
        path: 'pdmr-list',
        name: 'pdmr-list',
        component: () => import('../views/pages/pdmr-pca/PDMRList.vue'),
        meta: {
          permission: 'show_pdmr_list',
        },
      },
      {
        path: 'published-reports',
        name: 'published-reports',
        component: () => import('../views/pages/pdmr-pca/PublishedReports.vue'),
        meta: {
          permission: 'show_published_reports',
        },
      },
      {
        path: 'upload-application',
        name: 'upload-application',
        component: () => import('../views/pages/UploadApplication.vue'),
        meta: {
          permission: 'show_upload_application',
        },
      },
      {
        path: 'add-new-transaction',
        name: 'add-new-transaction',
        component: () => import('../views/pages/pdmr-pca/ReportTransactionForm.vue'),
        meta: {
          permission: 'add_new_transaction',
        },
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/pages/authentication/Login.vue'),
      },
      {
        path: 'login-password',
        name: 'login-password',
        component: () => import('../views/pages/authentication/LoginPassword.vue'),
        props: (route) => ({ ...route.query }),
      },
      {
        path: 'login-email-notification',
        name: 'login-email-notification',
        component: () => import('../views/pages/authentication/LoginEmailNotification.vue'),
        props: true,
      },
      {
        path: 'login-code-verification',
        name: 'login-code-verification',
        component: () => import('../views/pages/authentication/LoginCodeVerification.vue'),
        props: true,
      },
      {
        path: 'verify-code',
        name: 'verify-code',
        component: () => import('../views/pages/authentication/LoginCode.vue'),
        props: (route) => ({ ...route.query }),
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: () => import('../views/pages/authentication/ResetPassword.vue'),
      },
      {
        path: 'reset-password/reset-sent',
        name: 'reset-sent',
        component: () => import('../views/pages/authentication/PasswordResetSent.vue'),
        props: (route) => ({ ...route.query }),
      },
      {
        path: 'forgot-password-finalize',
        name: 'new-password',
        component: () => import('../views/pages/authentication/NewPassword.vue'),
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('../views/pages/authentication/Terms.vue'),
      },
      {
        path: '404',
        name: '404',
        component: () => import('../views/pages/authentication/Page404.vue'),
      },
      {
        path: 'thank-you',
        name: 'thank-you',
        component: () => import('../views/pages/authentication/ThankU.vue'),
      },
      {
        path: 'signup',
        name: 'signup',
        component: () => import('../views/pages/authentication/SignUp.vue'),
      },
      {
        path: 'access-person/:id',
        name: 'access-person',
        component: () => import('../views/pages/authentication/MarketDataRoomAccessPerson.vue'),
        meta: {
          permission: 'show_access_person',
        },
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('../views/pages/Settings.vue'),
        meta: {
          permission: 'show_settings',
        },
      },
      {
        path: 'my-logins',
        name: 'my-logins',
        component: () => import('../views/pages/authentication/UserLogins.vue'),
        meta: {
          permission: 'show_my_logins',
        },
      },
      {
        path: 'subscriptions',
        name: 'subscriptions',
        component: () => import('../views/pages/Subscriptions.vue'),
        meta: {
          permission: 'show_subscriptions',
        },
      },
      {
        path: 'packages',
        name: 'packages',
        component: () => import('../views/pages/Packages.vue'),
        meta: {
          permission: 'show_packages',
        },
      },
      {
        path: 'insider-list',
        name: 'insider-list',
        component: () => import('../views/pages/PermanentInsiderList.vue'),
        meta: {
          permission: 'show_insider_list',
        },
      },
      {
        path: 'specific-insiders',
        name: 'specific-insiders',
        component: () => import('../views/pages/current-insider/ListOfTransactionSpecificInsiders.vue'),
        meta: {
          permission: 'show_specific_insiders',
        },
      },
      {
        path: 'specific-insider-details/:id',
        name: 'specific-insider-details',
        component: () => import('../views/pages/current-insider/SpecificInsiderDetails.vue'),
        meta: {
          permission: 'show_specific_insider_details',
        },
      },
      {
        path: 'confidential-list',
        name: 'confidential-list',
        component: () => import('../views/pages/confidential/ConfidentialList.vue'),
        meta: {
          permission: 'show_confidential_list',
        },
      },
      {
        path: 'new-confidential/:id',
        name: 'new-confidential',
        component: () => import('../views/pages/confidential/NewConfidentialForm.vue'),
        meta: {
          permission: 'show_new_confidential',
        },
        props: true,
      },
      {
        path: 'confidential-details/:id',
        name: 'confidential-details',
        component: () => import('../views/pages/confidential/ConfidentialDetails.vue'),
        meta: {
          permission: 'show_confidential_details',
        },
      },
      {
        path: 'period-ban',
        name: 'period-ban',
        component: () => import('../views/pages/PeriodBan.vue'),
        meta: {
          permission: 'show_period_ban',
        },
      },
      {
        path: 'data-rooms',
        name: 'data-rooms',
        component: () => import('../views/pages/data-room/DataRooms.vue'),
        meta: {
          permission: 'show_data_rooms',
        },
      },
      {
        path: 'data-room-details/:id',
        name: 'data-room-details',
        component: () => import('../views/pages/data-room/DataRoomDetails.vue'),
        meta: {
          permission: 'show_data_room_details',
        },
      },
      {
        path: 'data-room-details/:id/folder/:uuid',
        name: 'data-room-folder',
        component: () => import('@/views/pages/data-room/DataRoomFolder.vue'),
      },
      {
        path: 'classification-info',
        name: 'classification-info',
        component: () => import('../views/pages/classification/ClassificationInsiderInfo.vue'),
        meta: {
          permission: 'show_classification_info',
        },
      },
      {
        path: 'new-info-qualifications',
        name: 'new-info-qualifications',
        component: () => import('../views/pages/classification/NewInformationQualification.vue'),
        meta: {
          permission: 'show_new_info_qualifications',
        },
      },
      {
        path: 'info-requalification/:id',
        name: 'info-requalification',
        component: () => import('../views/pages/classification/InformationReQualification.vue'),
        props: true,
        meta: {
          permission: 'show_info_requalification',
        },
      },
      {
        path: 'delay-insider-information/:id',
        name: 'delay-insider-information',
        component: () => import('../views/pages/classification/DelayInsiderInformation.vue'),
        meta: {
          permission: 'show_delay_insider_information',
        },
      },
      {
        path: '101',
        name: 'unupgraded',
        component: () => import('../views/pages/authentication/Page101.vue'),
      },
      {
        path: '401',
        name: 'unauthorized',
        component: () => import('../views/pages/authentication/UnauthorizedPage.vue'),
      },
      {
        path: 'settings/billing-data',
        name: 'billing-data',
        component: () => import('@/views/pages/settings/BillingData.vue'),
      },
      {
        path: 'site-documents',
        name: 'site-documents',
        component: () => import('@/views/pages/SiteDocuments.vue'),
      },
      {
        path: 'site-documents/:id',
        name: 'site-document-edit',
        component: () => import('@/views/pages/SiteDocument.vue'),
      },
      {
        path: 'site-documents/create',
        name: 'site-document-create',
        component: () => import('@/views/pages/NewSiteDocument.vue'),
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/views/pages/PrivacyPolicy.vue'),
      },
      {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import('@/views/pages/TermsAndConditions.vue'),
      },
      {
        path: '/admin-billing',
        name: 'admin-billing',
        component: () => import('@/views/pages/admin/AdminBilling.vue'),
      },
      {
        path: '/admin-billing/clients/:id',
        name: 'admin-client-billing',
        component: () => import('@/views/pages/admin/AdminClientBilling.vue'),
      },
      {
        path: 'new-client',
        name: 'new-client',
        component: () => import('@/views/pages/authentication/NewClient.vue'),
      },
      {
        path: 'pdmr-list/sync-data',
        name: 'sync-data',
        component: () => import('@/views/pages/pdmr-pca/SyncData.vue'),
      },
      {
        path: 'era',
        name: 'era',
        component: () => import('@/views/pages/Era.vue'),
      },
    ],
  },
];

// let accessLevels = ['client-contact', 'pdmr', 'pca', 'insider', 'data-room'];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

function advancedAuthorized(permissions, route) {
  if (route?.meta?.permission) {
    return permissions.includes(route.meta.permission);
  } else {
    return true;
  }
}

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);

  // check if the route exists or not
  if (!to.matched.length) return next({ name: '404' });
  if (to.name === 'unauthorized') return next();

  // Try logging in if it's the first time
  const userStore = useUserStore();
  const billingStore = useBillingStore();

  // Try logging in if it's the first time
  if (!userStore.initialized) {
    userStore.setInitialized(true);
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      if (to.name === 'main') return next({ name: roleDashboardPage.guest });
      if (hasRoleAccess(['guest'], to.name)) return next();
      return next({ name: 'login' });
    }

    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    try {
      await userStore.fetchUser();
      await userStore.fetchDecryptedUser();
      await userStore.fetchRolesAndPermissions();
      await billingStore.fetchBillingData();
      console.log('got user from beforeEach');
    } catch (error) {
      // Handle any error that might occur during user data fetching
      console.error('Error fetching user data:', error);
      return next({ name: 'login' }); // Redirect to login in case of an error
    }
  }

  // Check authorization
  const user = userStore.user;
  const roles = userStore.userAccess.roles;
  const permissions = userStore.userAccess.permissions;
  const isContactClient = roles.includes('client_contact') || roles.includes('delegated_user');

  // Special case: '/' (main) route
  // Redirect to main dashboard or TODO: welcome page if guest/not logged in (for now its login page)

  if (user && !userStore.key) return next();

  if (user && ['pdmr', 'pca'].includes(roles[0]) && !user.pdmrPcaRecord && to.name !== 'pdmr-fill-missing-data')
    return next({ name: 'pdmr-fill-missing-data' });

  if (user && roles.length === 0) {
    if (to.name === 'new-contact') return next();
    return next({ name: 'new-contact' });
  }

  if (user && user.client && to.name === 'new-contact') return next({ name: 'add-contact-pdmr' });

  if (!isContactClient && user && !billingStore.billingData && to.name === 'main')
    return next({ name: 'billing-data' });

  if (to.name === 'main') {
    if (user) return next({ name: roleDashboardPage[roles[0]] });
    return next({ name: roleDashboardPage.guest });
  }

  if (!user) {
    if (hasRoleAccess(['guest'], to.name)) return next();
    return next({ name: 'login' });
  }

  if (to.name === 'login') return next({ name: roleDashboardPage[roles[0]] });

  if (to.name === 'settings' && userStore.userAccess?.roles?.includes('admin')) return next({ name: 'main' });

  if (userStore.isDelegatedUser) {
    console.log(to.name, permissions, hasPermissionAccess(permissions, to.name));
    if (hasPermissionAccess(permissions, to.name)) return next();
    next({ name: 'unauthorized', query: { from: to.path } });
  }

  if (hasRoleAccess([...roles, 'auth'], to.name)) return next();
  return next({ name: 'unauthorized', query: { from: to.path } });
});
export default router;

import { getInstance } from '@/main';
import { ToastPositions, useToast } from 'vue-toast-notification';

export default {
  download(url, filename = '') {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.target = '_BLANK';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  displayAllErrorsAsToasts(error) {
    const toast = useToast({
      position: ToastPositions.TOP_RIGHT,
      timeout: 3000,
    });

    if (error.response?.status === 500) {
      toast.error('Internal server error.');
      return;
    }

    if (error.response?.data?.error) {
      toast.error(error.response.data.error);
      return;
    }

    if (error.response?.data?.errors) {
      for (const value of Object.values(error.response.data.errors)) toast.error(value.join('<br>'));
      return;
    }

    if (Object.entries(error.response?.data?.errors || {}).length === 0 && error.response?.data?.message) {
      toast.error(error.response?.data?.message);
      return;
    }

    if (typeof error.response?.data === 'string') {
      toast.error(error.response.data);
      return;
    }

    if (error.response && getInstance()) {
      if (error.response.status === 401) {
        toast.error('You are not authorized to perform this action.');
      } else if (error.response.status === 403) {
        toast.error('You are not authorized to perform this action.');
      } else if (error.response.status === 404) {
        toast.error('The requested resource was not found.');
      } else if (error.response.status === 500) {
        toast.error('Internal server error.');
      } else if (error.response.status === 422) {
        toast.error('The request was well-formed but was unable to be followed due to semantic errors.');
      } else if (error.response.status === 400) {
        toast.error('The request was not well-formed.');
      } else if (error.response.status === 429) {
        toast.error('Too many requests.');
      } else if (error.response.status === 503) {
        toast.error('Service unavailable.');
      } else if (error.response.status === 504) {
        toast.error('Gateway timeout.');
      } else {
        toast.error('Something went wrong.');
      }
    }
  },
  successToast(message) {
    const toast = useToast({
      position: ToastPositions.TOP_RIGHT,
      timeout: 3000,
    });
    toast.success(message);
  },
  errorToast(message) {
    const toast = useToast({
      position: ToastPositions.TOP_RIGHT,
      timeout: 3000,
    });
    toast.error(message);
  },
  dateFormat(date) {
    if (typeof date === 'string') date = new Date(date);
    return date.toLocaleString('hu-HU');
  },

  getDataCompleteness(object) {
    let fieldsOccupied = 0;

    const keys = Object.keys(object);
    keys.forEach((key, index) => {
      if (object[key] !== null) {
        fieldsOccupied++;
      }
    });
    return `${fieldsOccupied}%`;
  },
  uniqid(prefix = '', random = false) {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, '').padEnd(14, '0');
    return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ''}`;
  },
  scrollTo(h) {
    var url = location.href;
    location.href = '#' + h;
    history.replaceState(null, null, url);
  },
  /**
   * @param {string | Date} date
   * @param {"hu-HU"} locale
   * @return {string}
   */
  formatDate(date, locale = 'hu-HU') {
    const formatter = new Intl.DateTimeFormat(locale);
    if (typeof date === 'string') {
      // change the european date format to american
      const [day, month, year] = date.split('.');
      date = `${month}.${day}.${year}`;
      date = new Date(date);
    }
    return formatter.format(date);
  },
  isNumber(value) {
    return !isNaN(Number(value));
  },
  /**
   * @param {string} value
   * @return {boolean}
   */
  isEmail(value) {
    const re =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(value.toLowerCase());
  },
  /**
   * @param {string} value
   * @return {boolean}
   */
  isPhoneNumber(value) {
    const re = /^(\+36|06)(20|30|70|31|25|1|2|3|4|5|6|7|8|9)\d{7,8}$/;
    return re.test(value);
  },
  /**
   * @param {string} template
   * @return {string}
   */
  parseEmailTemplate(template) {
    return template
      .replace(/A\(z\)\s*(?=[AÁEÉIÍOÓÖŐUÚÜŰaáeéiíoóöőuúüű])/g, 'Az ')
      .replace(/a\(z\)\s*(?=[AÁEÉIÍOÓÖŐUÚÜŰ])/gi, 'az ')
      .replace(/A\(z\)/g, 'A')
      .replace(/a\(z\)/gi, 'a');
  },
};

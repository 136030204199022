{
  "101": {
    "need_upgrade": "Módosítás szükséges",
    "it_seems_like_you_need_to_update": "Úgy tűnik, módosítania kell státuszát, mielőtt továbbléphet erre az oldalra.",
    "go_back": "Vissza",
    "report": "Jelentés"
  },
  "404": {
    "404": "404",
    "it_seems_like_we_ran_into_trouble": "Úgy tűnik, hogy valami probléma merült fel. Kérjük, próbálkozzon később!",
    "go_back": "Vissza",
    "report": "Jelentés"
  },
  "access-person": {
    "download_the_document_list": "Dokumentumlista letöltése",
    "download_all_files": "Az összes fájl letöltése",
    "download_document": "Dokumentum letöltése",
    "file_name": "Fájlnév",
    "uploaded": "Feltöltve",
    "time_of_upload": "Feltöltés ideje"
  },
  "add-new-transaction": {
    "name": "Név",
    "email": "E-mail cím",
    "position": "Beosztás",
    "phone_number": "Telefonszám",
    "this_form_is_required_for_disclosure": "Ez az űrlap a piaci visszaélésről szóló, 2014. április 16-i 596/2014/EU európai parlamenti és tanácsi rendelet (piaci visszaélésről szóló rendelet) 19. cikke szerinti tranzakciók közzétételéhez szükséges.",
    "details_of_the_person_discharging": "1. A vezetői feladatokat ellátó személy/közeli kapcsolatban álló személy adatai",
    "natural_person": "Természetes személy",
    "first_name": "Keresztnév",
    "last_name": "Vezetéknév",
    "legal_person": "Jogi személy",
    "full_name": "Teljes név",
    "modify": "Módosítás",
    "reason_for_the_notification": "2. A bejelentés oka",
    "position_status": "Beosztás/státusz",
    "initial_notification": "Első értesítés",
    "amendment_to_prior_notification": "Előzetes bejelentések módosítása",
    "description": "Részletek",
    "details_of_the_issuer": "3. A kibocsátó, a kibocsátási egység piaci szereplője, az aukciós platform, az aukcióvezető vagy az aukciófigyelő adatai",
    "full_name_of_the_entity": "A személy teljes neve",
    "lei_legal_entity_identifier": "LEI / Jogi személy azonosítója",
    "details_of_the_transaction": "4. Az ügylet(ek) adatai: a szakaszt meg kell ismételni i. minden eszköztípus esetében; ii. minden ügylettípus; iii. minden dátum; és (iv) minden hely ahol tranzakciókat hajtottak végre",
    "details_of_the_transaction_section": "4.{idx}. Az ügylet(ek) adatai: a szakaszt meg kell ismételni i. minden eszköztípus esetében; ii. minden ügylettípus; iii. minden dátum; és (iv) minden hely ahol tranzakciókat hajtottak végre",
    "description_of_the_finincial_instrument": "A pénzügyi eszköz leírása, az eszköz típusa",
    "identification_code": "Azonosító kód",
    "nature_of_the_transaction": "Az ügylet jellege",
    "description_of_the_transaction": "A tranzakció típusának leírása, adott esetben az 596/2014/EU rendelet 19. cikkének (14) bekezdése alapján elfogadott (EU) 2016/5221 felhatalmazáson alapuló bizottsági rendelet 10. cikkében meghatározott tranzakciótípus felhasználásával, vagy egy konkrét példa az 596/2014/EU rendelet 19. cikkének (7) bekezdésében meghatározott. Az 596/2014/EU rendelet 19. cikke (6) bekezdésének e) pontja értelmében fel kell tüntetni, hogy az ügylet részvényopciós program lehívásához kapcsolódik-e.",
    "prices_and_volumes": "Mennyiség(ek) és Ár(ak)",
    "aggregated_volume_and_price": "Összesített mennyiség és ár",
    "time_of_the_transaction": "A tranzakció időpontja",
    "transaction_date_and_time": "A tranzakció időpontja",
    "place_of_the_transaction": "A tranzakció helye",
    "name_and_code_to_identify_the_mifid": "Név és kód annak a MiFID kereskedési helyszínnek, a rendszeres internalizálónak vagy az Unión kívüli szervezett kereskedési platformnak az azonosítására, ahol az ügyletet végrehajtották, a 600/2014/EU európai parlamenti és hatósági rendeletet kiegészítő, felhatalmazáson alapuló bizottsági rendeletben meghatározottak szerint. A Tanács a 600/2014/EU rendelet 26. cikke alapján elfogadott, az ügyletekről az illetékes hatóságoknak történő jelentéstételre vonatkozó szabályozástechnikai standardok tekintetében, vagy ha az ügyletet nem a fent említett helyszínek egyikén hajtották végre, kérjük, említse meg a „kereskedésen kívüli helyszín'.",
    "submit_form": "Űrlap beküldése",
    "where_more_then_one_transaction": "Ha egynél több azonos jellegű tranzakció (vásárlás, eladás, kölcsönadás, kölcsön,…) ugyanazon a pénzügyi eszközön vagy kibocsátási egységen ugyanazon a napon teljesítik  és ugyanazon az ügylet helyén ezen ügyletek árait és mennyiségeit kell feltüntetni ebben a mezőben, a fent bemutatott két oszlopos formában, annyi sort beszúrva, mint szükséges.",
    "new_detail": "Új részlet"
  },
  "billing-data": {
    "name": "Név",
    "zip_code": "Irányítószám",
    "city": "Város",
    "address": "Cím",
    "tax_number": "Adószám",
    "save": "Mentés",
    "billing_title": "Módosítsa számlázási adatait",
    "billing_subtitle": "Adja meg adatait alább",
    "invoices": "Számlák",
    "contracts": "Szerződések",
    "subscriptions": "Előfizetések",
    "view_client": "Ügyfél megtekintése",
    "billing": "Számlázás",
    "create_billingo_partner": "Billingo partner létrehozása",
    "new_invoice": "Új számla",
    "client": "Ügyfél",
    "invoice_number": "Számlaszám",
    "download_invoice": "Számla letöltése",
    "package": "Csomag",
    "period": "Időszak",
    "monthly": "Havi",
    "yearly": "Éves",
    "discard": "Elvetés",
    "date": "Dátum",
    "new_contract": "Új szerződés",
    "save_new_invoice": "Új számla mentése",
    "contract_name": "Szerződés neve",
    "contract_date": "Szerződés dátuma",
    "select_contract": "Válasszon szerződést",
    "download_contract": "Szerződés letöltése",
    "end_date": "Befejezés dátuma",
    "new_subscription": "Új előfizetés",
    "start_date": "Kezdés dátuma",
    "finalize_subscription": "Előfizetés befejezése"
  },
  "charts": {
    "no_data_available": "Nincs megjeleníthető adat",
    "insider_list_information": "Bennfentes lista információ",
    "insider_list_statements": "Bennfentes lista nyilatkozatok",
    "insider_list_count": "Bennfentes lista számláló",
    "insider_list_user_count": "Bennfentes lista felhasználók száma",
    "market_sounding": "Piaci tapogatózá",
    "below_33": "33% alatt",
    "between_33_66": "33% és 66% között",
    "above_66": "66% felett",
    "filled": "Kitöltötte",
    "not_filled": "Nem töltötte ki"
  },
  "classification-info": {
    "qualification_and_delay_of_inside_information": "Bennfentes információ minősítése és késleltetése",
    "add_new_information": "Új információ hozzáadása",
    "current_inside_information": "Aktuális bennfentes információk",
    "previous_inside_information": "Megszűnt bennfentes információk",
    "sensitive_information": "Bennfentes információvá nem minősített információk",
    "search": "Keresés",
    "delay_occurred": "Késleltetés történt",
    "delay": "Késleltetés",
    "make_public": "Közzététel",
    "delete": "Törlés",
    "do_you_want_to_cease": "Megszűnteti az információ bennfentes jellegét?",
    "back": "Vissza",
    "cease": "Megszűntetés",
    "edit": "Szerkesztés",
    "disable": "Letiltás",
    "short_name": "Bennfentes információ neve",
    "date_identified": "Keletkezés időpontja",
    "date_of_delay": "Késleltetés időpontja",
    "delay_occured": "Késleltetve",
    "ceased": "Más okból megszűnt",
    "statement_is": "A feltétel",
    "fulfilled": "teljesült",
    "not_fulfilled": "nem teljesült",
    "reason_for_delay": "Késleltetés oka",
    "reclassify_the_information": "Információ átminősítése",
    "reclassify": "Átminősítés",
    "requalification_of_information": "Információ átminősítése",
    "project_name": "Projekt neve",
    "please_indicate_the_name_of_the_project": "Kérjük, adja meg a projekt nevét (pl. egyesülés, MABU projekt stb.). Fontos, hogy ez a mező nem tartalmazhat semmilyen bennfentes információt.",
    "description_of_the_information": "A minősítendő információ részletei",
    "current_date_and_time": "Date of qualification",
    "it_can_be_modified": "(módosítható)",
    "uploaded_files": "Feltöltött fájlok",
    "upload_file": "Feltöltés",
    "attention_the_insider_information_is_being_created": "Figyelem! Ez a bennfentes információ készül, kérjük, ne zárja be az ablakot, és ne menjen tovább sehova!",
    "attention_the_selected_files_are_uploading": "Figyelem! A kiválasztott fájlok feltöltődnek a szerverre, kérjük, ne zárja be az ablakot, és ne lépjen tovább!",
    "discard": "Elvetés",
    "classify_information": "Információ minősítése",
    "what_do_you_want_to_do_with_the_information": "A bennfentes információt haladéktalanul közzé kell tenni, kivéve, ha a késleltetés feltételei fennállnak. Mit szeretne tenni a bennfentes információval?",
    "immediate_publication": "Közzététel",
    "this_information_does_not_qualify": "Ez az információ nem minősül bennfentes információnak. Szeretne egy bizalmas listát létrehozni?",
    "no": "Nem",
    "yes": "Igen",
    "attention_you_have_not_qualified": "Figyelem! Nem minősítette az összes lehetőséget! Kérjük, töltsön ki minden szükséges mezőt!",
    "ok": "Ok",
    "files_uploaded_successfully": "Fájlok feltöltése sikeres!",
    "successfully_created": "Sikeres létrehozás!",
    "edit_the_information": "Szerkessze az információkat",
    "confirm_edit_the_information": "Megerősítés Szerkessze az információkat",
    "do_you_want_to_Reclassify_the_information": "Át szeretné-e minősíteni az információkat?",
    "save_changes": "Változtatások mentése",
    "download": "Letöltés",
    "conceptual_elements_of_inside_information": "A bennfentes információ fogalmi eleme",
    "add_comment": "Megjegyzés hozzáadása",
    "choose": "Válasszon",
    "download_file": "Fájl letöltése",
    "please_provide_information": "Kérjük adja meg a bennfentes információ közzétételére vonatkozó adatokat!",
    "link": "Link",
    "k_number": "Hivatkozási szám",
    "date_and_time_of_publication": "Közzététel ideje",
    "approve": "Jóváhagyás",
    "reclassification_of_information": "Információ átminősítése",
    "date_of_qualification": "Minősítés időpontja",
    "do_you_want_to_delete_the_insider_information": "Biztosan törölni szeretné a bennfentes információt?",
    "create_confidential_list": "Bizalmas lista létrehozása",
    "confirm": "Megerősítés",
    "qualification_step_1_title": "Cím 1",
    "qualification_step_1_description": "Leírás 1",
    "qualification_step_2_title": "Cím 2",
    "qualification_step_2_description": "Leírás 2",
    "qualification_step_3_title": "Cím 3",
    "qualification_step_3_description": "Leírás 3",
    "qualification_step_4_title": "Cím 4",
    "qualification_step_4_description": "Leírás 4",
    "delay_step_1_title": "Késleltetés címe 1",
    "delay_step_1_description": "Késleltetés leírása 1",
    "delay_step_2_title": "Késleltetés címe 2",
    "delay_step_2_description": "Késleltetés leírása 2",
    "delay_step_3_title": "Késleltetés címe 3",
    "delay_step_3_description": "Késleltetés leírása 3"
  },
  "confidential-details": {
    "confidential_list": "Bizalmas lista",
    "date_placeholder": "YYYY.MM.DD.",
    "date_and_time_of_creation": "A bennfentes jegyzék létrehozásának dátuma és időpontja)",
    "dt_of_last_update": "(A legutolsó frissítés dátuma és időpontja)",
    "a_brief_description_of_the_confidential_information": "Bizalmas lista neve",
    "confidential_list_long_description": "Bizalmas lista leírása",
    "add_new_person": "Új személy felvétele",
    "delete": "Törlés",
    "this_confidential_list_is_empty": "Ezen bizalmas lista jelenleg még üres. Kérjük, adjon hozzá legalább egy személyt!",
    "export_confidential_list": "Bizalmas lista exportálása",
    "convert_to_a_deal_specific_insider_list": "Átalakítás ügyletspecifikus listává",
    "name": "Teljes név",
    "position": "Beosztás",
    "email": "E-mail cím",
    "edit": "Szerkesztés",
    "disable_editing": "Szerkesztés letiltása",
    "name_of_the_insider": "Bennfentes személy neve",
    "data_completeness": "Adatok teljessége",
    "notification_emails": "Értesítési e-mailek",
    "acknowledgements": "Elismerések",
    "last_login": "Utolsó bejelentkezés",
    "save_data": "Save Data",
    "name_of_insider": "Bennfentes neve",
    "email_address": "E-mail cím",
    "back": "Vissza",
    "do_you_want_to_delete_this_insider": "Biztosan törölni szeretné ezt a bennfentes személyt?",
    "download_all_acknowledgements": "Összes elismerő nyilatkozat letöltése",
    "download_all_notification_emails": "Összes értesítés letöltése",
    "dt_of_creation_of_permanent_insider_section": "(A jegyzék létrehozásának dátuma és időpontja)",
    "date_of_transmission_to_competent_authority": "(Az illetékes hatósághoz történő továbbítás dátuma",
    "export_the_permanent_insider_list": "Állandó bennfentes jegyzék exportálása",
    "first_name": "Keresztnév",
    "surname": "Vezetéknév",
    "birth_surname": "Születési név",
    "professional_telephone_number": "Munkahelyi telefonszám",
    "company_name": "Társaság neve",
    "company_address": "Társaság címe",
    "function_and_reason_for_being_insider": "Funkció és a bennfentesség oka",
    "function_and_reason_for_being_insider_title": "A szerepet, funkciót és az ebben a jegyzékben való szerepeltetés okát leíró szöveg.",
    "function_and_reason_for_being_insider_placeholder": "Funkció és a bennfentesség oka",
    "included": "Felvétel időpontja",
    "dt_of_persons_inclusion": "A bennfentes állandó bennfentesek szakaszába való felvételének dátuma és időpontja (yyyy-mm-dd, hh:mm UTC).",
    "birthdate": "Születési dátum",
    "national_id_card_number": "Személyi igazolvány szám",
    "personal_telephone_number": "Telefonszám",
    "personal_full_home_address": "Lakcím",
    "personal_full_home_address_title": "Ország, irányítószám, város, utca, házszám",
    "mar_18_notifications": "MAR 18(2) értesítések",
    "mar_18_acknowledgements": "MAR 18(2) elismerő nyilatkozatok",
    "not_logged_in_yet": "Nem lépett be",
    "select_version": "Válasszon verziót",
    "permanent_insider_list": "Állandó bennfentesek jegyzéke",
    "insider": "bennfentes",
    "notifications_and_acknowledgements": "Értesítések és nyilatkozatok",
    "last_name": "Vezetéknév",
    "birth_last_name": "Születési név",
    "dt_of_the_entry": "A bennfentes állandó bennfentesek szakaszába való felvételének dátuma és időpontja. (yyyy-mm-dd, hh: dd UTC)",
    "reason_for_listing": "Funkció és a bennfentesség oka",
    "add_the_person_as_central_contract_point": "Központi kapcsolattartóként kerül felvételre?",
    "yes": "Igen",
    "no": "Nem",
    "notify_the_insider": "A bennfentes személy értesítése a bennfentes jegyzékbe vétel tényéről, a vonatkozó jogi és szabályozási kötelezettségekről, valamint a bennfentes kereskedelem és a bennfentes információk jogosulatlan közzététele tekintetében alkalmazandó szankciókról a MAR 18. cikk (2) bekezdése alapján.",
    "confirm": "Megerősítés",
    "download": "Letöltés",
    "date_sent": "Küldés dátuma"
  },
  "confidential-list": {
    "closed_confidential_list": "Lezárt bizalmas lista",
    "confidential_list": "Bizalmas lista",
    "create_new_confidential_list": "Új bizalmas lista létrehozása",
    "download": "Letöltés",
    "details": "Részletek",
    "project_name": "A bizalmas információ neve",
    "date_and_time_of_creation": "Létrehozás időpontja",
    "number_of_people": "Résztvevők száma",
    "short_name_of_information": "Információ rövid leírása",
    "dt_placeholder": "dd-mm-yyyy; hh:mm",
    "discard": "Elvetés",
    "create": "Létrehozás",
    "date": "Dátum",
    "transmission_to_authority_at": "Átadás az illetékes hatóságnak",
    "insider_information": "Insider Information",
    "create_insider_information": "Insider információ létrehozása"
  },
  "control-panel-data-room": {
    "market_sounding_data_room": "Piaci tapogatózásos adatszobák",
    "data_room_name": "Adatszoba neve",
    "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba",
    "this_room_contains_insider_information": "Ez a szoba bennfentes információt tartalmaz"
  },
  "dashboard": {
    "todo_item": "Teendő",
    "add_new": "Új hozzáadása",
    "no_permissions": "Nincs hozzáférés",
    "automated_emails": "Automatikus e-mailek",
    "pdrm_emails": "PDMR e-mailek",
    "pca_emails": "PCA e-mailek",
    "emails_related_to_a_permanent_insider_list": "Állandó bennfentesek jegyzékéhez kapcsolódó e-mailek",
    "emails_related_to_a_transaction_specific_insider_list": "Ügyletspecifikus bennfentes jegyzékekhez kapcsolódó e-mailek",
    "emails_related_to_data_rooms": "Adatszobákhoz kapcsolódó e-mailek",
    "statements_and_statistics": "Kimutatások, statisztikák",
    "per": "Per",
    "individual_percentages": "Adatok kitöltöttsége",
    "comapnys_inside_information": "Bennfentes információk",
    "per_specific_confidential_pdmr": "Listák",
    "manage_permissions": "Jogosultságok kezelése",
    "pdmr_pca_emails": "PDMR/PCA e-mailek",
    "emails_related_to_trade_ban": "Kereskedelmi tilalomhoz kapcsolódó e-mailek",
    "show_more": "Továbbiak",
    "show_less": "Kevesebb",
    "search_accounts": "Keresés",
    "fill_in_the_following_data": "Jogosultság módosítása",
    "change_permission": "Jogosultság módosítása",
    "privileges": "Jogosultságok",
    "no_access": "Nincs hozzáférés",
    "read_only": "Csak olvasó",
    "editor": "Szerkesztő",
    "inside_information": "Bennfentes információ minősítése és késleltetése",
    "insider_list": "Bennfentes jegyzék vezetése",
    "pdmr_pca": "PDRM/PCA tranzakciók",
    "data_rooms": "Bennfentes információ átadása (adatszoba)",
    "user_created_successfully": "Felhasználó sikeresen létrehozva",
    "save": "Mentés",
    "discard": "Elvetés",
    "name": "Név",
    "email": "E-mail cím",
    "position": "Pozíció",
    "phone_number": "Telefonszám"
  },
  "data-room-details": {
    "market_sounding_data_room": "Piaci tapogatózás - { dataroom }",
    "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba - { dataroom }",
    "uploaded_files": "Feltöltött fájlok",
    "people_with_access": "Hozzáféréssel rendelkezők",
    "upload_new_documents": "Új dokumentum(ok) feltöltése",
    "delete": "Elvetés",
    "download": "Letöltés",
    "download_document_list": "Dokumentumlista letöltése",
    "download_all_files_zip": "Az összes fájl letöltése",
    "file_name": "Fájlnév",
    "time_of_upload": "Feltöltés időpontja",
    "folder_name": "Mappa neve",
    "open_folder": "Mappa megnyitása",
    "create_new_folder": "Új mappa létrehozása",
    "create": "Létrehozás",
    "discard": "Elvetés",
    "rename": "Átnevezés",
    "this_file_contains_inside_information": "A fájl bennfentes információt tartalmaz.",
    "select_insider_information_from_the_list": "Kérjük, válassza ki az érintett bennfentes információt!",
    "insider_information_related_to_the_file": "A fájlhoz kapcsolódó bennfentes információ(k):",
    "upload_file": "Feltöltés",
    "yes": "Igen",
    "no": "Nem",
    "please_select_the_files": "Kérjük, válassza ki azon fájlokat, amelyek bennfentes információt tartalmaznak!",
    "upload_files": "Feltöltés",
    "please_select_the_file_list": "Kérjük, ellenőrizze a feltölteni kívánt fájlok és a hozzájuk rendelt bennfentes információk helyességét!",
    "grant_access": "Hozzáférés adása",
    "take_away_access_from_everyone": "Hozzáférés elvétele minden személytől",
    "take_away_access": "Visszavonja a hozzáférést",
    "email_notifications": "E-mail értesítés(ek)",
    "acknowledgement": "Elismerő nyilatkozatok",
    "download_list_of_people_with_access": "Hozzáféréssel rendelkező személyek listájának letöltése",
    "download_notification_emails_and_statements": "Összes elismerő nyilatkozat és értesítés letöltése",
    "personal_data": "Személyes adatok",
    "notification_status": "Értesítés hozzáférésről",
    "statement_of_consent_status": "Elismerő nyilatkozatok",
    "termination_of_access": "Hozzáférés megszűnése",
    "telephone_number": "Telefonszám",
    "dt_placeholder": "yyyy.mm.dd; hh:mm",
    "the_person_to_be_added": "A megadott személy még nem szerepel az adatszobában található bennfentes információt tartalmazó fájlhoz tartozó bennfentes listán.",
    "name_of_inside_information": "Bennfentes információ neve",
    "notification_and_insider_listing": "Értesítés és bennfentesjegyzékbe történő felvétel",
    "address": "Hozzáférés",
    "subject": "Tárgy",
    "body": "Tartalom",
    "email": "E-mail",
    "inclusion_email_attachments": "Mellékletek (Ezek a fájlok letölthetők és az értesítő e-mailhez csatolhatók)",
    "send": "Küldés",
    "first_name": "Keresztnév",
    "last_name": "Vezetéknév",
    "phone_number": "Telefonszám",
    "folders_map": "Mappa térkép",
    "upload": "Feltöltés",
    "size": "Méret",
    "modified": "Módosítva",
    "title": "Cím",
    "do_you_want_to_revoke_access_to_this_person": "Biztosan visszavonja a hozzáférést ehhez a személyhez?",
    "do_you_want_to_revoke_access_to_everyone": "Biztosan visszavonja a hozzáférést minden személytől?",
    "delete_dataroom": "Adatszoba törlése",
    "are_you_sure_you_want_to_delete_this_data_room": "Biztosan törölni szeretné ezt az adatszobát?",
    "save": "Mentés",
    "name_of_the_data_room": "Az adatszoba neve",
    "the_information_is_transferred": "Az információ átadása piaci tapogatózás keretében történik.",
    "view_a_guide": "Útmutató megtekintése",
    "comment": "Megjegyzés"
  },
  "data-rooms": {
    "create_a_new_data_room": "Új adatszoba létrehozása",
    "market_sounding_data_room": "Piaci tapogatózásos adatszoba",
    "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba",
    "name_of_the_data_room": "Adatszoba neve",
    "the_information_is_transferred": "Az információ átadása piaci tapogatózás keretében történik.",
    "view_a_guide": "Útmutató megtekintése",
    "create": "Tovább",
    "cancel": "Mégse",
    "next": "Következő"
  },
  "delay-insider-information": {
    "delaying_inside_information": "Bennfentes információ késleltetése",
    "delaying_the_disclosure_of_inside_information": "A belső információk késleltetésének feltételei",
    "current_date_and_time": "Késleltetési döntés időpontja:",
    "it_can_be_modified": "(módosítható)",
    "uploaded_files": "Feltöltött fájlok",
    "upload_file": "Feltöltés",
    "attention_the_selected_files_are_uploading": " Figyelem! A kiválasztott fájlok feltöltődnek a szerverre, kérjük, ne zárja be az ablakot, és ne lépjen tovább!",
    "discard": "Elvetés",
    "save": "Információ késleltetése",
    "the_conditions_of_delaying": "A bennfentes információ késleltetésének feltételei nem teljesülnek, ezért a bennfentes információt haladéktalanul közzé kell tenni!",
    "back": "Mégsem",
    "publish": "Közzététel",
    "the_recorded_delay_data_will_not_be_clumped": "A rögzített késleltetési adatok nem lesznek összevonva, de a bennfentes információk el lesznek mentve!",
    "proceed": "Tovább",
    "attention_you_have_not_qualified": "Figyelem! Nem minősítette az összes lehetőséget! Kérjük, töltsön ki minden szükséges mezőt!",
    "ok": "Ok",
    "files_uploaded_successfully": "Fájlok feltöltése sikeres!"
  },
  "dropdown": {
    "settings": "Beállítások",
    "pdmr": "PDMR",
    "pca": "PCA",
    "dashboard": "Irányítópult",
    "my_subscription": "Előfizetésem",
    "era_form_filler": "ERA űrlapkitöltő",
    "billing_data": "Számlázási adatok",
    "billing": "Számlázás",
    "change_password": "Jelszó módosítása",
    "login_history": "Korábbi bejelentkezések",
    "edit_master_key": "Mesterkulcs szerkesztése",
    "logout": "Kijelentkezés"
  },
  "edit-contact": {
    "contact_details": "Az ügyfél adatai",
    "clients_name": "Teljes név",
    "clients_address": "Cím",
    "clients_company_registration_number": "Cégjegyzékszám",
    "investor_contacts_name": "Kapcsolattartó neve",
    "investor_contacts_position": "Kapcsolattartó beosztása",
    "investor_contacts_email_address": "Kapcsolattartó e-mail címe",
    "investor_contacts_phone_number": "Kapcsolattartó telefonszáma",
    "subscribed_package": "Előfizetett csomag",
    "regulated_market": "Szabályozott piac",
    "sme": "SME",
    "advisory": "Tanácsadó",
    "discard": "Elvetés",
    "save": "Mentés",
    "email_address": "E-mail cím",
    "tax_number": "Adószám",
    "data_protection_officer": "Adatvédelmi tisztviselő"
  },
  "era": {
    "description": "Az ERA felület használatához rendelkezik a Mabureg egy böngészőkiegészítéssel, mely segítségével az űrlapok kitöltése meggyorsítható a Mabureg-ből történő adatok átemelésével.",
    "steps_phrase": "Ennek használatához az alábbi lépéseket kell megtenni:",
    "step_1": "A böngészőkiegészítés az alábbi linkel letölthető (<a href='' target='_blank' class='extension-link'>kiterjesztés link</a>). A böngészőkiegészítés Google Chrome böngészőre telepíthető, de az Edge és az Opera platformokon is elérhető a szolgáltatásunk (ehhez engedélyezni kell a Chrome Webstore-ból történő telepítést).",
    "step_2": "A letöltött böngészőkiegészítést ezt követően telepíteni szükséges, valami engedélyezni. Egyes böngészőverziók esetén ez automatikus.",
    "step_3": "Amennyiben telepítettük, úgy a böngészőkiegészítés már használható is. Ehhez csak annyit kell tenni, hogy az adott felhasználónak bejelentkezik a Mabureg webes alkalmazásba, hiszen a böngészőkiegészítés külön bejelentkezéssel nem rendelkezik, a Mabureg alkalmazást használja és hívja meg, hogy a letölthető űrlapok adatai elérhetőek legyenek benne.",
    "step_4": "Az űrlapok kiöltéséhez az ERA felületén az adott űrlapot meg kell nyitnia, ezt követően pedig a böngészőkiegészítés felkínálja a kitöltési opciókat.",
    "end": "Amennyiben az ERA rendszer frissül, a böngészőkiegészítést is frissíteni kell. Ehhez hibaüzenet figyelmeztet a böngészőkiegészítés."
  },
  "footer": {
    "requesting_an_offer": "Árajánlat kérése",
    "contact_us": "Elérhetőségek",
    "privacy_policy": "Adatkezelési szabályzat",
    "terms_and_conditions": "Általános szerződési feltételek",
    "sitemap": "Oldaltérkép",
    "cookies": "Cookie-k",
    "back_to_top": "Vissza a tetejére",
    "copyright_and_authorship_information": "Szerzői jog",
    "english": "Angol",
    "hungarian": "Magyar",
    "it_security": "IT biztonság",
    "attention_currently_there_is_a_trade-ban_period": "Figyelem! Jelenleg kereskedelmi tilalom van érvényben."
  },
  "globals": {
    "components": {
      "base": {
        "form": {
          "the_password_must_contain_at_least_one_uppercase_letter_and_one_lowercase_letter": "A jelszónak tartalmaznia kell legalább egy kisbetűt és egy nagybetűt.",
          "the_password_must_contain_at_least_one_symbol": "A jelszónak tartalmaznia kell legalább egy szimbólumot.",
          "the_password_must_contain_at_least_one_number": "A jelszónak tartalmaznia kell legalább egy számot.",
          "the_confirm_password_must_match_the_password": "A jelszó és a megerősítés nem egyezik."
        },
        "charts": {
          "no_data_available": "Nincs megjeleníthető adat",
          "insider_list_information": "Bennfentes lista információ",
          "insider_list_statements": "Bennfentes lista nyilatkozatok",
          "insider_list_count": "Bennfentes lista számláló",
          "insider_list_user_count": "Bennfentes lista felhasználók száma",
          "market_sounding": "Piaci tapogatózás"
        }
      },
      "pages": {
        "classification": {
          "general": {
            "short_name": "Bennfentes információ neve",
            "date_identified": "Keletkezés időpontja",
            "date_of_delay": "Késleltetés időpontja",
            "delay_occured": "Késleltetve",
            "ceased": "Más okból megszűnt",
            "statement_is": "A feltétel",
            "fulfilled": "teljesült",
            "not_fulfilled": "nem teljesült",
            "reason_for_delay": "Késleltetés oka"
          },
          "current_insider_info_tab": {
            "make_public": "Közzététel",
            "delete": "Törlés",
            "do_you_want_to_cease": "Megszűnteti az információ bennfentes jellegét?",
            "back": "Vissza",
            "cease": "Megszűntetés",
            "edit": "Szerkesztés",
            "disable": "Letiltás"
          },
          "discontinued_insider_info_tab": {
            "delayed": "Késleltetve",
            "published_ceased": "Közzétéve / Megszűntetve",
            "published": "Közzétéve",
            "delay_not_occured": "Nem történt késleltetés"
          },
          "information_not_classified_tab": {
            "reclassification_of_information": "Információ átminősítése",
            "date_of_qualification": "Minősítés időpontja"
          },
          "qualification_form": {
            "conceptual_elements_of_inside_information": "A bennfentes információ fogalmi eleme",
            "add_comment": "Megjegyzés hozzáadása"
          },
          "static_carousel_content": {
            "comment": "Komment"
          },
          "table_body_details": {
            "decision_information": "A döntés feltételei",
            "download_file": "Letöltés",
            "export": "Exportálás",
            "modify": "Módosítás"
          }
        },
        "common": {
          "general": {
            "name": "Név",
            "email": "E-mail cím",
            "position": "Pozíció",
            "phone_number": "Telefonszám",
            "zip_code": "Irányítószám",
            "city": "Város",
            "address": "Cím",
            "select_contract": "Szerződés kiválasztása",
            "subscriptions": "Előfizetések",
            "contracts": "Szerződések",
            "invoices": "Számlák",
            "view_client": "Ügyfél megtekintése",
            "client": "Ügyfél",
            "create_billingo_partner": "Billingo partner létrehozása",
            "invoice_number": "Számlaszám",
            "download_invoice": "Számla letöltése",
            "new_invoice": "Új számla",
            "save_new_invoice": "Új számla mentése",
            "date": "Dátum",
            "download_contract": "Szerződés letöltése",
            "new_contract": "Új szerződés",
            "upload_contract": "Szerződés feltöltése",
            "package": "Csomag",
            "period": "Időszak",
            "monthly": "Havi",
            "yearly": "Éves",
            "start_date": "Kezdő dátum",
            "end_date": "Befejező dátum",
            "new_subscription": "Új előfizetés",
            "finalize_subscription": "Előfizetés véglegesítése (nem újul meg)",
            "save": "Mentés",
            "delay_occurred": "Késleltetés történt",
            "do_you_want_to_delete_the_insider_information": "Biztosan törölni szeretné a bennfentes információt?",
            "create_confidential_list": "Bizalmas lista létrehozása",
            "discard": "Elvetés"
          },
          "company_info": {
            "name": "Cégnév",
            "company_registration_number": "Cégjegyzékszám",
            "headquarters": "Székhely",
            "tax_number": "Adószám",
            "lei_number": "LEI-kód",
            "data_protection_officer": "Adatvédelmi tisztviselő"
          },
          "email_attachment": {
            "date_sent": "Küldés időpontja",
            "download": "Letöltés"
          },
          "email_template": {
            "subject": "Tárgy",
            "body": "Szöveg",
            "save": "Mentés",
            "cancel": "Mégsem",
            "edit": "Szerkesztés",
            "send": "Küldés"
          },
          "permission": {
            "modify": "Módosítás",
            "remove_access": "Hozzáférés elvétele",
            "pdmr_pca_transactions": "PDMR / PCA tranzakciók",
            "user_permission_successfully_removed": "Hozzáférés megszűntetése sikeres",
            "no_access": "Nincs hozzáférés",
            "read_only": "Csak olvasó",
            "full_acccess": "Szerkesztő"
          },
          "text_area_attachment": {
            "attachments": "Csatolmányok"
          }
        },
        "data_room": {
          "general": {
            "grant_access": "Hozzáférés adása",
            "people_with_access": "Hozzáféréssel rendelkezők",
            "take_away_access_from_everyone": "Hozzáférés elvétele minden személytől",
            "take_away_access": "Hozzáférés elvétele",
            "email_notifications": "E-mail értesítés(ek)",
            "acknowledgement": "Elismerő nyilatkozatok",
            "download": "Letöltés",
            "download_list_of_people_with_access": "Hozzáféréssel rendelkező személyek listájának letöltése",
            "download_notification_emails_and_statements": "Összes elismerő nyilatkozat és értesítés letöltése",
            "personal_data": "Személyes adatok",
            "notification_status": "Értesítés hozzáférésről",
            "statement_of_consent_status": "Elismerő nyilatkozatok",
            "termination_of_access": "Hozzáférés megszűnése",
            "name": "Név",
            "email_address": "E-mail cím",
            "telephone_number": "Telefonszám",
            "dt_placeholder": "yyyy.mm.dd; hh:mm"
          },
          "uploaded_files_tab": {
            "upload_new_documents": "Új dokumentum(ok) feltöltése",
            "delete": "Elvetés",
            "download": "Letöltés",
            "download_document_list": "Dokumentumlista letöltése",
            "download_all_files_zip": "Az összes fájl letöltése",
            "file_name": "Fájlnév",
            "time_of_upload": "Feltöltés időpontja",
            "folder_name": "Mappa neve",
            "open_folder": "Mappa megnyitása",
            "create_new_folder": "Új mappa létrehozása",
            "create": "Létrehozás",
            "discard": "Elvetés",
            "rename": "Átnevezés"
          }
        },
        "insider_details": {
          "insider_list": {
            "date_placeholder": "YYYY.MM.DD; hh:mm",
            "dt_of_creation_of_permanent_insider_section": "(A jegyzék létrehozásának dátuma és időpontja)",
            "dt_of_last_update": "(Az utolsó frissítés dátuma és időpontja)",
            "date_of_transmission_to_competent_authority": "(Az illetékes hatósághoz történő továbbítás dátuma)",
            "project_name": "Projekt neve",
            "inside_information": "Bennfentes információ",
            "add_new_person": "Új személy hozzáadása",
            "export_insider_list": "Állandó bennfentes jegyzék exportálása",
            "delete": "Törlés",
            "first_name": "Keresztnév",
            "surname": "Vezetéknév",
            "birth_surname": "Születési név",
            "professional_telephone_number": "Munkahelyi telefonszám",
            "companys_name": "Társaság neve",
            "companys_address": "Társaság címe",
            "function_and_reason_for_being_insider": "Funkció és a bennfentesség oka",
            "function_and_reason_for_being_insider_title": "A szerepet, funkciót és az ebben a jegyzékben való szerepeltetés okát leíró szöveg.",
            "function_and_reason_for_being_insider_placeholder": "Funkció és a bennfentesség oka",
            "included": "Felvétel időpontja",
            "obtained": "Hozzáadás dátuma",
            "ceased": "Hozzáférés megszünésének időpontja",
            "birthdate": "Születési dátum",
            "national_id_card_number": "Személyi igazolvány szám",
            "personal_telephone_number": "Telefonszám",
            "personal_full_home_address": "Lakcím",
            "personal_full_home_address_title": "Ország, irányítószám, város, utca, házszám",
            "discard": "Elvetés",
            "save_data": "Mentés",
            "this_insider_information_was_disclosed": "Ez a bennfentes információ egy piaci tapogatódzás részeként is átadásra került.",
            "sending_the_notice_is_a_legal_obligation": "A bennfentes személy értesítése a MAR 18. cikk (2) bekezdése alapján kötelező. Biztos benne, hogy nem kívánja elküldeni az értesítést?",
            "name_of_the_insider": "Bennfentes személy neve",
            "data_completeness": "Adatok kitöltöttsége",
            "last_login": "Legutolsó bejelentkezés",
            "do_you_want_to_delete_this_insider": "Biztosan törölni szeretné ezt a bennfentes személyt?",
            "back": "Vissza"
          },
          "insider_notifications": {
            "download_all_acknowledgements": "MAR 18(2) szerinti nyilatkozatok letöltése",
            "download_all_notification_emails": "MAR 18(2) szerinti értesítések letöltése",
            "name_of_insider": "Bennfentes személy neve",
            "email_address": "E-mail cím"
          }
        },
        "packages": {
          "general": {
            "request": "Ajánlatkérés",
            "package_name": "Csomag neve",
            "function_module": "Elérhető modul"
          },
          "package_feature_collapse": {
            "optional": "Kiegészítő szolgáltatás"
          }
        },
        "pdma": {
          "general": {
            "modify": "Módosítás",
            "discard": "Elvetés",
            "save": "Mentés",
            "delete": "Törlés",
            "date_placeholder": "yyyy.mm.dd"
          },
          "insider_card": {
            "project_name": "Projekt neve",
            "first_name": "Keresztnév",
            "surname": "Vezetéknév",
            "birth_surname": "Születési név",
            "professional_telephone_number": "Munkahelyi telefonszám",
            "companys_name": "Társaság neve",
            "companys_address": "Társaság címe",
            "function_and_reason_for_being_insider": "Funkció és a bennfentesség oka",
            "function_and_reason_for_being_insider_title": "A szerepet, funkciót és az ebben a jegyzékben való szerepeltetés okát leíró szöveg.",
            "function_and_reason_for_being_insider_placeholder": "Funkció és a bennfentesség oka",
            "obtained": "Hozzáadás dátuma",
            "ceased": "Hozzáférés megszünésének időpontja",
            "birthdate": "Születési dátum",
            "national_id_card_number": "Személyi igazolvány szám",
            "personal_telephone_number": "Telefonszám",
            "personal_full_home_address": "Lakcím",
            "notification_email": "E-mail értesítés(ek)",
            "acknowledgement": "Elismerő nyilatkozatok"
          },
          "legal_form": {
            "company_name": "Cégnév",
            "headquarters": "Székhely",
            "company_registration_number": "Cégjegyzékszám",
            "type_of_relationship": "A kapcsolat típusa",
            "email_address": "E-mail cím",
            "phone_number": "Telefonszám",
            "delete_pca": "Elvetés",
            "download_notification": "Értesítés letöltése"
          },
          "natural_form": {
            "name": "Név",
            "type_of_relationship": "A kapcsolat típusa",
            "email_address": "E-mail cím",
            "phone_number": "Telefonszám",
            "id_number": "Személyi igazolvány száma",
            "place_of_birth": "Születési hely",
            "date_of_birth": "Születési dátum",
            "time_of_inclusion": "Felvétel időpontja",
            "download_notification": "Értesítés letöltése"
          },
          "notification_tab": {
            "name_of_the_person": "Név",
            "email_address": "E-mail cím",
            "no_data_to_show": "Nincs megjeleníthető adat",
            "download_all_notifications": "Összes értesítés letöltése"
          },
          "pca_card": {
            "pca_name": "PCA neve",
            "pca_email_address": "PCA E-mail címe",
            "natual_person": "Természetes személy",
            "legal_person": "Jogi személy"
          },
          "pdmr_list_tab": {
            "add_new": "Új hozzáadása",
            "add_new_pdmr": "Új PDMR hozzáadása",
            "add_new_pca": "Új PCA hozzáadása",
            "position_at_client": "Beosztás",
            "phone_number": "Telefonszám",
            "place_of_birth": "Születési hely",
            "date_of_birth": "Születési dátum",
            "id_number": "Személyi igazolvány szám",
            "date_of_inclusion": "Hozzáadás dátuma",
            "registered_seat": "Székhely",
            "registration_number": "Cégjegyzékszám",
            "download_list": "Lista letöltése",
            "pdmr_pca_data": "PDMR/PCA adatok",
            "date_completeness": "Adatok kitöltöttsége",
            "notification_status": "Értesítés státusza",
            "successful_modification": "Sikeres módosítás",
            "successful_deletion": "Sikeres törlés",
            "modify": "Módosítás",
            "save": "Mentés",
            "discard": "Elvetés",
            "delete": "Törlés",
            "back": "Vissza",
            "first_name": "Keresztnév",
            "last_name": "Vezetéknév",
            "email_address": "E-mail cím",
            "sync_data": "Adatok szinkronizálása",
            "attention_data_is_not_up_to_date": "Figyelem! Az adatok nem naprakészek!",
            "do_you_want_to_delete_this_pdmr_pca": "Biztosan törölni szeretné ezt a PDMR/PCA-t?"
          }
        },
        "permanent_insider_list": {
          "general": {
            "name_of_insider": "Bennfentes neve",
            "email_address": "E-mail cím",
            "date_placeholder": "YYYY.MM.DD.",
            "delete": "Törlés",
            "discard": "Elvetés",
            "back": "Vissza",
            "do_you_want_to_delete_this_insider": "Biztosan törölni szeretné ezt a bennfentes személyt?"
          },
          "insider_collapse": {
            "notification_emails": "E-mail értesítés(ek)",
            "acknowledgements": "Elismerő nyilatkozatok"
          },
          "notification_and_acknowledgement": {
            "download_all_acknowledgements": "Összes elismerő nyilatkozat letöltése",
            "download_all_notification_emails": "Összes értesítés letöltése"
          },
          "permanent_insider_list": {
            "dt_of_creation_of_permanent_insider_section": "(A jegyzék létrehozásának dátuma és időpontja)",
            "dt_of_last_update": "(Az utolsó frissítés dátuma és időpontja",
            "date_of_transmission_to_competent_authority": "(Az illetékes hatósághoz történő továbbítás dátuma",
            "export_the_permanent_insider_list": "Állandó bennfentes jegyzék exportálása",
            "first_name": "Keresztnév",
            "surname": "Vezetéknév",
            "birth_surname": "Születési név",
            "professional_telephone_number": "Munkahelyi telefonszám",
            "company_name": "Társaság neve",
            "company_address": "Társaság címe",
            "function_and_reason_for_being_insider": "Funkció és a bennfentesség oka",
            "function_and_reason_for_being_insider_title": "A szerepet, funkciót és az ebben a jegyzékben való szerepeltetés okát leíró szöveg.",
            "function_and_reason_for_being_insider_placeholder": "Funkció és a bennfentesség oka",
            "included": "Felvétel időpontja",
            "dt_of_persons_inclusion": "A bennfentes állandó bennfentesek szakaszába való felvételének dátuma és időpontja (yyyy-mm-dd, hh:mm UTC).",
            "birthdate": "Születési dátum",
            "national_id_card_number": "Személyi igazolvány szám",
            "personal_telephone_number": "Telefonszám",
            "personal_full_home_address": "Lakcím",
            "personal_full_home_address_title": "Ország, irányítószám, város, utca, házszám",
            "save_data": "Mentés",
            "name_of_the_insider": "Bennfentes személy neve",
            "data_completeness": "Adatok kitöltöttsége",
            "last_login": "Legutolsó bejelentkezés",
            "mar_18_notifications": "MAR 18(2) értesítések",
            "mar_18_acknowledgements": "MAR 18(2) elismerő nyilatkozatok",
            "not_logged_in_yet": "Nem lépett be",
            "select_version": "Válasszon verziót"
          }
        },
        "serviceprovider": {
          "client_card": {
            "package_subscribed_and_extras": "Előfizetett csomag + extrák",
            "modify_data_and_subscribed_package": "Adatok/előfizetés módosítása",
            "invoices_sent_to_customer": "Az ügyfélnek küldött számlák",
            "download_invoice": "Számla letöltése",
            "download_document": "Dokumentum letöltése",
            "invoice_number": "Számlaszám",
            "contracts": "Szerződésok",
            "contract_name": "Szerződés neve",
            "contract_date": "Keltezés",
            "upload_a_new_contract": "Új szerződés feltöltése"
          }
        }
      },
      "tools": {
        "alert": {
          "this_list_is_empty": "A lista üres"
        },
        "attachment": {
          "dt_when_persons_access_ceased": "Hozzáférés megszünésének időpontja (yyyy.mm.dd, hh: dd UTC)"
        },
        "carousel": {
          "previous": "Előző",
          "next": "Következő"
        },
        "chart": {
          "view_statistic": "Statisztikák"
        },
        "custom_date": {
          "dt_placeholder": "yyyy.mm.dd hh.mm",
          "date_placeholder": "yyyy.mm.dd"
        },
        "custom_dropdown": {
          "choose": "Kérjük, válasszon a legördülő listából!"
        },
        "editable_table": {
          "volume": "Mennyiség",
          "price": "Ár",
          "cur": "CUR",
          "add_row": "Hozzáadás"
        },
        "progess_bar": {
          "step": "Lépés"
        },
        "slider_table": {
          "previous": "Előző",
          "next": "Következő"
        }
      }
    },
    "modals": {
      "general": {
        "first_name": "Keresztnév",
        "surname": "Vezetéknév",
        "last_name": "Vezetéknév",
        "email_address": "E-mail cím",
        "email": "E-mail",
        "position": "Pozíció",
        "discard": "Elvetés",
        "confirm": "Megererősítés",
        "phone_number": "Telefonszám",
        "save": "Mentés",
        "add": "Hozzáadás",
        "next": "Következő",
        "birth_last_name": "Születési név",
        "inclusion_email_attachments": "Mellékletek (Ezek a fájlok letölthetők és az értesítő e-mailhez csatolhatók)"
      },
      "classification": {
        "information_form": {
          "please_provide_information": "Kérjük adja meg a bennfentes információ közzétételére vonatkozó adatokat!",
          "link": "Link",
          "k_number": "Hivatkozási szám",
          "date_and_time_of_publication": "Közzététel ideje",
          "upload_file": "Fájl feltöltése",
          "approve": "Jóváhagyás"
        },
        "information_notification": {
          "pursuant_to_article_17_4": "Ezen információkat az MNB részére is meg kell küldeni!"
        }
      },
      "data_rooms": {
        "general": {
          "yes": "Igen",
          "no": "Nem"
        },
        "grant_access_form": {
          "address": "Hozzáférés",
          "grant_access": "Hozzáférés adása"
        },
        "grant_access_modal": {
          "the_person_to_be_added": "A megadott személy még nem szerepel az adatszobában található bennfentes információt tartalmazó fájlhoz tartozó bennfentes listán.",
          "name_of_inside_information": "Bennfentes információ neve",
          "notification_and_insider_listing": "Értesítés és bennfentesjegyzékbe történő felvétel"
        },
        "insider_individuals": {
          "the_following_individuals": "Az alábbi személyek nem szerepelnek a megnevezett bennfentes jegyzékben.",
          "name_of_inside_information": "Értesítés és bennfentesjegyzékbe történő felvétel",
          "name": "Név",
          "notification_and_insider_listing": "Értesítés és bennfentesjegyzékbe történő felvétel",
          "email_address": "E-mail cím",
          "discard": "Elvetés"
        },
        "market_sounding_check": {
          "the_market_sounding_check": "A piaci tapogatózás bennfentes információ átadásával jár.",
          "pursuant_of_article_11": "A MAR 11. cikk (3) bekezdése alapján Önnek rögzítenie kell a fenti következtetéséhez vezető okokat.",
          "create_a_data_room": "Létrehozás",
          "discard": "Elvetés",
          "yes": "Igen",
          "no": "Nem",
          "comment": "Komment"
        },
        "multiple_files_indider": {
          "please_select_the_inside_information": "Kérjük, válassza ki az érintett fájlokat, majd a legördülő listából a hozzájuk tartozó bennfentes információt!",
          "select_insider_information_from_the_list": "Kérjük, válassza ki az érintett bennfentes információt!",
          "select_all": "Összes kijelölése",
          "file_name": "Fájlnév",
          "select_insider_information": "Bennfentes információ kijelölése"
        },
        "new_data_room": {
          "name_of_the_data_room": "Adatszoba neve",
          "the_information_is_transferred": "Az információ átadása piaci tapogatózás keretében történik.",
          "view_a_guide": "Útmutató megtekintése",
          "create": "Tovább"
        },
        "transcription_description": {
          "description_of_the_transaction_subject": "Kérjük mutassa be az ügyletet, amire tekintettel a piaci tapogatózás történik"
        },
        "upload_file_confirm": {
          "file_name": "Fájlnév",
          "this_file_contains_inside_information": "A fájl bennfentes információt tartalmaz.",
          "select_insider_information_from_the_list": "Kérjük, válassza ki az érintett bennfentes információt!",
          "insider_information_related_to_the_file": "A fájlhoz kapcsolódó bennfentes információ(k):",
          "upload_file": "Feltöltés",
          "discard": "Elvetés",
          "yes": "Igen",
          "no": "Nem"
        },
        "upload_multiple_files": {
          "please_select_the_files": "Kérjük, válassza ki azon fájlokat, amelyek bennfentes információt tartalmaznak!",
          "upload_files": "Feltöltés",
          "discard": "Elvetés"
        },
        "upload_multiple_files_confirm": {
          "please_select_the_file_list": "Kérjük, ellenőrizze a feltölteni kívánt fájlok és a hozzájuk rendelt bennfentes információk helyességét!",
          "file_name": "Fájlnév",
          "name": "Név",
          "email_address": "E-mail cím",
          "upload_files": "Feltöltés",
          "discard": "Elvetés"
        }
      },
      "pdmr_pca": {
        "general": {
          "place_of_birth": "Születési hely",
          "date_of_birth": "Születési dátum",
          "id_number": "Személyi igazolvány szám",
          "date_of_inclusion": "Felvétel időpontja"
        },
        "new_pca_form": {
          "add_new_pca": "PCA hozzáadása",
          "related_pdmr": "Kapcsolódó PDMR",
          "please_choose": "Kérjük, válasszon a legördülő listából!",
          "default_select_example": "Kérjük, válasszon a legördülő listából!",
          "nature_of_the_relationship": "Kérjük, válasszon a legördülő listából!",
          "please_choose_a_pdmr": "Kérjük, válasszon PDMR-t és kapcsolati típust!"
        },
        "new_pca_legal": {
          "add_new_pca": "PCA hozzáadása",
          "company_name": "Társaság neve",
          "registered_seat": "Székhely",
          "companys_registration_number": "Cégjegyzékszám",
          "date_of_inclusion": "Felvétel időpontja",
          "notify_pdrm": "A MAR 19. cikk (5) bekezdése szerinti értesítés megküldése a hozzáadással egyidejűleg.",
          "entry_of_the_pca_in_the_register": "Hozzáadás",
          "pursuant_to_article_19_5": "A MAR 19. cikk (5) bekezdése értelmében a PDMR-eket és a PCA-kat írásban kell értesíteni a MAR szerinti kötelezettségeikről. Biztos, hogy nem szeretné elküldeni ezt az értesítést?",
          "email_address": "E-mail cím",
          "phone_number": "Telefonszám"
        },
        "new_pca_natural": {
          "add_new_pca": "PCA hozzáadása",
          "notify_pdmr": "A MAR 19. cikk (5) bekezdése szerinti értesítés megküldése a hozzáadással egyidejűleg.",
          "entry_of_the_pca_in_the_register": "Hozzáadás",
          "notification_to_mar_is_a_legal": "A MAR 19. cikk (5) bekezdése értelmében a PDMR-eket és a PCA-kat írásban kell értesíteni a MAR szerinti kötelezettségeikről. Biztos, hogy nem szeretné elküldeni ezt az értesítést?"
        },
        "new_pdmr_form": {
          "add_new_pdrm": "PDMR hozzáadása",
          "position_at_client": "Beosztás",
          "send_a_mar_notification": "A MAR 19. cikk (5) bekezdése szerinti értesítés megküldése a hozzáadással egyidejűleg.",
          "entry_of_the_pdrm_in_the_register": "Hozzáadás",
          "notification_to_mar_is_a_legal": "A MAR 19. cikk (5) bekezdése értelmében a PDMR-eket és a PCA-kat írásban kell értesíteni a MAR szerinti kötelezettségeikről. Biztos, hogy nem szeretné elküldeni ezt az értesítést?"
        },
        "pdmr_pca_options": {
          "add_new_pdrm_pca": "PDMR / PCA hozzáadása",
          "add_new_pdrm": "PDMR hozzáadása",
          "add_new_pca": "PCA hozzáadása"
        },
        "previous_notifications": {
          "pdrm_name": "PDRM neve",
          "date_sent": "Küldés időpontja",
          "dt_placeholder": "yyyy.mm.dd hh:mm",
          "notification_email": "E-mail értesítés"
        }
      },
      "add_new_person_insider": {
        "obtained": "Felvétel",
        "dt_of_the_entry": "A bennfentes állandó bennfentesek szakaszába való felvételének dátuma és időpontja. (yyyy-mm-dd, hh: dd UTC)",
        "function_and_reason_for_being_insider": "Funkció és a bennfentesség oka",
        "reason_for_listing": "Funkció és a bennfentesség oka",
        "add_the_person_as_central_contract_point": "Központi kapcsolattartóként kerül felvételre?",
        "yes": "Igen",
        "no": "Nem",
        "notify_the_insider": "A bennfentes személy értesítése a bennfentes jegyzékbe vétel tényéről, a vonatkozó jogi és szabályozási kötelezettségekről, valamint a bennfentes kereskedelem és a bennfentes információk jogosulatlan közzététele tekintetében alkalmazandó szankciókról a MAR 18. cikk (2) bekezdése alapján."
      },
      "change_data_form": {
        "change_data": "Adatok módosítása",
        "name": "Név"
      },
      "insider_date_form": {
        "please_indicate_the_date": "Kérjük, adja meg a {title} bennfentesjegyzék létrehozásának időpontját!",
        "creating_dt": "Dátum és idő megadása",
        "permanent_insider_list_created": "Állandó bennfentes jegyzék létrhozása sikeres!",
        "permanent_insider_list_updated": "Állandó bennfentes jegyzék módosítása sikeres!"
      },
      "notification_alert": {
        "no": "Nem",
        "yes": "Igen"
      },
      "provide_link": {
        "link": "Link"
      }
    },
    "views": {
      "pages": {
        "admin": {
          "new_contract": {
            "contact_details": "Az ügyfél adatai",
            "clients_name": "Teljes név",
            "clients_address": "Cím",
            "clients_company_registration_number": "Cégjegyzékszám",
            "investor_contacts_name": "Kapcsolattartó neve",
            "investor_contacts_position": "Kapcsolattartó beosztása",
            "investor_contacts_email_address": "Kapcsolattartó e-mail címe",
            "investor_contacts_phone_number": "Kapcsolattartó telefonszáma",
            "subscribed_package": "Előfizetett csomag",
            "regulated_market": "Szabályozott piac",
            "sme": "SME",
            "advisory": "Tanácsadó",
            "discard": "Elvetés",
            "save": "Mentés",
            "email_address": "E-mail cím",
            "tax_number": "Adószám",
            "data_protection_officer": "Adatvédelmi tisztviselő"
          },
          "service_provider_control_panel": {
            "clients": "Ügyfelek",
            "add_a_new_client": "Új ügyfél hozzáadása",
            "search_by_name": "Név szerinti keresés",
            "actions": "Szűrés előfizetésre",
            "package_num": "Csomag {num}",
            "send_email_to_customers": "Email küldése minden ügyfélnek",
            "automated_emails": "Automatikus e-mailek",
            "pdmr_emails": "PDMR e-mailek",
            "pca_emails": "PCA e-mailek",
            "emails_related_to_a_permanent_insider_list": "Állanadó bennfentesek jegyzékéhez kapcsolódó e-mailek",
            "emails_related_to_a_transaction_specific_insider_list": "Ügyletspecifikus bennfentes jegyzékekhez kapcsolódó e-mailek",
            "emails_related_to_data_rooms": "Adatszobákhoz kapcsolódó e-mailek",
            "open_folder": "Mappa megnyitása",
            "folder_name": "Mappa neve",
            "template_name": "Sablon neve",
            "email_templates": "E-mail sablonok",
            "edit_template": "Sablon szerkesztése",
            "category": "Kategória",
            "type": "Típus",
            "template_name_en": "Sablon neve (angol)",
            "template_name_hu": "Sablon neve (magyar)",
            "subject_en": "Tárgy (angol)",
            "subject_hu": "Tárgy (magyar)",
            "body_en": "Törzs (angol)",
            "body_hu": "Törzs (magyar)",
            "category_required": "Kategória szükséges",
            "type_required": "Típus szükséges",
            "template_name_hu_required": "Sablon neve (magyar) szükséges",
            "template_name_en_required": "Sablon neve (angol) szükséges",
            "subject_hu_required": "Tárgy (magyar) szükséges",
            "subject_en_required": "Tárgy (angol) szükséges",
            "body_hu_required": "Törzs (magyar) szükséges",
            "body_en_required": "Törzs (angol) szükséges",
            "template_saved_successfully": "Sablon sikeresen mentve"
          }
        },
        "authentication": {
          "general": {
            "login_to_mabureg": "Bejelentkezés",
            "create_new_client": "Hozzon létre új MABUREG klienst",
            "new_client_created_successfully": "Új kliens sikeresen létrehozva!",
            "this_is_your_account_uid": "Ez az Ön fiók azonosítója:"
          },
          "login": {
            "enter_your_email_below": "Kérjük, adja meg a bejelentkezési adatait!",
            "email_address_phone_number": "E-mail cím / telefonszám*",
            "email_address": "E-mail cím",
            "log_in": "Bejelentkezés",
            "password": "Jelszó",
            "forgot_password": "Elfelejtett jelszó...",
            "new_client": "Új ügyfél",
            "confirm_password": "Jelszó ismét",
            "phone_number": "Telefonszám",
            "register": "Regisztráció"
          },
          "login_code": {
            "a_verification_code_has_been_sent": "E-mail-címére / telefonjára elküldtünk egy ellenőrző kódot, amely 10 percig érvényes.",
            "code": "Ellenőrző kód",
            "verify_login": "Megerősítés és bejelentkezés"
          },
          "login_code_verification": {
            "please_wait_while_we_verify_your_token": "Kérjük, várjon, amíg ellenőrizzük a tokenjét!"
          },
          "market_data_room_access_person": {
            "download_the_document_list": "Dokumentumlista letöltése",
            "download_all_files": "Az összes fájl letöltése",
            "download_document": "Dokumentum letöltése",
            "file_name": "Fájlnév",
            "uploaded": "Feltöltve"
          },
          "new_password": {
            "reset_your_password": "Jelszó helyreállítás",
            "enter_your_registered_email_address": "Adja meg a fiókjához tartozó e-mail címet vagy telefonszámot, amire elküldjük a jelszó helyreállításához szükséges kódot!",
            "new_password": "Új jelszó",
            "reset_password": "Jelszó visszaállítása",
            "email_address": "E-mail cím",
            "enter_your_new_password": "Új jelszó",
            "confirm_password": "Véglegesítés",
            "repeat_password": "Jelszó ismét",
            "dont_have_an_account": "Nincs még fiókja?",
            "sign_up": "Regisztráció"
          },
          "password_code": {
            "reset_your_password": "Jelszó helyreállítás",
            "enter_your_registered_email_address": "Adja meg a fiókjához tartozó e-mail címet vagy telefonszámot, amire elküldjük a jelszó helyreállításához szükséges kódot!",
            "code": "Ellenőrző kód",
            "enter_code": "Ellenőrző kód",
            "continue": "Megererősítés és bejelentkezés",
            "didnt_receive_code_yet": "Nem kapta meg a kódot?",
            "resend_link": "Újraküldés"
          },
          "terms": {
            "please_read_the_terms_carefully": "Kérjük, figyelmesen olvassa el a feltételeket!",
            "please_provide_us_with_the_following": "Kérjük, adja meg adatait!",
            "phone_number": "Telefonszám",
            "address": "Telefonszám",
            "go_to_the_data_room": "Tovább az adatszobához"
          },
          "user_logins": {
            "my_logins": "Korábbi bejelentkezések",
            "other_logins": "Egyéb bejelentkezések",
            "search_by_name_email": "Név vagy e-mail szerinti szűrés",
            "name_and_email_address": "Név és e-mail cím",
            "device": "Eszköz neve"
          }
        },
        "classification": {
          "classification_insider_info": {
            "qualification_and_delay_of_inside_information": "Bennfentes információ minősítése és késleltetése",
            "add_new_information": "Új információ hozzáadása",
            "current_inside_information": "Aktuális bennfentes információk",
            "previous_inside_information": "Megszűnt bennfentes információk",
            "sensitive_information": "Bennfentes információvá nem minősített információk"
          },
          "delay_insider_information": {
            "delaying_inside_information": "Bennfentes információ késleltetése",
            "delaying_the_disclosure_of_inside_information": "A belső információk késleltetésének feltételei",
            "current_date_and_time": "Késleltetési döntés időpontja:",
            "it_can_be_modified": "(módosítható)",
            "uploaded_files": "Feltöltött fájlok",
            "upload_file": "Feltöltés",
            "attention_the_selected_files_are_uploading": " Figyelem! A kiválasztott fájlok feltöltődnek a szerverre, kérjük, ne zárja be az ablakot, és ne lépjen tovább!",
            "discard": "Elvetés",
            "save": "Információ késleltetése",
            "the_conditions_of_delaying": "A bennfentes információ késleltetésének feltételei nem teljesülnek, ezért a bennfentes információt haladéktalanul közzé kell tenni!",
            "back": "Mégsem",
            "publish": "Közzététel",
            "the_recorded_delay_data_will_not_be_clumped": "A rögzített késleltetési adatok nem lesznek összevonva, de a bennfentes információk el lesznek mentve!",
            "proceed": "Tovább",
            "attention_you_have_not_qualified": "Figyelem! Nem minősítette az összes lehetőséget! Kérjük, töltsön ki minden szükséges mezőt!",
            "ok": "Ok",
            "files_uploaded_successfully": "Fájlok feltöltése sikeres!"
          },
          "information_re_qualification": {
            "requalification_of_information": "Információ átminősítése",
            "project_name": "Projekt neve",
            "please_indicate_the_name_of_the_project": "Kérjük, adja meg a projekt nevét (pl. egyesülés, MABU projekt stb.). Fontos, hogy ez a mező nem tartalmazhat semmilyen bennfentes információt.",
            "description_of_the_information": "A minősítendő információ részletei",
            "current_date_and_time": "Késleltetési döntés időpontja:",
            "it_can_be_modified": "(módosítható)",
            "uploaded_files": "Feltöltött fájlok",
            "upload_file": "Feltöltés",
            "attention_the_selected_files_are_uploading": "Figyelem! A kiválasztott fájlok feltöltődnek a szerverre, kérjük, ne zárja be az ablakot, és ne lépjen tovább!",
            "discard": "Elvetés",
            "save": "Mentés",
            "the_conditions_of_delaying": "A bennfentes információ késleltetésének feltételei nem teljesülnek, ezért a bennfentes információt haladéktalanul közzé kell tenni!",
            "back": "Mégsem",
            "publish": "Közzététel",
            "the_recorded_delay_data_will_not_be_clumped": "A rögzített késleltetési adatok nem lesznek összevonva, de a bennfentes információk el lesznek mentve!",
            "proceed": "Tovább",
            "attention_you_have_not_qualified": "Figyelem! Nem minősítette az összes lehetőséget! Kérjük, töltsön ki minden szükséges mezőt!",
            "ok": "Ok",
            "files_uploaded_successfully": "Fájlok feltöltése sikeres!"
          },
          "new_information_qualification": {
            "reclassify_the_information": "Információ átminősítése",
            "requalification_of_information": "Információ átminősítése",
            "project_name": "Projekt neve",
            "please_indicate_the_name_of_the_project": "Kérjük, adja meg a projekt nevét (pl. egyesülés, MABU projekt stb.). Fontos, hogy ez a mező nem tartalmazhat semmilyen bennfentes információt.",
            "description_of_the_information": "A minősítendő információ részletei",
            "current_date_and_time": "Date of qualification",
            "it_can_be_modified": "(módosítható)",
            "uploaded_files": "Feltöltött fájlok",
            "upload_file": "Feltöltés",
            "attention_the_insider_information_is_being_created": "Figyelem! Ez a bennfentes információ készül, kérjük, ne zárja be az ablakot, és ne menjen tovább sehova!",
            "attention_the_selected_files_are_uploading": "Figyelem! A kiválasztott fájlok feltöltődnek a szerverre, kérjük, ne zárja be az ablakot, és ne lépjen tovább!",
            "discard": "Elvetés",
            "classify_information": "Információ minősítése",
            "what_do_you_want_to_do_with_the_information": "A bennfentes információt haladéktalanul közzé kell tenni, kivéve, ha a késleltetés feltételei fennállnak. Mit szeretne tenni a bennfentes információval?",
            "delay": "Késleltetés",
            "immediate_publication": "Közzététel",
            "this_information_does_not_qualify": "Ez az információ nem minősül bennfentes információnak. Szeretne egy bizalmas listát létrehozni?",
            "no": "Nem",
            "yes": "Igen",
            "attention_you_have_not_qualified": "Figyelem! Nem minősítette az összes lehetőséget! Kérjük, töltsön ki minden szükséges mezőt!",
            "ok": "Ok",
            "files_uploaded_successfully": "Fájlok feltöltése sikeres!",
            "successfully_created": "Sikeres létrehozás!",
            "edit_the_information": "Szerkessze az információkat",
            "confirm_edit_the_information": "Megerősítés Szerkessze az információkat",
            "do_you_want_to_Reclassify_the_information": "Át szeretné-e minősíteni az információkat?",
            "save_changes": "Változtatások mentése"
          }
        },
        "confidential": {
          "confidential_details": {
            "confidential_list": "Bizalmas lista",
            "date_placeholder": "YYYY.MM.DD.",
            "date_and_time_of_creation": "A bennfentes jegyzék létrehozásának dátuma és időpontja)",
            "dt_of_last_update": "(A legutolsó frissítés dátuma és időpontja)",
            "a_brief_description_of_the_confidential_information": "Bizalmas lista neve",
            "confidential_list_long_description": "Bizalmas lista leírása",
            "add_new_person": "Új személy felvétele",
            "delete": "Törlés",
            "this_confidential_list_is_empty": "Ezen bizalmas lista jelenleg még üres. Kérjük, adjon hozzá legalább egy személyt!",
            "export_confidential_list": "Bizalmas lista exportálása",
            "convert_to_a_deal_specific_insider_list": "Átalakítás ügyletspecifikus listává",
            "name": "Teljes név",
            "position": "Beosztás",
            "email": "E-mail cím",
            "edit": "Szerkesztés",
            "disable_editing": "Szerkesztés letiltása"
          },
          "confidential_list": {
            "closed_confidential_list": "Lezárt bizalmas lista",
            "confidential_list": "Bizalmas lista",
            "create_new_confidential_list": "Új bizalmas lista létrehozása",
            "download": "Letöltés",
            "details": "Részletek",
            "project_name": "A bizalmas információ neve",
            "date_and_time_of_creation": "Létrehozás időpontja",
            "number_of_people": "Résztvevők száma",
            "short_name_of_information": "Információ rövid leírása",
            "dt_placeholder": "dd-mm-yyyy; hh:mm"
          },
          "new_confidential_form": {
            "confidential_list": "Bizalmas lista",
            "project_name": "Projekt neve",
            "description_of_the_sensitive_information": "Bizalmas információ leírása",
            "current_date_and_time": "Késleltetési döntés időpontja:",
            "it_can_be_modified": "(módosítható)",
            "save": "Mentés",
            "cancel": "Elvetés"
          }
        },
        "current_insider": {
          "list_of_transaction_specifi_insiders": {
            "closed_insider_lists": "Lezárt ügyletspecifikus jegyzékek",
            "current_insider_lists": "Aktuális ügyletspecifikus jegyzékek",
            "search": "Keresés",
            "download": "Letöltés",
            "details": "Részletek",
            "inside_information": "Bennfentes információ",
            "project_name": "Projekt neve",
            "time_of_creation": "Létrehozás dátuma",
            "number_of_persons_included": "Hozzáférő személyek száma",
            "date_of_closure": "Lezárás dátuma",
            "short_name_of_insider_information": "Leírás",
            "dt_placeholder": "yyyy.mm.dd.; hh:mm",
            "number_of_people": "Hozzáférő személyek száma"
          },
          "specific_insider_details": {
            "deal_specific_insider_list": "Ügyletspecifikus bennfentes jegyzék",
            "insider_list": "Bennfentes személyek",
            "notifications_and_acknowledgements": "Értesítések és nyilatkozatok"
          }
        },
        "data_room": {
          "data_room_details": {
            "market_sounding_data_room": "Piaci tapogatózás - { dataroom }",
            "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba - { dataroom }",
            "uploaded_files": "Feltöltött fájlok",
            "people_with_access": "Hozzáféréssel rendelkezők"
          },
          "data_rooms": {
            "create_a_new_data_room": "Új adatszoba létrehozása",
            "market_sounding_data_room": "Piaci tapogatózásos adatszoba",
            "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba"
          }
        },
        "pdmr_pca": {
          "pca": {
            "name_and_email_address_of_the_person_discharging": "Az Önnel szoros kapcsolatban álló, vezetői feladatokat ellátó személy (PDMR) neve és e-mail címe.",
            "pdmr_name": "PDRM neve",
            "pdmr_email_address": "PDMR e-mail címe",
            "the_data_of_persons_closely_associated": "Vezetőkkel szoros kapcsolatban állók adatai.",
            "pca_name": "PCA neve",
            "pca_email_address": "PCA e-mail címe",
            "new_transaction": "Új ügylet bejelentése",
            "download_application": "Bejelentés letöltése",
            "market_sounding_data_room": "Piaci tapogatózásos adatszoba",
            "data_room_name": "Adatszoba neve",
            "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba",
            "transaction_details": "Az ügylet alapadatai",
            "notification_date": "A bejelentés időpontja",
            "basic_transaction_details": "Az ügylet alapadatai"
          },
          "pdmr": {
            "pdmr_information": "PDMR információk",
            "name": "Teljes név",
            "position_at_client": "Beosztás",
            "email_address": "E-mail cím",
            "phone_number": "Telefonszám",
            "id_number": "Személyi igazolvány szám",
            "place_of_birth": "Születési hely",
            "date_of_birth": "Születési dátum",
            "time_of_inclusion": "Felvétel időpontja",
            "modify": "Módosítás",
            "discard": "Elvetés",
            "save": "Mentés",
            "add_a_new_pca": "PCA hozzáadása",
            "download_all_notifications": "Összes értesítés letöltése",
            "pca_name": "PCA neve",
            "pca_email_address": "PCA e-mail címe",
            "reported_transactions": "Bejelentett ügyletek",
            "notify_new_transaction": "Új ügylet bejelentése",
            "download_application": "Bejelentés letöltése",
            "periods_covered_by_the_trade_ban": "Kereskedelmi tilalommal érintett időszakok",
            "requests_on_trading_ban_exemption": "Kereskedelmi tilalom alóli felmentések iránti kérelmek",
            "new_application": "Új kérelem létrehozása",
            "download": "Kérelem letöltése",
            "insider_list": "Bennfentes jegyzékek",
            "market_sounding_data_room": "Piaci tapogatózásos adatszobák",
            "non_market_sounding_data_room": "Nem piacérzékelő adatszobák",
            "data_room_name": "Adatszoba neve",
            "transaction_details": "Az ügylet alapadatai",
            "notification_date": "A bejelentés időpontja",
            "request_id": "Kérelem azonosítója",
            "date": "Dátum",
            "approved_rejected": "Állapot"
          },
          "pdmr_list": {
            "pdmr_pca_list": "PDMR / PCA nyilvántartás",
            "notifications": "Értesítések"
          },
          "published_reports": {
            "unpublished_pdmr_pca_reports": "Közzé nem tett PDMR/PCA bejelentések",
            "search": "Keresés",
            "provide_publishing_information": "Közzététel adatainak megadása",
            "published_pdmr_pca_reports": "Közzétett PDMR/PCA bejelentések",
            "posted_link": "Link",
            "name_of_applicant": "Bejelentő neve",
            "time_of_notification": "Bejelentés dátuma",
            "pdmr_pca": "PDRM / PCA",
            "view_form": "Kitöltött űrlapok",
            "dt_placeholder": "yyyy.mm.dd hh:mm"
          },
          "report_transaction_form": {
            "name": "Név",
            "email": "E-mail cím",
            "position": "Beosztás",
            "phone_number": "Telefonszám",
            "this_form_is_required_for_disclosure": "Ez az űrlap a piaci visszaélésről szóló, 2014. április 16-i 596/2014/EU európai parlamenti és tanácsi rendelet (piaci visszaélésről szóló rendelet) 19. cikke szerinti tranzakciók közzétételéhez szükséges.",
            "details_of_the_person_discharging": "1. A vezetői feladatokat ellátó személy/közeli kapcsolatban álló személy adatai",
            "natural_person": "Természetes személy",
            "first_name": "Keresztnév",
            "last_name": "Vezetéknév",
            "legal_person": "Jogi személy",
            "full_name": "Teljes név",
            "modify": "Módosítás",
            "reason_for_the_notification": "2. A bejelentés oka",
            "position_status": "Beosztás/státusz",
            "initial_notification": "Első értesítés",
            "amendment_to_prior_notification": "Előzetes bejelentések módosítása",
            "description": "Részletek",
            "details_of_the_issuer": "3. A kibocsátó, a kibocsátási egység piaci szereplője, az aukciós platform, az aukcióvezető vagy az aukciófigyelő adatai",
            "full_name_of_the_entity": "A személy teljes neve",
            "lei_legal_entity_identifier": "LEI / Jogi személy azonosítója",
            "details_of_the_transaction": "4. Az ügylet(ek) adatai: a szakaszt meg kell ismételni i. minden eszköztípus esetében; ii. minden ügylettípus; iii. minden dátum; és (iv) minden hely ahol tranzakciókat hajtottak végre",
            "details_of_the_transaction_section": "4.{idx}. Az ügylet(ek) adatai: a szakaszt meg kell ismételni i. minden eszköztípus esetében; ii. minden ügylettípus; iii. minden dátum; és (iv) minden hely ahol tranzakciókat hajtottak végre",
            "description_of_the_finincial_instrument": "A pénzügyi eszköz leírása, az eszköz típusa",
            "identification_code": "Azonosító kód",
            "nature_of_the_transaction": "Az ügylet jellege",
            "description_of_the_transaction": "A tranzakció típusának leírása, adott esetben az 596/2014/EU rendelet 19. cikkének (14) bekezdése alapján elfogadott (EU) 2016/5221 felhatalmazáson alapuló bizottsági rendelet 10. cikkében meghatározott tranzakciótípus felhasználásával, vagy egy konkrét példa az 596/2014/EU rendelet 19. cikkének (7) bekezdésében meghatározott. Az 596/2014/EU rendelet 19. cikke (6) bekezdésének e) pontja értelmében fel kell tüntetni, hogy az ügylet részvényopciós program lehívásához kapcsolódik-e.",
            "prices_and_volumes": "Mennyiség(ek) és Ár(ak)",
            "aggregated_volume_and_price": "Összesített mennyiség és ár",
            "time_of_the_transaction": "A tranzakció időpontja",
            "transaction_date_and_time": "A tranzakció időpontja",
            "place_of_the_transaction": "A tranzakció helye",
            "name_and_code_to_identify_the_mifid": "Név és kód annak a MiFID kereskedési helyszínnek, a rendszeres internalizálónak vagy az Unión kívüli szervezett kereskedési platformnak az azonosítására, ahol az ügyletet végrehajtották, a 600/2014/EU európai parlamenti és hatósági rendeletet kiegészítő, felhatalmazáson alapuló bizottsági rendeletben meghatározottak szerint. A Tanács a 600/2014/EU rendelet 26. cikke alapján elfogadott, az ügyletekről az illetékes hatóságoknak történő jelentéstételre vonatkozó szabályozástechnikai standardok tekintetében, vagy ha az ügyletet nem a fent említett helyszínek egyikén hajtották végre, kérjük, említse meg a „kereskedésen kívüli helyszín'.",
            "submit_form": "Űrlap beküldése",
            "where_more_then_one_transaction": "Ha egynél több azonos jellegű tranzakció (vásárlás, eladás, kölcsönadás, kölcsön,…) ugyanazon a pénzügyi eszközön vagy kibocsátási egységen ugyanazon a napon teljesítik  és ugyanazon az ügylet helyén ezen ügyletek árait és mennyiségeit kell feltüntetni ebben a mezőben, a fent bemutatott két oszlopos formában, annyi sort beszúrva, mint szükséges.",
            "new_detail": "Új részlet"
          }
        },
        "data_room_control_panel": {
          "market_sounding_data_room": "Piaci tapogatózásos adatszobák",
          "data_room_name": "Adatszoba neve",
          "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba",
          "this_room_contains_insider_information": "Ez a szoba bennfentes információt tartalmaz"
        },
        "insider_list_control_panel": {
          "insider_list": "Bennfentes jegyzék",
          "market_sounding_data_room": "Piaci tapogatózásos adatszobák",
          "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba",
          "data_room_name": "Adatszoba neve"
        },
        "packages": {
          "subscribed_package_and_additional_services": "Előfizetett csomag és kiegészítő szolgáltatások",
          "other_package_offers": "Csomagajánlatok"
        },
        "period_ban": {
          "fixing_the_period_covered_by_the_trading_ban": "A kereskedelmi tilalom hatálya alá tartozó időszak meghatározása",
          "start_date_of_publication_of_half_yearly": "A féléves pénzügyi jelentések közzétételének időpontja:",
          "end_date_of_publication_of_half_yearly": "Az éves pénzügyi jelentések közzétételének időpontja:",
          "start_date_of_publication_of_annual": "A féléves pénzügyi jelentések közzétételének időpontja:",
          "end_date_of_publication_of_annual": "Az éves pénzügyi jelentések közzétételének időpontja:",
          "save": "Mentés",
          "send_emails_automatically": "Automatikus e-mail értesítő küldése",
          "days_before_the_start_of_closed_period": "nappal a kereskedelmi tilalom kezdete előtt.",
          "list_of_persons_to_be_notified": "Értesítendő személyek listája",
          "date_sent": "Értesítés dátuma",
          "previous_notifications": "Korábbi értesítések",
          "requests_on_trade_ban_excemptions": "Kereskedelmi tilalom alóli felmentések iránti kérelmek",
          "reject": "Elutasítás",
          "accept": "Engedélyezés",
          "are_you_sure_you_want_to_accept_the_excemption": "Biztos, hogy elfogadja a felmentést?",
          "are_you_sure_you_want_to_reject_the_excemption": "Biztos, hogy elutasítja a felmentést?",
          "back": "Vissza",
          "pdmr_name": "PDRM neve",
          "email_address": "E-mail cím",
          "time_of_notification": "Értesítés időpontja",
          "automatic_email_notifications": "Automatikus e-mail értesítések",
          "name": "Név",
          "request_link": "A kérelem linkje",
          "dt_placeholder": "yyyy.mm.dd hh:mm",
          "successfully_updated_period": "Sikeresen frissített időszak!",
          "ban_period_successfully_updated": "Tilalmi időszak sikeresen frissítve!",
          "successfully_updated_notification_state": "Sikeresen frissítettük az értesítési állapotot!",
          "successfully_accepted_excemption_request": "Sikeresen elfogadott mentességi kérelem!",
          "successfully_rejected_excemption_request": "Sikeresen elutasított mentességi kérelem!",
          "there_is_no_notification": "Nincs értesítés"
        },
        "permanent_insider_list": {
          "permanent_insider_list": "Állandó bennfentesek jegyzéke",
          "insider": "bennfentes",
          "notifications_and_acknowledgements": "Értesítések és nyilatkozatok"
        },
        "roles_select_page": {
          "select_role": "Válasszon szerepkört",
          "please_select_role": "Válasszon szerepkört",
          "investor_contact": "Befektetői kapcsolat",
          "admin": "Admin",
          "pdmr": "PDRM",
          "pca": "PCA",
          "person_on_deal_specific_insider_list": "Személy az ügyletspecifikus bennfentes listán",
          "person_invited_only_to_data_rooms": "Csak az adatszoba(k)ba meghívott személy"
        },
        "settings": {
          "company_name": "Cég neve",
          "company_headquarters": "Cég székhelye",
          "automatically_log_off_users_after": "A felhasználók automatikus kijelentkeztetése",
          "input": "Bevitel",
          "minutes_of_inactivity": "perc inaktivitás után.",
          "change_data": "Adatok módosítása",
          "change_password": "Jelszó módosítása",
          "client_decrypt_request": "Ügyfél adatokhoz való hozzáférési kérelem",
          "login_history": "Korábbi bejelentkezések",
          "permanently_delete_profile": "Fiók törlése",
          "delete_my_account": "A fiókom törlése",
          "site_documents": "Oldal dokumentumai",
          "document_title": "Dokumentum címe",
          "document_type": "Dokumentum típusa",
          "document_content": "Dokumentum tartalma",
          "date": "Dátum",
          "edit_document": "Dokumentum szerkesztése",
          "from_date": "Dátumtól",
          "document_title_required": "Dokumentum címe szükséges",
          "from_date_required": "Dátumtól szükséges",
          "document_content_required": "Dokumentum tartalma szükséges",
          "create_document": "Dokumentum létrehozása"
        },
        "subscriptions": {
          "subscribed_package": "Előfizetett csomag és kiegészítő szolgáltatások",
          "subscribed_package_name": "Csomag neve",
          "download_content": "Szerződés letöltése",
          "change_subscription": "Előfizetés módosítása",
          "my_accounts": "Számláim",
          "seach_accounts_by_number": "Keresés számlaszám alapján",
          "account_number": "Számlaszám",
          "date_of_issue": "Kiállítás dátuma",
          "date_of_completion": "Teljesítés dátuma",
          "download_request": "Díjbekérő letöltése",
          "download_invoice": "Számla letöltése"
        },
        "upload_application": {
          "upload_application": "Kérelem feltöltése",
          "file_name": "Fájlnév",
          "proceed": "Kérelem benyújtása"
        },
        "declaration_of_recognition_share": {
          "document_and_information_disclosure": "Tudomásul veszem, hogy az adatszobában megosztott dokumentumok és információk átadására a piaci visszaélésekről szóló 596/2014/EU rendelet 11. cikke szerinti piaci tapogatózás keretében, a piaci szereplők véleményére vonatkozó közvélemény-kutatás céljából kerül sor.",
          "communication": "Megerősítem, hogy az Ügyfél a potenciális befektető által a piaci szereplők véleményére vonatkozó közvélemény-kutatás fogadásával megbízott személlyel kommunikál.",
          "judge_for_self": "Tudomásul veszem, hogy ha hozzájárulok a piaci szereplők véleményére vonatkozó közvélemény-kutatás fogadásához, akkor olyan információt fogok kapni, amely az Ügyfél szerint bennfentes információnak minősül. Tudomásul veszem, hogy a piaci tapogatózás során minden esetben saját magamnak kell megítélnem, hogy bennfentes információ birtokában vagyok-e, illetve hogy a birtokomban lévő információ mely időponttól nem tekinthető többé bennfentes információnak",
          "cease_inside_info": "Tudomásul veszem, hogy az átadott bennfentes információ bennfentes jellegének megszűnése esetén ennek tényéről e-mailben kapok tájékoztatást.",
          "financial_instruments": "Tudomásul veszem, hogy tilos az átadott bennfentes információ oly módon történő felhasználása vagy az arra irányuló kísérlet, hogy közvetlenül vagy közvetve saját vagy harmadik személy javára az átadott bennfentes információhoz kapcsolódó pénzügyi eszközöket szerezzek meg, vagy idegenítsek el.",
          "amend_cancel": "Tudomásul veszem, hogy tilos az átadott bennfentes információ oly módon történő felhasználása vagy az arra irányuló kísérlet, hogy az információhoz kapcsolódó pénzügyi eszközre szóló, már létező megbízást visszavonjak vagy módosítsak.",
          "confidentiality_obl": "Tudomásul veszem, hogy a bennfentes információhoz való hozzájutással köteles vagyok az információ bizalmas kezelésére.",
          "info_receive": "Kifejezetten hozzájárulok, hogy számomra piaci tapogatózás keretében bennfentes információ kerüljön átadásra.",
          "data_room_separation": "Tudomásul veszem, hogy az adatszoba tartalmazza a piaci szereplők véleményére vonatkozó közvélemény-kutatás céljából átadott minden információt, külön azonosítva azt az információt, amely az Ügyfél szerint bennfentes információnak minősül.",
          "privacy_policy": "Az adatkezelési tájékoztatót megismertem, az adatvédelmi szabályzatot elfogadom { link }"
        },
        "declaration_of_recognition_no_share": {
          "document_and_information_disclosure": "Tudomásul veszem, hogy az adatszobában megosztott dokumentumok és információk átadására a piaci visszaélésekről szóló 596/2014/EU rendelet 11. cikke szerinti piaci tapogatózás keretében, a piaci szereplők véleményére vonatkozó közvélemény-kutatás céljából kerül sor.",
          "communication": "Megerősítem, hogy az Ügyfél a potenciális befektető által a piaci szereplők véleményére vonatkozó közvélemény-kutatás fogadásával megbízott személlyel kommunikál.",
          "not_inside_information": "Tudomásul veszem, hogy ha hozzájárulok a piaci szereplők véleményére vonatkozó közvélemény-kutatás fogadásához, akkor olyan információt fogok kapni, amely az Ügyfél szerint nem minősül bennfentes információnak. Tudomásul veszem, hogy a piaci tapogatózás során minden esetben saját magamnak kell megítélnem, hogy bennfentes információ birtokában vagyok-e, illetve hogy a birtokomban lévő információ mely időponttól nem tekinthető többé bennfentes információnak.",
          "info_receive": "Kifejezetten hozzájárulok, hogy számomra piaci tapogatózás keretében információ kerüljön átadásra.",
          "data_room_all": "Tudomásul veszem, hogy az adatszoba tartalmazza a piaci szereplők véleményére vonatkozó közvélemény-kutatás céljából átadott minden információt.",
          "privacy_policy": "Az adatkezelési tájékoztatót megismertem, az adatvédelmi szabályzatot elfogadom { link }"
        },
        "insider_first_login": {
          "legal_and_regulatory_duties": "Kifejezettem elismerem, hogy az Ügyfél tájékoztatott a bennfentes jegyzékre való felvétellel járó jogi és szabályozási kötelezettségekről, valamint a bennfentes kereskedelem és a bennfentes információk jogosulatlan közzététele tekintetében alkalmazandó szankciókról.",
          "retain_insider_list": "Tudomásul veszem, hogy a bennfentesek jegyzékét az Ügyfél a bennfentes jegyzék összeállítását vagy frissítését követő legalább öt éven át köteles megőrizni, és kérésre köteles a lehető leghamarabb benyújtani a Magyar Nemzeti Bank részére.",
          "privacy_policy": "Az adatkezelési tájékoztatót megismertem, az adatvédelmi szabályzatot elfogadom { link }"
        },
        "normal_first_login": {
          "privacy_policy": "Az adatkezelési tájékoztatót megismertem, az adatvédelmi szabályzatot elfogadom { link }"
        }
      }
    },
    "aside": {
      "classification_and_delay_of_inside_info": "A belső információk besorolása és késleltetése.",
      "insider_list": {
        "title": "Bennfentesek listája",
        "submenu": {
          "permanent_insider_list": "Állandó bennfentesek listája",
          "transaction-specific_insiders_list": "Tranzakcióspecifikus bennfentesek listája",
          "confidential_list": "Bizalmas lista"
        }
      },
      "management_transactions": {
        "title": "Menedzsment tranzakciók",
        "submenu": {
          "record-keeping": "Nyilvántartás",
          "transaction_reporting_for_executives_and_close_associates": "Tranzakciós jelentések vezetők és közeli munkatársak számára",
          "trading_ban_period": "Kereskedési tilalom időszaka"
        }
      },
      "transfer_of_inside_info": "Belső információk átadása.",
      "transaction_form": "Ügylet bejelentés",
      "dashboard": "Kezdőlap"
    },
    "dropdown": {
      "settings": "Beállítások",
      "pdmr": "PDMR",
      "pca": "PCA",
      "dashboard": "Irányítópult",
      "my_subscription": "Előfizetésem",
      "era_form_filler": "ERA űrlapkitöltő",
      "billing_data": "Számlázási adatok",
      "billing": "Számlázás",
      "change_password": "Jelszó módosítása",
      "login_history": "Korábbi bejelentkezések"
    },
    "util": {
      "decrypt_request": "Titkosítás feloldásának kérelme",
      "request_sent": "Kérelem elküldve",
      "up_to_date": "Frissítve",
      "loading": "Betöltés",
      "save_selected_representatives": "Kiválasztott képviselők mentése",
      "items_per_page": "Elemek oldalanként",
      "page": "Oldal",
      "board_member": "Igazgatósági tag",
      "representative": "Képviselő"
    }
  },
  "header": {
    "logout": "Kijelentkezés",
    "log_in": "Bejelentkezés",
    "create_master_key": "Mesterkulcs létrehozása",
    "edit_master_key": "Mesterkulcs módosítása",
    "billing_data": "Számlázási adatok",
    "settings": "Beállítások",
    "enter_your_master_password": "Adja meg a mesterkulcsot",
    "master_password": "Mesterkulcs",
    "wrong_master_password": "Hibás mesterkulcs",
    "save": "Mentés",
    "the_password_must_contain_at_least_one_uppercase_letter_and_one_lowercase_letter": "A jelszónak tartalmaznia kell legalább egy kisbetűt és egy nagybetűt.",
    "the_password_must_contain_at_least_one_symbol": "A jelszónak tartalmaznia kell legalább egy szimbólumot.",
    "the_password_must_contain_at_least_one_number": "A jelszónak tartalmaznia kell legalább egy számot.",
    "the_confirm_password_must_match_the_password": "A jelszó és a megerősítés nem egyezik.",
    "discard": "Elvetés",
    "old_password": "Régi jelszó",
    "password": "Jelszó",
    "confirm_password": "Jelszó megerősítése",
    "enter_your_email": "Adja meg az e-mail címét",
    "email_address": "E-mail cím"
  },
  "inclusion_emails": {
    "select_language": "Email nyelv kiválasztása",
    "english": "Angol",
    "hungarian": "Magyar"
  },
  "info-requalification": {
    "requalification_of_information": "Információ átminősítése",
    "project_name": "Projekt neve",
    "please_indicate_the_name_of_the_project": "Kérjük, adja meg a projekt nevét (pl. egyesülés, MABU projekt stb.). Fontos, hogy ez a mező nem tartalmazhat semmilyen bennfentes információt.",
    "description_of_the_information": "A minősítendő információ részletei",
    "current_date_and_time": "Késleltetési döntés időpontja:",
    "it_can_be_modified": "(módosítható)",
    "uploaded_files": "Feltöltött fájlok",
    "upload_file": "Feltöltés",
    "attention_the_selected_files_are_uploading": "Figyelem! A kiválasztott fájlok feltöltődnek a szerverre, kérjük, ne zárja be az ablakot, és ne lépjen tovább!",
    "discard": "Elvetés",
    "save": "Mentés",
    "the_conditions_of_delaying": "A bennfentes információ késleltetésének feltételei nem teljesülnek, ezért a bennfentes információt haladéktalanul közzé kell tenni!",
    "back": "Mégsem",
    "publish": "Közzététel",
    "the_recorded_delay_data_will_not_be_clumped": "A rögzített késleltetési adatok nem lesznek összevonva, de a bennfentes információk el lesznek mentve!",
    "proceed": "Tovább",
    "attention_you_have_not_qualified": "Figyelem! Nem minősítette az összes lehetőséget! Kérjük, töltsön ki minden szükséges mezőt!",
    "ok": "Ok",
    "files_uploaded_successfully": "Fájlok feltöltése sikeres!",
    "date_of_qualification": "Minősítés időpontja"
  },
  "insider-list": {
    "name_of_insider": "Bennfentes neve",
    "email_address": "E-mail cím",
    "date_placeholder": "YYYY.MM.DD.",
    "delete": "Törlés",
    "discard": "Elvetés",
    "back": "Vissza",
    "do_you_want_to_delete_this_insider": "Biztosan törölni szeretné ezt a bennfentes személyt?",
    "notification_emails": "E-mail értesítés(ek)",
    "acknowledgements": "Elismerő nyilatkozatok",
    "download_all_acknowledgements": "Összes elismerő nyilatkozat letöltése",
    "download_all_notification_emails": "Összes értesítés letöltése",
    "dt_of_creation_of_permanent_insider_section": "(A jegyzék létrehozásának dátuma és időpontja)",
    "dt_of_last_update": "(Az utolsó frissítés dátuma és időpontja",
    "date_of_transmission_to_competent_authority": "(Az illetékes hatósághoz történő továbbítás dátuma",
    "export_the_permanent_insider_list": "Állandó bennfentes jegyzék exportálása",
    "first_name": "Keresztnév",
    "surname": "Vezetéknév",
    "birth_surname": "Születési név",
    "professional_telephone_number": "Munkahelyi telefonszám",
    "company_name": "Társaság neve",
    "company_address": "Társaság címe",
    "function_and_reason_for_being_insider": "Funkció és a bennfentesség oka",
    "function_and_reason_for_being_insider_title": "A szerepet, funkciót és az ebben a jegyzékben való szerepeltetés okát leíró szöveg.",
    "function_and_reason_for_being_insider_placeholder": "Funkció és a bennfentesség oka",
    "included": "Felvétel időpontja",
    "dt_of_persons_inclusion": "A bennfentes állandó bennfentesek szakaszába való felvételének dátuma és időpontja (yyyy-mm-dd, hh:mm UTC).",
    "birthdate": "Születési dátum",
    "national_id_card_number": "Személyi igazolvány szám",
    "personal_telephone_number": "Telefonszám",
    "personal_full_home_address": "Lakcím",
    "personal_full_home_address_title": "Ország, irányítószám, város, utca, házszám",
    "save_data": "Mentés",
    "name_of_the_insider": "Bennfentes személy neve",
    "data_completeness": "Adatok kitöltöttsége",
    "last_login": "Legutolsó bejelentkezés",
    "mar_18_notifications": "MAR 18(2) értesítések",
    "mar_18_acknowledgements": "MAR 18(2) elismerő nyilatkozatok",
    "not_logged_in_yet": "Nem lépett be",
    "select_version": "Válasszon verziót",
    "permanent_insider_list": "Állandó bennfentesek jegyzéke",
    "insider": "bennfentes",
    "notifications_and_acknowledgements": "Értesítések és nyilatkozatok",
    "add_new_person": "Új személy hozzáadása",
    "edit": "Szerkesztés",
    "disable_editing": "Szerkesztés letiltása",
    "email": "E-mail",
    "last_name": "Vezetéknév",
    "birth_last_name": "Születési név",
    "dt_of_the_entry": "A bennfentes állandó bennfentesek szakaszába való felvételének dátuma és időpontja. (yyyy-mm-dd, hh: dd UTC)",
    "obtained": "Hozzáadás dátuma",
    "obtained_info": "Teszt",
    "reason_for_listing": "Funkció és a bennfentesség oka",
    "add_the_person_as_central_contract_point": "Központi kapcsolattartóként kerül felvételre?",
    "yes": "Igen",
    "no": "Nem",
    "notify_the_insider": "A bennfentes személy értesítése a bennfentes jegyzékbe vétel tényéről, a vonatkozó jogi és szabályozási kötelezettségekről, valamint a bennfentes kereskedelem és a bennfentes információk jogosulatlan közzététele tekintetében alkalmazandó szankciókról a MAR 18. cikk (2) bekezdése alapján.",
    "confirm": "Megerősítés",
    "download": "Letöltés",
    "date_sent": "Küldés dátuma",
    "subject": "Tárgy",
    "body": "Törzs",
    "inclusion_email_attachments": "Felvételi e-mail mellékletek",
    "uploaded_files": "Feltöltött fájlok",
    "upload_file": "Fájl feltöltése",
    "send": "Küldés"
  },
  "login-code-verification": {
    "test": ""
  },
  "login-email-notification": {
    "login_to_mabureg": "Bejelentkezés",
    "your_one_time_use_link_has_been_sent": "Az egyszer használatos linket elküldtük az Ön email címére {email}! Kérjük, ellenőrizze e-mailjeit, és használja a linket a bejelentkezéshez."
  },
  "login-password": {
    "enter_your_password_below": "Jelszó",
    "password": "Jelszó",
    "forgot_password": "Elfelejtett jelszó...",
    "log_in": "Bejelentkezés",
    "old_password": "Régi jelszó",
    "login_to_mabureg": "Bejelentkezés"
  },
  "login": {
    "login_to_mabureg": "Bejelentkezés",
    "email_address": "E-mail cím",
    "phone_number": "Telefonszám",
    "password": "Jelszó",
    "enter_your_email_below": "Kérjük, adja meg a bejelentkezési adatait!",
    "email_address_phone_number": "E-mail cím / telefonszám*",
    "log_in": "Bejelentkezés",
    "forgot_password": "Elfelejtett jelszó...",
    "new_client": "Új ügyfél",
    "register": "Regisztráció"
  },
  "main": {
    "clients": "Ügyfelek",
    "search_by_name": "Keresés név szerint",
    "name": "Név",
    "invoice": "Számla",
    "contracts": "Szerződések",
    "subscriptions": "Előfizetések",
    "view_client": "Ügyfél megtekintése",
    "automated_emails": "Automatikus e-mailek",
    "folder_name": "Mappa neve",
    "open_folder": "Mappa megnyitása",
    "email_templates": "E-mail sablonok",
    "template_name": "Sablon neve",
    "edit_template": "Sablon szerkesztése",
    "category": "Kategória",
    "type": "Típus",
    "template_name_en": "Sablon neve (angol)",
    "template_name_hu": "Sablon neve (magyar)",
    "subject_en": "Tárgy (angol)",
    "subject_hu": "Tárgy (magyar)",
    "body_en": "Törzs (angol)",
    "body_hu": "Törzs (magyar)",
    "category_required": "Kategória szükséges",
    "type_required": "Típus szükséges",
    "template_name_hu_required": "Sablon neve (magyar) szükséges",
    "template_name_en_required": "Sablon neve (angol) szükséges",
    "subject_hu_required": "Tárgy (magyar) szükséges",
    "subject_en_required": "Tárgy (angol) szükséges",
    "body_hu_required": "Törzs (magyar) szükséges",
    "body_en_required": "Törzs (angol) szükséges",
    "template_saved_successfully": "Sablon sikeresen mentve",
    "save": "Mentés",
    "cancel": "Mégsem"
  },
  "market-data-room-details": {
    "market_sounding_data_room": "Piaci tapogatózás - { dataroom }",
    "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba - { dataroom }",
    "uploaded_files": "Feltöltött fájlok",
    "people_with_access": "Hozzáféréssel rendelkezők",
    "upload_new_documents": "Új dokumentum(ok) feltöltése",
    "delete": "Elvetés",
    "download": "Letöltés",
    "download_document_list": "Dokumentumlista letöltése",
    "download_all_files_zip": "Az összes fájl letöltése",
    "file_name": "Fájlnév",
    "time_of_upload": "Feltöltés időpontja",
    "folder_name": "Mappa neve",
    "open_folder": "Mappa megnyitása",
    "create_new_folder": "Új mappa létrehozása",
    "create": "Létrehozás",
    "discard": "Elvetés",
    "rename": "Átnevezés",
    "this_file_contains_inside_information": "A fájl bennfentes információt tartalmaz.",
    "select_insider_information_from_the_list": "Kérjük, válassza ki az érintett bennfentes információt!",
    "insider_information_related_to_the_file": "A fájlhoz kapcsolódó bennfentes információ(k):",
    "upload_file": "Feltöltés",
    "yes": "Igen",
    "no": "Nem",
    "please_select_the_files": "Kérjük, válassza ki azon fájlokat, amelyek bennfentes információt tartalmaznak!",
    "upload_files": "Feltöltés",
    "please_select_the_file_list": "Kérjük, ellenőrizze a feltölteni kívánt fájlok és a hozzájuk rendelt bennfentes információk helyességét!",
    "grant_access": "Hozzáférés adása",
    "take_away_access_from_everyone": "Hozzáférés elvétele minden személytől",
    "take_away_access": "Hozzáférés elvétele",
    "email_notifications": "E-mail értesítés(ek)",
    "acknowledgement": "Elismerő nyilatkozatok",
    "download_list_of_people_with_access": "Hozzáféréssel rendelkező személyek listájának letöltése",
    "download_notification_emails_and_statements": "Összes elismerő nyilatkozat és értesítés letöltése",
    "personal_data": "Személyes adatok",
    "notification_status": "Értesítés hozzáférésről",
    "statement_of_consent_status": "Elismerő nyilatkozatok",
    "termination_of_access": "Hozzáférés megszűnése",
    "telephone_number": "Telefonszám",
    "dt_placeholder": "yyyy.mm.dd; hh:mm",
    "the_person_to_be_added": "A megadott személy még nem szerepel az adatszobában található bennfentes információt tartalmazó fájlhoz tartozó bennfentes listán.",
    "name_of_inside_information": "Bennfentes információ neve",
    "notification_and_insider_listing": "Értesítés és bennfentesjegyzékbe történő felvétel",
    "address": "Hozzáférés",
    "subject": "Tárgy",
    "body": "Tartalom",
    "email": "E-mail",
    "inclusion_email_attachments": "Mellékletek (Ezek a fájlok letölthetők és az értesítő e-mailhez csatolhatók)",
    "send": "Küldés",
    "first_name": "Keresztnév",
    "last_name": "Vezetéknév",
    "phone_number": "Telefonszám"
  },
  "my-logins": {
    "test": ""
  },
  "new-client": {
    "login_to_mabureg": "Bejelentkezés",
    "create_new_client": "Hozzon létre új MABUREG klienst",
    "new_client_created_successfully": "Új kliens sikeresen létrehozva!",
    "this_is_your_account_uid": "Ez az Ön fiók azonosítója:",
    "email_address": "E-mail cím",
    "phone_number": "Telefonszám",
    "password": "Jelszó",
    "confirm_password": "Jelszó megerősítése",
    "register": "Regisztráció",
    "log_in": "Bejelentkezés"
  },
  "new-confidential": {
    "confidential_list": "Bizalmas lista",
    "project_name": "Projekt neve",
    "description_of_the_sensitive_information": "Bizalmas információ leírása",
    "current_date_and_time": "Késleltetési döntés időpontja:",
    "it_can_be_modified": "(módosítható)",
    "save": "Mentés",
    "cancel": "Elvetés"
  },
  "new-contact": {
    "contact_details": "Az ügyfél adatai",
    "clients_name": "Teljes név",
    "clients_address": "Cím",
    "clients_company_registration_number": "Cégjegyzékszám",
    "investor_contacts_name": "Kapcsolattartó neve",
    "investor_contacts_position": "Kapcsolattartó beosztása",
    "investor_contacts_email_address": "Kapcsolattartó e-mail címe",
    "investor_contacts_phone_number": "Kapcsolattartó telefonszáma",
    "subscribed_package": "Előfizetett csomag",
    "regulated_market": "Szabályozott piac",
    "sme": "SME",
    "advisory": "Tanácsadó",
    "discard": "Elvetés",
    "save": "Mentés",
    "email_address": "E-mail cím",
    "tax_number": "Adószám",
    "data_protection_officer": "Adatvédelmi tisztviselő",
    "next": "Next",
    "loading": "Betöltés",
    "save_selected_representatives": "Kiválasztott képviselők mentése",
    "items_per_page": "Elemek oldalanként",
    "page": "Oldal",
    "board_member": "Igazgatósági tag",
    "representative": "Képviselő",
    "position_at_client": "Pozíció az ügyfél cégénél",
    "first_name": "Keresztnév",
    "last_name": "Vezetéknév",
    "phone_number": "Telefonszám",
    "place_of_birth": "Születési hely",
    "date_of_birth": "Születési dátum",
    "id_number": "Személyi igazolvány szám",
    "date_of_inclusion": "Hozzáadás dátuma",
    "send_a_mar_notification": "A MAR 19. cikk (5) bekezdése szerinti értesítés megküldése a hozzáadással egyidejűleg."
  },
  "new-info-qualifications": {
    "reclassify_the_information": "Információ átminősítése",
    "requalification_of_information": "Információ átminősítése",
    "project_name": "Projekt neve",
    "please_indicate_the_name_of_the_project": "Kérjük, adja meg a projekt nevét (pl. egyesülés, MABU projekt stb.). Fontos, hogy ez a mező nem tartalmazhat semmilyen bennfentes információt.",
    "description_of_the_information": "A minősítendő információ részletei",
    "current_date_and_time": "Date of qualification",
    "it_can_be_modified": "(módosítható)",
    "uploaded_files": "Feltöltött fájlok",
    "upload_file": "Feltöltés",
    "attention_the_insider_information_is_being_created": "Figyelem! Ez a bennfentes információ készül, kérjük, ne zárja be az ablakot, és ne menjen tovább sehova!",
    "attention_the_selected_files_are_uploading": "Figyelem! A kiválasztott fájlok feltöltődnek a szerverre, kérjük, ne zárja be az ablakot, és ne lépjen tovább!",
    "discard": "Elvetés",
    "classify_information": "Információ minősítése",
    "what_do_you_want_to_do_with_the_information": "A bennfentes információt haladéktalanul közzé kell tenni, kivéve, ha a késleltetés feltételei fennállnak. Mit szeretne tenni a bennfentes információval?",
    "delay": "Késleltetés",
    "immediate_publication": "Közzététel",
    "this_information_does_not_qualify": "Ez az információ nem minősül bennfentes információnak. Szeretne egy bizalmas listát létrehozni?",
    "no": "Nem",
    "yes": "Igen",
    "attention_you_have_not_qualified": "Figyelem! Nem minősítette az összes lehetőséget! Kérjük, töltsön ki minden szükséges mezőt!",
    "ok": "Ok",
    "files_uploaded_successfully": "Fájlok feltöltése sikeres!",
    "successfully_created": "Sikeres létrehozás!",
    "edit_the_information": "Szerkessze az információkat",
    "confirm_edit_the_information": "Megerősítés Szerkessze az információkat",
    "do_you_want_to_Reclassify_the_information": "Át szeretné-e minősíteni az információkat?",
    "save_changes": "Változtatások mentése"
  },
  "new-password": {
    "test": ""
  },
  "non-market-data-room-details": {
    "market_sounding_data_room": "Piaci tapogatózás - { dataroom }",
    "non_market_sounding_data_room": "Nem piaci tapogatózásos adatszoba - { dataroom }",
    "uploaded_files": "Feltöltött fájlok",
    "people_with_access": "Hozzáféréssel rendelkezők",
    "upload_new_documents": "Új dokumentum(ok) feltöltése",
    "delete": "Elvetés",
    "download": "Letöltés",
    "download_document_list": "Dokumentumlista letöltése",
    "download_all_files_zip": "Az összes fájl letöltése",
    "file_name": "Fájlnév",
    "time_of_upload": "Feltöltés időpontja",
    "folder_name": "Mappa neve",
    "open_folder": "Mappa megnyitása",
    "create_new_folder": "Új mappa létrehozása",
    "create": "Létrehozás",
    "discard": "Elvetés",
    "rename": "Átnevezés",
    "this_file_contains_inside_information": "A fájl bennfentes információt tartalmaz.",
    "select_insider_information_from_the_list": "Kérjük, válassza ki az érintett bennfentes információt!",
    "insider_information_related_to_the_file": "A fájlhoz kapcsolódó bennfentes információ(k):",
    "upload_file": "Feltöltés",
    "yes": "Igen",
    "no": "Nem",
    "please_select_the_files": "Kérjük, válassza ki azon fájlokat, amelyek bennfentes információt tartalmaznak!",
    "upload_files": "Feltöltés",
    "please_select_the_file_list": "Kérjük, ellenőrizze a feltölteni kívánt fájlok és a hozzájuk rendelt bennfentes információk helyességét!",
    "grant_access": "Hozzáférés adása",
    "take_away_access_from_everyone": "Hozzáférés elvétele minden személytől",
    "take_away_access": "Hozzáférés elvétele",
    "email_notifications": "E-mail értesítés(ek)",
    "acknowledgement": "Elismerő nyilatkozatok",
    "download_list_of_people_with_access": "Hozzáféréssel rendelkező személyek listájának letöltése",
    "download_notification_emails_and_statements": "Összes elismerő nyilatkozat és értesítés letöltése",
    "personal_data": "Személyes adatok",
    "notification_status": "Értesítés hozzáférésről",
    "statement_of_consent_status": "Elismerő nyilatkozatok",
    "termination_of_access": "Hozzáférés megszűnése",
    "telephone_number": "Telefonszám",
    "dt_placeholder": "yyyy.mm.dd; hh:mm",
    "the_person_to_be_added": "A megadott személy még nem szerepel az adatszobában található bennfentes információt tartalmazó fájlhoz tartozó bennfentes listán.",
    "name_of_inside_information": "Bennfentes információ neve",
    "notification_and_insider_listing": "Értesítés és bennfentesjegyzékbe történő felvétel",
    "address": "Hozzáférés",
    "subject": "Tárgy",
    "body": "Tartalom",
    "email": "E-mail",
    "inclusion_email_attachments": "Mellékletek (Ezek a fájlok letölthetők és az értesítő e-mailhez csatolhatók)",
    "send": "Küldés",
    "first_name": "Keresztnév",
    "last_name": "Vezetéknév",
    "phone_number": "Telefonszám"
  },
  "packages": {
    "subscribed_package_and_additional_services": "Előfizetett csomag és kiegészítő szolgáltatások",
    "other_package_offers": "Csomagajánlatok",
    "request": "Ajánlatkérés",
    "package_name": "Csomag neve",
    "function_module": "Elérhető modul",
    "optional": "Kiegészítő szolgáltatás"
  },
  "pca": {
    "pdmr_pca_list": "PDMR / PCA nyilvántartás",
    "pdmr_information": "PDMR információk",
    "name": "Teljes név",
    "position_at_client": "Beosztás",
    "email_address": "E-mail cím",
    "phone_number": "Telefonszám",
    "id_number": "Személyi igazolvány szám",
    "place_of_birth": "Születési hely",
    "date_of_birth": "Születési dátum",
    "time_of_inclusion": "Felvétel időpontja",
    "modify": "Módosítás",
    "discard": "Elvetés",
    "save": "Mentés",
    "add_a_new_pca": "PCA hozzáadása",
    "download_all_notifications": "Összes értesítés letöltése",
    "pca_name": "PCA neve",
    "pca_email_address": "PCA e-mail címe",
    "reported_transactions": "Bejelentett ügyletek",
    "notify_new_transaction": "Új ügylet bejelentése",
    "download_application": "Bejelentés letöltése",
    "periods_covered_by_the_trade_ban": "Kereskedelmi tilalommal érintett időszakok",
    "requests_on_trading_ban_exemption": "Kereskedelmi tilalom alóli felmentések iránti kérelmek",
    "new_application": "Új kérelem létrehozása",
    "download": "Kérelem letöltése",
    "insider_list": "Bennfentes jegyzékek",
    "market_sounding_data_room": "Piaci tapogatózásos adatszobák",
    "non_market_sounding_data_room": "Nem piacérzékelő adatszobák",
    "data_room_name": "Adatszoba neve",
    "transaction_details": "Az ügylet alapadatai",
    "notification_date": "A bejelentés időpontja",
    "request_id": "Kérelem azonosítója",
    "date": "Dátum",
    "approved_rejected": "Állapot",
    "request_sent": "Kérelem elküldve",
    "request_link": "Kérelem linkje",
    "function_and_reason_for_being_insider_placeholder": "Funkció és a bennfentesség oka",
    "new_transaction": "Új ügylet bejelentése"
  },
  "pdmr-list": {
    "pdmr_pca_list": "PDMR / PCA nyilvántartás",
    "notifications": "Értesítések",
    "add_new": "Új hozzáadása",
    "add_new_pdmr": "Új PDMR hozzáadása",
    "add_new_pca": "Új PCA hozzáadása",
    "position_at_client": "Beosztás",
    "phone_number": "Telefonszám",
    "place_of_birth": "Születési hely",
    "date_of_birth": "Születési dátum",
    "id_number": "Személyi igazolvány szám",
    "date_of_inclusion": "Hozzáadás dátuma",
    "registered_seat": "Székhely",
    "registration_number": "Cégjegyzékszám",
    "download_list": "Lista letöltése",
    "pdmr_pca_data": "PDMR/PCA adatok",
    "date_completeness": "Adatok kitöltöttsége",
    "notification_status": "Értesítés státusza",
    "successful_modification": "Sikeres módosítás",
    "successful_deletion": "Sikeres törlés",
    "modify": "Módosítás",
    "save": "Mentés",
    "discard": "Elvetés",
    "delete": "Törlés",
    "back": "Vissza",
    "first_name": "Keresztnév",
    "last_name": "Vezetéknév",
    "email_address": "E-mail cím",
    "sync_data": "Adatok szinkronizálása",
    "attention_data_is_not_up_to_date": "Figyelem! Az adatok nem naprakészek!",
    "do_you_want_to_delete_this_pdmr_pca": "Biztosan törölni szeretné ezt a PDMR/PCA-t?",
    "related_pdmr": "Kapcsolódó PDMR",
    "please_choose": "Kérjük, válasszon a legördülő listából!",
    "default_select_example": "Kérjük, válasszon a legördülő listából!",
    "nature_of_the_relationship": "Kérjük, válasszon a legördülő listából!",
    "please_choose_a_pdmr": "Kérjük, válasszon PDMR-t és kapcsolati típust!",
    "next": "Következő",
    "email": "E-mail",
    "subject": "Tárgy",
    "body": "Tartalom",
    "inclusion_email_attachments": "Hozzáadás e-mail mellékletek",
    "uploaded_files": "Feltöltött fájlok",
    "upload_file": "Fájl feltöltése",
    "send": "Küldés",
    "company_name": "Társaság neve",
    "companys_registration_number": "Cégjegyzékszám",
    "notify_pdrm": "A MAR 19. cikk (5) bekezdése szerinti értesítés megküldése a hozzáadással egyidejűleg.",
    "entry_of_the_pca_in_the_register": "Hozzáadás",
    "pursuant_to_article_19_5": "A MAR 19. cikk (5) bekezdése értelmében a PDMR-eket és a PCA-kat írásban kell értesíteni a MAR szerinti kötelezettségeikről. Biztos, hogy nem szeretné elküldeni ezt az értesítést?",
    "decrypt_request": "Titkosítás feloldásának kérelme",
    "request_sent": "Kérelem elküldve",
    "up_to_date": "Frissítve",
    "loading": "Betöltés",
    "save_selected_representatives": "Kiválasztott képviselők mentése",
    "items_per_page": "Elemek oldalanként",
    "page": "Oldal",
    "board_member": "Igazgatósági tag",
    "representative": "Képviselő",
    "name_of_the_person": "Név",
    "no_data_to_show": "Nincs megjeleníthető adat",
    "download_all_notifications": "Összes értesítés letöltése"
  },
  "pdmr": {
    "pdmr_pca_list": "PDMR / PCA nyilvántartás",
    "pdmr_information": "PDMR információk",
    "name": "Teljes név",
    "position_at_client": "Beosztás",
    "email_address": "E-mail cím",
    "phone_number": "Telefonszám",
    "id_number": "Személyi igazolvány szám",
    "place_of_birth": "Születési hely",
    "date_of_birth": "Születési dátum",
    "time_of_inclusion": "Felvétel időpontja",
    "modify": "Módosítás",
    "discard": "Elvetés",
    "save": "Mentés",
    "add_a_new_pca": "PCA hozzáadása",
    "download_all_notifications": "Összes értesítés letöltése",
    "pca_name": "PCA neve",
    "pca_email_address": "PCA e-mail címe",
    "reported_transactions": "Bejelentett ügyletek",
    "notify_new_transaction": "Új ügylet bejelentése",
    "download_application": "Bejelentés letöltése",
    "periods_covered_by_the_trade_ban": "Kereskedelmi tilalommal érintett időszakok",
    "requests_on_trading_ban_exemption": "Kereskedelmi tilalom alóli felmentések iránti kérelmek",
    "new_application": "Új kérelem létrehozása",
    "download": "Kérelem letöltése",
    "insider_list": "Bennfentes jegyzékek",
    "market_sounding_data_room": "Piaci tapogatózásos adatszobák",
    "non_market_sounding_data_room": "Nem piacérzékelő adatszobák",
    "data_room_name": "Adatszoba neve",
    "transaction_details": "Az ügylet alapadatai",
    "notification_date": "A bejelentés időpontja",
    "request_id": "Kérelem azonosítója",
    "date": "Dátum",
    "approved_rejected": "Állapot",
    "request_sent": "Kérelem elküldve",
    "request_link": "Kérelem linkje",
    "function_and_reason_for_being_insider_placeholder": "Funkció és a bennfentesség oka"
  },
  "period-ban": {
    "fixing_the_period_covered_by_the_trading_ban": "A kereskedelmi tilalom hatálya alá tartozó időszak meghatározása",
    "start_date_of_publication_of_half_yearly": "A féléves pénzügyi jelentések megjelenésének kezdő időpontja:",
    "date_of_publication_of_half_yearly": "A féléves adatok közzétételének időpontja:",
    "end_date_of_publication_of_half_yearly": "A féléves pénzügyi beszámolók közzétételének záró dátuma:",
    "start_date_of_publication_of_annual": "Az éves pénzügyi beszámolók közzétételének kezdő időpontja:",
    "date_of_publication_of_annual": "Az éves számadatok közzétételének dátuma:",
    "end_date_of_publication_of_annual": "Az éves pénzügyi beszámolók közzétételének záró dátuma:",
    "save": "Mentés",
    "send_emails_automatically": "Automatikus e-mail értesítő küldése",
    "days_before_the_start_of_closed_period": "nappal a kereskedelmi tilalom kezdete előtt.",
    "list_of_persons_to_be_notified": "Értesítendő személyek listája",
    "date_sent": "Értesítés dátuma",
    "previous_notifications": "Korábbi értesítések",
    "requests_on_trade_ban_excemptions": "Kereskedelmi tilalom alóli felmentések iránti kérelmek",
    "reject": "Elutasítás",
    "accept": "Engedélyezés",
    "are_you_sure_you_want_to_accept_the_excemption": "Biztos, hogy elfogadja a felmentést?",
    "are_you_sure_you_want_to_reject_the_excemption": "Biztos, hogy elutasítja a felmentést?",
    "back": "Vissza",
    "pdmr_name": "PDRM neve",
    "email_address": "E-mail cím",
    "time_of_notification": "Értesítés időpontja",
    "automatic_email_notifications": "Automatikus e-mail értesítések",
    "name": "Név",
    "request_link": "A kérelem linkje",
    "dt_placeholder": "yyyy.mm.dd hh:mm",
    "successfully_updated_period": "Sikeresen frissített időszak!",
    "ban_period_successfully_updated": "Tilalmi időszak sikeresen frissítve!",
    "successfully_updated_notification_state": "Sikeresen frissítettük az értesítési állapotot!",
    "successfully_accepted_excemption_request": "Sikeresen elfogadott mentességi kérelem!",
    "successfully_rejected_excemption_request": "Sikeresen elutasított mentességi kérelem!",
    "there_is_no_notification": "Nincs értesítés",
    "time_period_should_be_updated": "Az időszakot frissíteni kell!"
  },
  "privacy-policy": {
    "test": ""
  },
  "published-reports": {
    "unpublished_pdmr_pca_reports": "Közzé nem tett PDMR/PCA bejelentések",
    "search": "Keresés",
    "provide_publishing_information": "Közzététel adatainak megadása",
    "published_pdmr_pca_reports": "Közzétett PDMR/PCA bejelentések",
    "posted_link": "Link",
    "name_of_applicant": "Bejelentő neve",
    "time_of_notification": "Bejelentés dátuma",
    "pdmr_pca": "PDRM / PCA",
    "view_form": "Kitöltött űrlapok",
    "dt_placeholder": "yyyy.mm.dd hh:mm"
  },
  "reset-password": {
    "reset_your_password": "Jelszó helyreállítás",
    "enter_your_registered_email_address": "Adja meg a fiókjához tartozó e-mail címet vagy telefonszámot, amire elküldjük a jelszó helyreállításához szükséges kódot!",
    "new_password": "Új jelszó",
    "reset_password": "Jelszó visszaállítása",
    "email_address": "E-mail cím",
    "enter_your_new_password": "Új jelszó",
    "confirm_password": "Véglegesítés",
    "repeat_password": "Jelszó ismét",
    "dont_have_an_account": "Nincs még fiókja?",
    "sign_up": "Regisztráció",
    "email_hone_number": "E-mail cím / telefonszám",
    "email_address_phone_number": "E-mail cím / telefonszám",
    "send_code": "Ellenőrző kód elküldése",
    "didnt_receive_code_yet": "Nem kapta meg a kódot?",
    "resend_link": "Újraküldés",
    "a_reset_link_has_been_sent_to_your_email_address": "A visszaállítási linket elküldtük az Ön e-mail címére."
  },
  "reset-sent": {
    "reset_your_password": "Jelszó helyreállítás",
    "didnt_receive_code_yet": "Nem kapta meg a kódot?",
    "resend_link": "Újraküldés",
    "a_reset_link_has_been_sent_to_your_email_address": "A visszaállítási linket elküldtük az Ön e-mail címére."
  },
  "serviceprovider": {
    "test": ""
  },
  "settings": {
    "change_password": "Jelszó módosítása",
    "old_password": "Régi jelszó",
    "password": "Jelszó",
    "confirm_password": "Jelszó megerősítése",
    "company_name": "Cég neve",
    "company_headquarters": "Cég székhelye",
    "automatically_log_off_users_after": "A felhasználók automatikus kijelentkeztetése",
    "input": "Bevitel",
    "minutes_of_inactivity": "perc inaktivitás után.",
    "change_data": "Adatok módosítása",
    "client_decrypt_request": "Ügyfél adatokhoz való hozzáférési kérelem",
    "login_history": "Korábbi bejelentkezések",
    "permanently_delete_profile": "Fiók törlése",
    "delete_my_account": "A fiókom törlése",
    "site_documents": "Oldal dokumentumai",
    "document_title": "Dokumentum címe",
    "document_type": "Dokumentum típusa",
    "document_content": "Dokumentum tartalma",
    "date": "Dátum",
    "edit_document": "Dokumentum szerkesztése",
    "from_date": "Dátumtól",
    "document_title_required": "Dokumentum címe szükséges",
    "from_date_required": "Dátumtól szükséges",
    "document_content_required": "Dokumentum tartalma szükséges",
    "create_document": "Dokumentum létrehozása",
    "name": "Név",
    "position": "Beosztás",
    "email": "E-mail",
    "phone_number": "Telefonszám",
    "company_registration_number": "Cégjegyzékszám",
    "headquarters": "Székhely",
    "tax_number": "Adószám",
    "lei_number": "LEI szám",
    "data_protection_officer": "Adatvédelmi tisztviselő",
    "client_name": "Ügyfél neve"
  },
  "sidebar": {
    "classification_and_delay_of_inside_info": "A belső információk besorolása és késleltetése.",
    "insider_list": {
      "title": "Bennfentesek listája",
      "submenu": {
        "permanent_insider_list": "Állandó bennfentesek listája",
        "transaction-specific_insiders_list": "Tranzakcióspecifikus bennfentesek listája",
        "confidential_list": "Bizalmas lista"
      }
    },
    "management_transactions": {
      "title": "Menedzsment tranzakciók",
      "submenu": {
        "record-keeping": "Nyilvántartás",
        "transaction_reporting_for_executives_and_close_associates": "Tranzakciós jelentések vezetők és közeli munkatársak számára",
        "trading_ban_period": "Kereskedési tilalom időszaka"
      }
    },
    "transfer_of_inside_info": "Belső információk átadása.",
    "transaction_form": "Ügylet bejelentés",
    "dashboard": "Kezdőlap"
  },
  "signup": {
    "signup_to_mabureg": "Regisztráció",
    "welcome_aboard_lets_begin": "Üdvözöljük a fedélzeten! Kezdjük!",
    "investor_contacts_email": "Kapcsolattartó e-mail címe",
    "investor_contacts_password": "Kapcsolattartó jelszava",
    "clients_name": "Ügyfél neve",
    "clients_address": "Ügyfél címe",
    "investor_contacts_name": "Kapcsolattartó neve",
    "investor_contacts_position": "Kapcsolattartó beosztása",
    "investor_contacts_phone_number": "Kapcsolattartó telefonszáma",
    "company_registration_number": "Cégjegyzékszám",
    "tax_number": "Adószám",
    "lei_number": "LEI-kód",
    "delegate_access_to_other_people": "Hozzáférési jog delegálása más személyeknek is.",
    "add_decision_maker": "+ Új döntéshozó delegálása",
    "next": "Tovább"
  },
  "site-document-create": {
    "document_title": "Dokumentum címe",
    "document_type": "Dokumentum típusa",
    "document_content": "Dokumentum tartalma",
    "date": "Dátum",
    "create_document": "Dokumentum létrehozása",
    "from_date": "Dátumtól",
    "document_title_required": "Dokumentum címe szükséges",
    "from_date_required": "Dátumtól szükséges",
    "document_content_required": "Dokumentum tartalma szükséges",
    "privacy_policy": "Adatkezelési szabályzat",
    "terms_and_conditions": "Általános szerződési feltételek"
  },
  "site-document-edit": {
    "document_title": "Dokumentum címe",
    "document_type": "Dokumentum típusa",
    "document_content": "Dokumentum tartalma",
    "date": "Dátum",
    "edit_document": "Dokumentum szerkesztése",
    "from_date": "Dátumtól",
    "document_title_required": "Dokumentum címe szükséges",
    "from_date_required": "Dátumtól szükséges",
    "document_content_required": "Dokumentum tartalma szükséges",
    "privacy_policy": "Adatkezelési szabályzat",
    "terms_and_conditions": "Általános szerződési feltételek"
  },
  "site-documents": {
    "site_documents": "Oldal dokumentumai",
    "create_document": "Dokumentum létrehozása",
    "edit": "Szerkesztés",
    "delete": "Törlés",
    "delete_document": "Dokumentum törlése",
    "cancel": "Mégsem",
    "document_title": "Dokumentum címe",
    "document_type": "Dokumentum típusa",
    "date": "Dátum"
  },
  "specific-insider-details": {
    "short_name_of_insider_information": "Leírás",
    "date_placeholder": "YYYY.MM.DD; hh:mm",
    "dt_of_creation_of_permanent_insider_section": "(A jegyzék létrehozásának dátuma és időpontja)",
    "dt_of_last_update": "Az utolsó frissítés dátuma és időpontja",
    "date_of_transmission_to_competent_authority": "Az illetékes hatósághoz történő továbbítás dátuma",
    "project_name": "Projekt neve",
    "inside_information": "Bennfentes információ",
    "add_new_person": "Új személy hozzáadása",
    "export_insider_list": "Ügyletspecifikus bennfentes jegyzék exportálása",
    "delete": "Törlés",
    "first_name": "Keresztnév",
    "surname": "Vezetéknév",
    "birth_surname": "Születési név",
    "professional_telephone_number": "Munkahelyi telefonszám",
    "companys_name": "Társaság neve",
    "companys_address": "Társaság címe",
    "function_and_reason_for_being_insider": "Funkció és a bennfentesség oka",
    "function_and_reason_for_being_insider_title": "A szerepet, funkciót és az ebben a jegyzékben való szerepeltetés okát leíró szöveg.",
    "function_and_reason_for_being_insider_placeholder": "Funkció és a bennfentesség oka",
    "included": "Felvétel időpontja",
    "obtained": "Hozzáadás dátuma",
    "ceased": "Hozzáférés megszünésének időpontja",
    "birthdate": "Születési dátum",
    "national_id_card_number": "Személyi igazolvány szám",
    "personal_telephone_number": "Telefonszám",
    "personal_full_home_address": "Lakcím",
    "personal_full_home_address_title": "Ország, irányítószám, város, utca, házszám",
    "discard": "Elvetés",
    "save_data": "Mentés",
    "this_insider_information_was_disclosed": "Ez a bennfentes információ egy piaci tapogatódzás részeként is átadásra került.",
    "sending_the_notice_is_a_legal_obligation": "A bennfentes személy értesítése a MAR 18. cikk (2) bekezdése alapján kötelező. Biztos benne, hogy nem kívánja elküldeni az értesítést?",
    "name_of_the_insider": "Bennfentes személy neve",
    "data_completeness": "Adatok kitöltöttsége",
    "last_login": "Legutolsó bejelentkezés",
    "do_you_want_to_delete_this_insider": "Biztosan törölni szeretné ezt a bennfentes személyt?",
    "back": "Vissza",
    "modify": "Módosítás",
    "deal_specific_insider_list": "Ügyletspecifikus bennfentes jegyzék",
    "insider_list": "Bennfentes személyek",
    "notifications_and_acknowledgements": "Értesítések és nyilatkozatok",
    "download_all_acknowledgements": "Összes elismerő nyilatkozat letöltése",
    "download_all_notification_emails": "Összes értesítés letöltése",
    "name_of_insider": "Bennfentes személy neve",
    "email_address": "E-mail cím",
    "date_and_time_of_creation": "Létrehozás időpontja"
  },
  "specific-insiders": {
    "closed_insider_lists": "Lezárt ügyletspecifikus jegyzékek",
    "current_insider_lists": "Aktuális ügyletspecifikus jegyzékek",
    "search": "Keresés",
    "download": "Letöltés",
    "details": "Részletek",
    "inside_information": "Bennfentes információ",
    "project_name": "Projekt neve",
    "time_of_creation": "Létrehozás dátuma",
    "number_of_persons_included": "Hozzáférő személyek száma",
    "date_of_closure": "Lezárás dátuma",
    "short_name_of_insider_information": "Leírás",
    "dt_placeholder": "yyyy.mm.dd.; hh:mm",
    "number_of_people": "Hozzáférő személyek száma"
  },
  "subscriptions": {
    "subscribed_package": "Előfizetett csomag és kiegészítő szolgáltatások",
    "subscribed_package_name": "Csomag neve",
    "download_content": "Szerződés letöltése",
    "change_subscription": "Előfizetés módosítása",
    "my_accounts": "Számláim",
    "seach_accounts_by_number": "Keresés számlaszám alapján",
    "account_number": "Számlaszám",
    "date_of_issue": "Kiállítás dátuma",
    "date_of_completion": "Teljesítés dátuma",
    "download_request": "Díjbekérő letöltése",
    "download_invoice": "Számla letöltése"
  },
  "terms-and-conditions": {
    "test": ""
  },
  "terms": {
    "please_read_the_terms_carefully": "Kérjük, figyelmesen olvassa el a feltételeket!",
    "please_provide_us_with_the_following": "Kérjük, adja meg adatait!",
    "phone_number": "Telefonszám",
    "address": "Telefonszám",
    "go_to_the_data_room": "Tovább az adatszobához"
  },
  "thank-you": {
    "thank_you": "Köszönjük!",
    "i_agree_to_privacy_policy": "Elismerem, hogy elolvastam és megértettem az Adatkezelési Szabályzatot.",
    "back": "Mégsem",
    "next": "Tovább"
  },
  "unauthorized": {
    "unauthorized": "Hiányzó jogosultság",
    "it_seems_like_you_dont_have_access": "Úgy tűnik, hogy nincs hozzáférése ehhez az oldalhoz.",
    "go_back": "Vissza",
    "report": "Jelentés"
  },
  "upload-application": {
    "upload_application": "Kérelem feltöltése",
    "file_name": "Fájlnév",
    "proceed": "Kérelem benyújtása"
  },
  "verify-code": {
    "login_to_mabureg": "Bejelentkezés",
    "a_verification_code_has_been_sent": "E-mail-címére / telefonjára elküldtünk egy ellenőrző kódot, amely 10 percig érvényes.",
    "code": "Ellenőrző kód",
    "verify_login": "Megerősítés és bejelentkezés"
  }
}
import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';
import helpers from '@/helpers/helpers';
import { useUserStore } from '@/stores/user';

export const useBillingStore = defineStore('billing', () => {
  const billingData = ref(null);
  const store = useUserStore();

  async function fetchBillingData() {
    try {
      const response = await axios.post('/api/billing/get', { password: store.key });
      billingData.value = response.data;
    } catch (er) {
      billingData.value = null;
    }
  }

  async function saveBillingData(payload) {
    try {
      const endpoint = billingData.value ? '/api/billing/update' : '/api/billing/create';
      await axios.post(endpoint, { ...payload, password: store.key });
      billingData.value = payload;
      helpers.successToast('Billing data saved successfully');
    } catch (er) {
      console.log({ ...er });
      helpers.displayAllErrorsAsToasts(er);
    }
  }

  return { billingData, fetchBillingData, saveBillingData };
});

import { createApp } from 'vue';
import App from './App.vue';
import VueToast from 'vue-toast-notification';
import router from './router';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'bootstrap';
import helpers from './helpers/helpers';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import 'vue-toast-notification/dist/theme-default.css';
import VueClickAway from 'vue3-click-away';
import {
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faEye,
  faEyeSlash,
  faPlus,
  faSearch,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { CKEditor } from '@ckeditor/ckeditor5-vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import { Chart } from 'chart.js';
import en from './locales/generated-en.json';
import hu from './locales/generated-hu.json';
import * as Sentry from '@sentry/vue';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

library.add(faBars, faSearch, faTimesCircle, faEyeSlash, faEye, faPlus, faChevronLeft, faChevronRight, faChevronDown);

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.baseURL = import.meta.env.VITE_AXIOS_BASE_URL;

const helperPlugin = {
  install(app, options) {
    app.config.globalProperties.$helpers = helpers;
  },
};

const i18n = createI18n({
  locale: localStorage.getItem('locale') ?? 'en',
  messages: { en, hu },
  fallbackLocale: 'en',
  legacy: false,
});

const pinia = createPinia();

Chart.register({
  id: 'noDataPlugin',
  afterDraw: function (chart) {
    if (chart.data.datasets[0].data.every((item) => item === 0)) {
      chart.ctx.canvas.style.display = 'none';
      const id = chart.ctx.canvas.id;
      const msgElem = document.getElementById(`chart-no-data-${id}`);
      if (msgElem) msgElem.style.display = 'block';
    }
  },
});

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://d0f5fca97993ec284f77657fa5f1ded8@sentry.weborigo.eu/23',
});

app
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('Datepicker', Datepicker)
  .component('autocomplete', Autocomplete)
  .use(router)
  .use(VueToast)
  .use(helperPlugin)
  .use(VueClickAway)
  .use(CKEditor)
  .use(VueAxios, axios)
  .use(i18n)
  .use(pinia)
  .use(FloatingVue);

app.mount('#app');

export const getInstance = () => app;

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import axios from 'axios';
import availablePermissions from '@/constants/temp-permissions.json';
import helpers from '@/helpers/helpers';

export const useUserStore = defineStore('user', () => {
  const user = ref(null);
  const hasKey = ref(false);
  const key = ref(null);
  const email = ref(null);
  const name = 'loggedinuser name';
  const initialized = ref(false);
  const activeClient = computed(() => user.value?.active_client_id);
  const activeClientAbility = computed(() => (user.value?.abilities?.length ? user.value.abilities[0] : null));
  const userAccess = ref({ permissions: [], roles: [], subscriptions: [] });
  const isAdmin = computed(() => user.value?.is_admin);
  const mainAbility = computed(() => (isAdmin.value ? 'admin' : activeClientAbility.value));
  const loggedIn = computed(() => user.value?.id > 0);
  const clients = computed(() =>
    user.value?.clients.map((client) => ({
      id: client?.id,
      name: client?.name,
      abilities: client?.abilities,
    })),
  );
  const isDelegatedUser = computed(() => userAccess.value?.roles[0] === 'delegated_user');

  async function fetchUser() {
    try {
      const { data } = await axios.get('/api/user/me');
      if (data?.id > 0) {
        user.value = data;
        hasKey.value = data.has_key;
        user.value.permissions = availablePermissions.permissions;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchPendingShareRequests() {
    try {
      await axios.post('/api/util/pending-share-requests', { password: key.value });
    } catch (err) {
      console.log(err);
    }
  }

  async function requestClientDecryption() {
    try {
      const { data } = await axios.post('/api/client/share-with-me', {
        password: key.value,
        modelId: user.value?.active_client_id,
      });
      await requestClientUserDecryption();
    } catch (err) {
      console.log(err);
    }
  }

  async function requestClientUserDecryption() {
    try {
      const { data2 } = await axios.post('/api/share-request/client-user', {
        password: key.value,
        modelId: user.value?.client_user_id,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchRolesAndPermissions() {
    try {
      const { data } = await axios.post('/api/auth/me/permissions', { password: key.value });
      userAccess.value = data;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchDecryptedUser() {
    try {
      let emailAddress = email.value;
      if (!emailAddress) emailAddress = localStorage.getItem('email');

      let masterKey = key.value;
      if (!masterKey) masterKey = localStorage.getItem('master_key') || null;
      key.value = masterKey;
      if (!masterKey) return;

      const { data } = await axios.post('/api/auth/me', { password: masterKey, email: emailAddress });
      // if (!emailAddress) {
      emailAddress = data.email.includes('@') ? data.email : emailAddress;
      localStorage.setItem('email', emailAddress);
      // }
      email.value = emailAddress;

      await axios.post('/api/util/pending-share-requests', { password: masterKey });
      if (data?.id > 0) {
        user.value = data;
        user.value.permissions = availablePermissions.permissions;
        user.value.decrypted = true;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function logout() {
    try {
      await axios.post('/api/auth/logout');
      user.value = null;
      setInitialized(false);

      localStorage.removeItem('access_token');
      axios.defaults.headers.common['Authorization'] = '';

      localStorage.removeItem('master_key');
      key.value = null;
      localStorage.removeItem('email');
      email.value = null;

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  function setInitialized(value) {
    initialized.value = value;
  }

  function setUser(value) {
    user.value = value;
  }

  async function testMasterPassword(value) {
    await axios.post('/api/keys/test', { password: value });
    key.value = value;
  }

  async function createMasterKey(payload) {
    try {
      await axios.post('/api/keys/create', payload);
      key.value = payload.password;
      helpers.successToast('Master password is created successfully');
      return true;
    } catch (err) {
      helpers.displayAllErrorsAsToasts(err);
      return false;
    }
  }

  async function editMasterKey(payload) {
    try {
      await axios.put('/api/keys/update-password', payload);
      key.value = payload.password;
      helpers.successToast('Master password is updated successfully');
      localStorage.setItem('master_key', payload.password);
      return true;
    } catch (err) {
      helpers.displayAllErrorsAsToasts(err);
      return false;
    }
  }

  async function switchUser(id) {
    await axios.post('/api/user/switch-client', { client_id: id, password: key.value });
  }

  async function updateUserInfo(payload) {
    await axios.post('/api/user/update/me', { password: key.value, ...payload });
  }

  async function getActiveClientLastChangeDate() {
    const { data } = await axios.post('/api/client/' + activeClient.value, { password: key.value });
    return data.last_change_date;
  }

  return {
    user,
    initialized,
    activeClient,
    activeClientAbility,
    userAccess,
    isAdmin,
    mainAbility,
    loggedIn,
    clients,
    key,
    hasKey,
    email,
    name,
    isDelegatedUser,
    setUser,
    fetchUser,
    fetchPendingShareRequests,
    requestClientDecryption,
    requestClientUserDecryption,
    fetchRolesAndPermissions,
    fetchDecryptedUser,
    logout,
    setInitialized,
    testMasterPassword,
    createMasterKey,
    editMasterKey,
    switchUser,
    updateUserInfo,
    getActiveClientLastChangeDate,
  };
});

export const rolePagesAccess = {
  all: [
    'main',
    'unauthorized',
    'thank-you',
    '404',
    '101',
    'unupgraded',
    'new-password',
    'reset-sent',
    'terms-and-conditions',
    'privacy-policy',
    'packages',
  ],
  guest: [
    'reset-password',
    'verify-code',
    'login',
    'signup',
    'login-password',
    'login-email-notification',
    'login-code-verification',
    'new-client',
  ],
  auth: ['settings'],
  admin: [
    'serviceprovider',
    'site-documents',
    'site-document-edit',
    'site-document-create',
    'my-logins',
    'admin-billing',
    'admin-client-billing',
    'signup',
    'adminEmailFolder',
    'editEmailTemplate',
    'change-password',
  ],
  client_contact: [
    'new-contact',
    'add-contact-pdmr',
    'edit-contact',
    'delay-insider-information',
    'new-info-qualifications',
    'specific-insider-details',
    'classification-info',
    'specific-insiders',
    'insider-list',
    'control-panel-insider-list',
    'data-room-details',
    'data-room-folder',
    'data-rooms',
    'period-ban',
    'confidential-details',
    'new-confidential',
    'confidential-list',
    'published-reports',
    'pdmr-list',
    'dashboard',
    'info-requalification',
    'settings',
    'upload-application',
    'my-logins',
    'data-room-details',
    'control-panel-data-room',
    'market-data-room-details',
    'non-market-data-room-details',
    'data-rooms',
    'terms',
    'billing-data',
    'subscriptions',
    'sync-data',
    'era',
  ],
  delegated_user: {
    all: ['dashboard', 'billing-data'],
    read_qualify_delay_inside_info: ['delay-insider-information', 'specific-insider-details', 'classification-info'],
    edit_qualify_delay_inside_info: [
      'delay-insider-information',
      'specific-insider-details',
      'classification-info',
      'new-info-qualifications',
      'info-requalification',
    ],
    read_insider_list: ['specific-insiders', 'insider-list', 'control-panel-insider-list', 'confidential-list'],
    edit_insider_list: ['specific-insiders', 'insider-list', 'control-panel-insider-list', 'confidential-list'],
    read_pdmr_pca_transaction: ['pdmr-list'],
    edit_pdmr_pca_transaction: ['add-new-transaction', 'upload-application', 'pdmr-list', 'period-ban'],
    read_data_rooms: [
      'data-room-details',
      'data-room-folder',
      'control-panel-data-room',
      'market-data-room-details',
      'non-market-data-room-details',
      'data-rooms',
      'access-person',
    ],
    edit_data_rooms: [
      'data-room-details',
      'data-room-folder',
      'control-panel-data-room',
      'market-data-room-details',
      'non-market-data-room-details',
      'data-rooms',
      'access-person',
      'terms',
    ],
  },
  pdmr: [
    'settings',
    'add-new-transaction',
    'upload-application',
    'pdmr',
    'my-logins',
    'billing-data',
    'pdmr-fill-missing-data',
  ],
  pca: ['pca', 'billing-data', 'add-new-transaction', 'pdmr-fill-missing-data'],
  insider_list_user: ['control-panel-insider-list', 'billing-data'],
  data_room_user: [
    'control-panel-data-room',
    'market-data-room-details',
    'non-market-data-room-details',
    'terms',
    'access-person',
    'data-rooms',
    'billing-data',
  ],
};

export const roleDashboardPage = {
  admin: 'serviceprovider',
  client_contact: 'dashboard',
  delegated_user: 'dashboard',
  pdmr: 'pdmr',
  pca: 'pca',
  insider_list_user: 'control-panel-insider-list',
  data_room_user: 'control-panel-data-room',
  guest: 'login',
};

/**
 * @param {string[]} roles
 * @param {string} pageName
 * */
export function hasRoleAccess(roles, pageName) {
  return ['all', ...roles].some((role) => rolePagesAccess[role].includes(pageName));
}

/**
 * @param {string[]} permissions
 * @param {string} pageName
 * */
export function hasPermissionAccess(permissions, pageName) {
  const delegatePermissions = Object.entries(rolePagesAccess.delegated_user);
  return (
    delegatePermissions
      .filter(([key]) => (key === 'all' ? true : permissions.includes(key)))
      .some(([, value]) => value.includes(pageName)) || hasRoleAccess(['auth'], pageName)
  );
}

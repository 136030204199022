{
  "101": {
    "need_upgrade": "Need Upgrade",
    "it_seems_like_you_need_to_update": "It seems like you need to update your status in order to access this page",
    "go_back": "Go back",
    "report": "Report"
  },
  "404": {
    "404": "404",
    "it_seems_like_we_ran_into_trouble": "It seems like we ran into trouble loading this page",
    "go_back": "Go back",
    "report": "Report"
  },
  "access-person": {
    "download_the_document_list": "Download The Document List",
    "download_all_files": "Download All Files",
    "download_document": "Download Document",
    "file_name": "File Name",
    "uploaded": "Uploaded",
    "time_of_upload": "Time of Upload"
  },
  "add-new-transaction": {
    "name": "Name",
    "email": "E-mail",
    "position": "Position",
    "phone_number": "Phone Number",
    "this_form_is_required_for_disclosure": "This form is required for disclosure of transactions under Article 19 of Regulation (EU) No 596/2014 of the European Parliament and of the Council of 16 April 2014 on market abuse (Market Abuse Regulation)",
    "details_of_the_person_discharging": "1. Details of the person discharging managerial responsibilities/person closely associated",
    "natural_person": "Natural Person",
    "first_name": "First Name",
    "last_name": "Last Name",
    "legal_person": "Legal Person",
    "full_name": "Full Name",
    "modify": "Modify",
    "reason_for_the_notification": "2. Reason for the notification",
    "position_status": "Position/Status",
    "initial_notification": "Initial Notification",
    "amendment_to_prior_notification": "Amendment to Prior Notifications",
    "description": "Description",
    "details_of_the_issuer": "3. Details of the issuer, emission allowance market participant, auction platform, auctioneer or auction monitor",
    "full_name_of_the_entity": "FUll Name of the Entity",
    "lei_legal_entity_identifier": "LEI / Legal Entity Identifier",
    "details_of_the_transaction": "4. Details of the transaction(s): section to be repeated for (i) each type of instrument; (ii) each type of transaction; (iii) each date; and (iv) each place where transactions have been conducted",
    "details_of_the_transaction_section": "4.{idx}. Details of the transaction(s): section to be repeated for (i) each type of instrument; (ii) each type of transaction; (iii) each date; and (iv) each place where transactions have been conducted",
    "description_of_the_finincial_instrument": "Description of the financial instrument, type of instrument",
    "identification_code": "Identification Code",
    "nature_of_the_transaction": "Nature of the transaction",
    "description_of_the_transaction": "Description of the transaction type using, where applicable, the type of transaction identified in Article 10 of the Commission Delegated Regulation (EU) 2016/5221 adopted under Article 19(14) of Regulation (EU) No 596/2014 or a specific example set out in Article 19(7) of Regulation (EU) No 596/2014. Pursuant to Article 19(6)(e) of Regulation (EU) No 596/2014, it shall be indicated whether the transaction is linked to the exercise of a share option programme.",
    "prices_and_volumes": "Price(s) and Volume(s)",
    "aggregated_volume_and_price": "Aggregated Volume & Price",
    "time_of_the_transaction": "Time of the Transaction",
    "transaction_date_and_time": "Transaction Date & Time",
    "place_of_the_transaction": "Place of The Transaction",
    "name_and_code_to_identify_the_mifid": "Name and code to identify the MiFID trading venue, the systematic internaliser or the organised trading platform outside of the Union where the transaction was executed as defined under Commission Delegated Regulation supplementing Regulation (EU) No 600/2014 of the European Parliament and of the Council with regard to regulatory technical standards for the reporting of transactions to competent authorities adopted under Article 26 of Regulation (EU) No 600/2014, or if the transaction was not executed on any of the above mentioned venues, please mention 'outside a trading venue'.",
    "additional_information": "Additional Information",
    "submit_form": "Submit Form",
    "where_more_then_one_transaction": "Where more than one transaction of the same nature (purchases, sales, lendings, borrows, …) on the same financial instrument or emission allowance are executed on the same day and on the same place of transaction, prices and volumes of these transactions shall be reported in this field, in a two columns form as presented above, inserting as many lines as needed.",
    "new_detail": "Add new detail"
  },
  "billing-data": {
    "name": "Name",
    "zip_code": "Zip code",
    "city": "City",
    "address": "Address",
    "tax_number": "Tax Number",
    "save": "Save",
    "billing_title": "Change your billing Data",
    "billing_subtitle": "Enter your details below",
    "invoices": "Invoices",
    "contracts": "Contracts",
    "subscriptions": "Subscriptions",
    "view_client": "View Client",
    "billing": "Billing",
    "create_billingo_partner": "Create Billingo Partner",
    "new_invoice": "New Invoice",
    "client": "Client",
    "invoice_number": "Invoice Number",
    "download_invoice": "Download Invoice",
    "package": "Package",
    "period": "Period",
    "monthly": "Monthly",
    "yearly": "Yearly",
    "discard": "Discard",
    "date": "Date",
    "new_contract": "New Contract",
    "save_new_invoice": "Save New Invoice",
    "contract_name": "Contract Name",
    "contract_date": "Contract Date",
    "select_contract": "Select Contract",
    "download_contract": "Download Contract",
    "end_date": "End Date",
    "new_subscription": "New Subscription",
    "start_date": "Start Date",
    "finalize_subscription": "Finalize Subscription"
  },
  "charts": {
    "no_data_available": "No data available",
    "insider_list_information": "Insider List Information",
    "insider_list_statements": "Insider List Statements",
    "insider_list_count": "Insider List Count",
    "insider_list_user_count": "Insider List User Count",
    "market_sounding": "Market sounding",
    "below_33": "Below 33%",
    "between_33_66": "Between 33% and 66%",
    "above_66": "Above 66%",
    "filled": "Filled",
    "not_filled": "Not filled"
  },
  "classification-info": {
    "qualification_and_delay_of_inside_information": "Qualification and Delay of Inside Information",
    "add_new_information": "Add New Information",
    "current_inside_information": "Current Inside Information",
    "previous_inside_information": "Previous Inside Information",
    "sensitive_information": "Sensitive Information",
    "search": "Search",
    "delay_occurred": "Delay occurred",
    "delay": "Delay",
    "make_public": "Make public",
    "delete": "Delete",
    "do_you_want_to_cease": "Do you want to Cease the Insider Information?",
    "back": "Back",
    "cease": "Cease",
    "edit": "Edit",
    "disable": "Disable",
    "short_name": "Short Name of Insider Information",
    "date_identified": "Date when identified",
    "date_of_delay": "Date of Delay",
    "delay_occured": "Delay occured",
    "ceased": "Ceased",
    "statement_is": "Statement is",
    "fulfilled": "fulfilled",
    "not_fulfilled": "not fulfilled",
    "reason_for_delay": "Reason for Delay",
    "reclassify_the_information": "Reclassify The Information",
    "reclassify": "Reclassify",
    "requalification_of_information": "Qualification of New Information",
    "project_name": "Project Name",
    "please_indicate_the_name_of_the_project": "Please indicate the name of the project (e.g. merger, project MABU, etc.). It is important that this field cannot contain any inside information.",
    "description_of_the_information": "Description of the Information to be Qualified",
    "current_date_and_time": "Current Date & Time",
    "it_can_be_modified": "(it can be modified)",
    "uploaded_files": "Uploaded Files",
    "upload_file": "Upload File",
    "attention_the_insider_information_is_being_created": "Attention! This insider information is being created, please do not close the window, or proceed anywhere!",
    "attention_the_selected_files_are_uploading": "Attention! The selected files are uploading to the server, please do not close the window, or proceed anywhere!",
    "discard": "Discard",
    "classify_information": "Classify The Information",
    "what_do_you_want_to_do_with_the_information": "What do you want to do with the information?",
    "immediate_publication": "Immediate Publication",
    "this_information_does_not_qualify": "This information does not qualify as inside information. Do you want to create a confidential list?",
    "no": "No",
    "yes": "Yes",
    "attention_you_have_not_qualified": "Attention! You have not qualified all the options! Please fill out every necessary fields!",
    "ok": "Ok",
    "files_uploaded_successfully": "Files uploaded successfully!",
    "successfully_created": "Successfully created!",
    "edit_the_information": "Edit the information",
    "confirm_edit_the_information": "Confirm Edit the information",
    "do_you_want_to_Reclassify_the_information": "Do you want to Reclassify the information",
    "save_changes": "Save Changes",
    "download": "Download",
    "conceptual_elements_of_inside_information": "Conceptual Elements of Inside Information",
    "add_comment": "Add Comment",
    "choose": "Choose",
    "download_file": "Download File",
    "please_provide_information": "Please provide information on the disclosure of inside information.",
    "link": "Link",
    "k_number": "K-Number",
    "date_and_time_of_publication": "Date and time of publication",
    "approve": "Approve",
    "reclassification_of_information": "Reclassification of information",
    "date_of_qualification": "Date of Qualification",
    "do_you_want_to_delete_the_insider_information": "Do you want to Delete the Insider Information?",
    "create_confidential_list": "Create Confidential List",
    "confirm": "Confirm",
    "qualification_step_1_title": "Title 1",
    "qualification_step_1_description": "Description 1",
    "qualification_step_2_title": "Title 2",
    "qualification_step_2_description": "Description 2",
    "qualification_step_3_title": "Title 3",
    "qualification_step_3_description": "Description 3",
    "qualification_step_4_title": "Title 4",
    "qualification_step_4_description": "Description 4",
    "delay_step_1_title": "Delay title 1",
    "delay_step_1_description": "Delay description 1",
    "delay_step_2_title": "Delay title 2",
    "delay_step_2_description": "Delay description 2",
    "delay_step_3_title": "Delay title 3",
    "delay_step_3_description": "Delay description 3"
  },
  "confidential-details": {
    "confidential_list": "Confidential list",
    "date_placeholder": "DD/MM/YYYY",
    "date_and_time_of_creation": "(Date & Time of creation of the permanent insiders section)",
    "dt_of_last_update": "(Date & Time of the last update)",
    "a_brief_description_of_the_confidential_information": "A Brief Description of the Confidential Information",
    "confidential_list_long_description": "Confidential List Long Description",
    "add_new_person": "Add New Person",
    "delete": "Delete",
    "this_confidential_list_is_empty": "This Confidential List is empty. Please add at least one person to it!",
    "export_confidential_list": "Export Confidential List",
    "convert_to_a_deal_specific_insider_list": "Convert to a Deal-Specific Insider List",
    "name": "Name",
    "position": "Position",
    "email": "E-mail",
    "edit": "Edit",
    "disable_editing": "Disable Editing",
    "name_of_the_insider": "Name of the Insider",
    "data_completeness": "Data Completeness",
    "notification_emails": "Notification Email(s)",
    "acknowledgements": "Acknowledgements",
    "last_login": "Last Login",
    "save_data": "Save Data",
    "name_of_insider": "Name of Insider",
    "email_address": "E-mail Address",
    "back": "Back",
    "do_you_want_to_delete_this_insider": "Do you want to delete this insider?",
    "download_all_acknowledgements": "Download All Acknowledgements",
    "download_all_notification_emails": "Download All Notification Emails",
    "dt_of_creation_of_permanent_insider_section": "(Date & Time of creation of the permanent insiders section)",
    "date_of_transmission_to_competent_authority": "(Date of transmission to the competent authority)",
    "export_the_permanent_insider_list": "Export the Permanent Insider List",
    "first_name": "First Name",
    "surname": "Surname",
    "birth_surname": "Birth Surname",
    "professional_telephone_number": "Professional Telephone Number",
    "company_name": "Company Name",
    "company_address": "Company Address",
    "function_and_reason_for_being_insider": "Function and reason for being insider",
    "function_and_reason_for_being_insider_title": "Text describing the role, function and reason for inclusion in this list",
    "function_and_reason_for_being_insider_placeholder": "Function and Reason",
    "included": "Included",
    "dt_of_persons_inclusion": "The date and time of the person's inclusion in the permanent insiders section (yyyy-mm-dd, hh:mm UTC).",
    "birthdate": "Birthdate",
    "national_id_card_number": "National ID Card Number",
    "personal_telephone_number": "Personal Telephone Number",
    "personal_full_home_address": "Personal Full Home Address",
    "personal_full_home_address_title": "Country, postcode, city, street, house number",
    "mar_18_notifications": "MAR 18(2) Notifications",
    "mar_18_acknowledgements": "MAR 18(2) Acknowledgements",
    "not_logged_in_yet": "Not logged in yet",
    "select_version": "Select Version",
    "permanent_insider_list": "Permanent Insider List",
    "insider": "insider",
    "notifications_and_acknowledgements": "Notifications and Acknowledgments",
    "last_name": "Last Name",
    "birth_last_name": "Birth Last Name",
    "obtained": "Obtained",
    "dt_of_the_entry": "Date and time of the entry of the person into the permanent insider stage (yyyy-mm-dd, hh: dd UTC)",
    "reason_for_listing": "Reason for Listing",
    "add_the_person_as_central_contract_point": "Add the person as central contact point.",
    "yes": "Yes",
    "no": "No",
    "notify_the_insider": "Notify the insider about the inclusion in the insider list, the legal and regulatory duties entailed, and the sanctions applicable to insider dealing and unlawful disclosure of inside information as per Article 18(2) of MAR.",
    "confirm": "Confirm",
    "download": "Download",
    "date_sent": "Date Sent"
  },
  "confidential-list": {
    "closed_confidential_list": "Closed Confidential List",
    "confidential_list": "Confidential list",
    "create_new_confidential_list": "Create New Confidential List",
    "download": "Download",
    "details": "Details",
    "project_name": "Project Name",
    "date_and_time_of_creation": "Date and Time of Creation",
    "number_of_people": "Number of People",
    "short_name_of_information": "Short Name of Information",
    "dt_placeholder": "dd-mm-yyyy; hh:mm",
    "discard": "Discard",
    "create": "Create",
    "date": "Date",
    "transmission_to_authority_at": "Transmission to Authority at",
    "insider_information": "Insider Information",
    "create_insider_information": "Create Insider Information"
  },
  "control-panel-data-room": {
    "market_sounding_data_room": "Market Sounding Data Room",
    "data_room_name": "Data Room Name",
    "non_market_sounding_data_room": "Non-Market Sounding Data Room",
    "this_room_contains_insider_information": "This room contains insider information"
  },
  "dashboard": {
    "todo_item": "To-Do List",
    "add_new": "Add New",
    "no_permissions": "No permissions",
    "automated_emails": "Automated E-mails",
    "pdrm_emails": "PDMR / PCA Emails",
    "pca_emails": "PCA Emails",
    "emails_related_to_a_permanent_insider_list": "Emails Related to a Permanent Insider List",
    "emails_related_to_a_transaction_specific_insider_list": "Emails Related to a Transaction-Specific Insider List",
    "emails_related_to_data_rooms": "Emails Related to Data Rooms",
    "statements_and_statistics": "Statements & Statistics",
    "per": "Per",
    "individual_percentages": "Individual-percentages",
    "comapnys_inside_information": "Company's Inside Information",
    "per_specific_confidential_pdmr": "Per., specific, confidential, PDMR",
    "manage_permissions": "Manage Permissions",
    "pdmr_pca_emails": "PDMR / PCA Emails",
    "emails_related_to_trade_ban": "Emails Related to Trade ban",
    "show_more": "Show More",
    "show_less": "Show Less",
    "search_accounts": "Search Accounts",
    "fill_in_the_following_data": "Fill in the following data",
    "change_permission": "Change Permission",
    "privileges": "Privileges",
    "no_access": "No Access",
    "read_only": "Read-Only",
    "editor": "Editor",
    "inside_information": "Inside Information",
    "insider_list": "Insider list",
    "pdmr_pca": "PDRM/PCA",
    "data_rooms": "Data rooms",
    "user_created_successfully": "User created successfully",
    "save": "Save",
    "discard": "Discard",
    "name": "Name",
    "email": "E-mail",
    "position": "Position",
    "phone_number": "Phone number"
  },
  "data-room-details": {
    "market_sounding_data_room": "Market Sounding Data Room - { dataroom }",
    "non_market_sounding_data_room": "Non-Market Sounding Data Room - { dataroom }",
    "uploaded_files": "Uploaded Files",
    "people_with_access": "People with Access",
    "upload_new_documents": "Upload New Document(s)",
    "delete": "Delete",
    "download": "Download",
    "download_document_list": "Download Document List",
    "download_all_files_zip": "Download All Files (.zip)",
    "file_name": "File Name",
    "time_of_upload": "Time of Upload",
    "folder_name": "Folder Name",
    "open_folder": "Open Folder",
    "create_new_folder": "Create New Folder",
    "create": "Create",
    "discard": "Discard",
    "rename": "Rename",
    "this_file_contains_inside_information": "This file contains inside information.",
    "select_insider_information_from_the_list": "Select Insider Information from the List!",
    "insider_information_related_to_the_file": "Insider information related to the file.",
    "upload_file": "Upload File",
    "yes": "Yes",
    "no": "No",
    "please_select_the_files": "Please select the files that contain inside information!",
    "upload_files": "Upload Files",
    "please_select_the_file_list": "Please check the file list before uploading!",
    "name": "Name",
    "email_address": "Email Address",
    "grant_access": "Grant Access",
    "take_away_access_from_everyone": "Take Away Access From Everyone",
    "take_away_access": "Revoke access",
    "email_notifications": "E-mail Notification(s)",
    "acknowledgement": "Acknowledgement",
    "download_list_of_people_with_access": "Download List of People with Access",
    "download_notification_emails_and_statements": "Download Notification Emails and Statements",
    "personal_data": "Personal Data",
    "notification_status": "Notification Status",
    "statement_of_consent_status": "Statement of Consent Status",
    "termination_of_access": "Termination of access",
    "telephone_number": "Telephone Number",
    "dt_placeholder": "dd-mm-yyyy; hh:mm",
    "the_person_to_be_added": "The person to be added to this data room is not yet on the insider list in respect of the following inside information",
    "name_of_inside_information": "Name of Inside Information",
    "notification_and_insider_listing": "Notification & Insider Listing",
    "address": "Address",
    "subject": "Subject",
    "body": "Body",
    "email": "Email",
    "inclusion_email_attachments": "Attachments (These files can be downloaded and attached to the notification email)",
    "send": "Send",
    "first_name": "First Name",
    "last_name": "Last Name",
    "phone_number": "Phone Number",
    "folders_map": "Folders Map",
    "upload": "Upload",
    "size": "Size",
    "modified": "Modified",
    "title": "Title",
    "do_you_want_to_revoke_access_to_this_person": "Do you want to revoke access to this person?",
    "do_you_want_to_revoke_access_to_everyone": "Do you want to revoke access to everyone?",
    "delete_dataroom": "Delete Data Room",
    "are_you_sure_you_want_to_delete_this_data_room": "Are you sure you want to delete this data room?",
    "save": "Save",
    "name_of_the_data_room": "Name of the Data Room",
    "the_information_is_transferred": "The information is transferred in the course of a market sounding.",
    "view_a_guide": "View a Guide",
    "comment": "Comment"
  },
  "data-rooms": {
    "create_a_new_data_room": "Create a New Data Room",
    "market_sounding_data_room": "Market Sounding Data Room",
    "non_market_sounding_data_room": "Non-Market Sounding Data Room",
    "name_of_the_data_room": "Name of the data room",
    "the_information_is_transferred": "The information is transferred in the course of a market sounding.",
    "view_a_guide": "View A Guide",
    "create": "Create",
    "cancel": "Cancel",
    "next": "Next"
  },
  "delay-insider-information": {
    "delaying_inside_information": "Delaying Insider Information",
    "delaying_the_disclosure_of_inside_information": "Delaying the Disclosure of Inside Information",
    "current_date_and_time": "Current Date & Time",
    "it_can_be_modified": "(it can be modified)",
    "uploaded_files": "Uploaded Files",
    "upload_file": "Upload File",
    "attention_the_selected_files_are_uploading": " Attention! The selected files are uploading to the server, please do not close the window, or proceed anywhere!",
    "discard": "Discard",
    "save": "Save",
    "the_conditions_of_delaying": "The conditions of delaying the disclosure of inside information as prescribed by Article 17(4) of MAR are not met. Please disclose the inside information as soon as possible!",
    "back": "Back",
    "publish": "Publish",
    "the_recorded_delay_data_will_not_be_clumped": "The recorded delay data will not be clumped, but insider information will be saved!",
    "proceed": "Proceed",
    "attention_you_have_not_qualified": "Attention! You have not qualified all the options! Please fill out every necessary fields!",
    "ok": "Ok",
    "files_uploaded_successfully": "Files uploaded successfully!"
  },
  "dropdown": {
    "settings": "Settings",
    "pdmr": "PDMR",
    "pca": "PCA",
    "dashboard": "Dashboard",
    "my_subscription": "My Subscription",
    "era_form_filler": "ERA Form Filler",
    "billing_data": "Billing Data",
    "billing": "Billing",
    "change_password": "Change password",
    "login_history": "Login history",
    "edit_master_key": "Edit Master Key",
    "logout": "Logout"
  },
  "edit-contact": {
    "contact_details": "Contact Details",
    "clients_name": "Client's name",
    "clients_address": "Client's address",
    "clients_company_registration_number": "Client's Company Registration Number",
    "investor_contacts_name": "Investor Contact's Name",
    "investor_contacts_position": "Investor Contact's Position",
    "investor_contacts_email_address": "Investor Contact's Email Address",
    "investor_contacts_phone_number": "Investor Contact's Phone Number",
    "subscribed_package": "Subscribed Package",
    "regulated_market": "Regulated Market",
    "sme": "SME",
    "advisory": "Advisory",
    "discard": "Discard",
    "save": "Save",
    "email_address": "Email Address",
    "tax_number": "Tax Number",
    "data_protection_officer": "Data Protection Officer"
  },
  "era": {
    "description": "For using the ERA interface, Mabureg has a browser add-on, which can be used to speed up the filling of forms by transferring data from Mabureg.",
    "steps_phrase": "To use it, follow these steps:",
    "step_1": "The browser extension can be downloaded using the link below (<a href='' target='_blank' class='extension-link'>extension link</a>). The browser extension can be installed on Google Chrome browser, but is also available on Edge and Opera (you need to enable installation from the Chrome Webstore).",
    "step_2": "The downloaded browser add-on must then be installed, something to enable. For some browser versions this is automatic.",
    "step_3": "Once installed, the browser add-on is ready to use. All that is required is for the user to log in to the Mabureg web application, as the browser add-on does not have a separate login, it uses the Mabureg application and calls it to make the downloadable forms data available in it.",
    "step_4": "To fill in the forms, you need to open the form in the ERA interface, after which the browser add-on will offer you the options to fill in the form.",
    "end": "If the ERA system is updated, the browser add-on must also be updated. To do this, the browser add-on will display an error message."
  },
  "footer": {
    "requesting_an_offer": "Requesting an Offer",
    "contact_us": "Contact us",
    "privacy_policy": "Privacy Policy",
    "terms_and_conditions": "Terms and Conditions",
    "sitemap": "Sitemap",
    "cookies": "Cookies Statement",
    "back_to_top": "Back to top",
    "copyright_and_authorship_information": "Copyright and Authorship Information",
    "english": "English",
    "hungarian": "Hungarian",
    "it_security": "IT Security",
    "attention_currently_there_is_a_trade-ban_period": "Attention! Currently there is a trade-ban period."
  },
  "globals": {
    "components": {
      "base": {
        "form": {
          "the_password_must_contain_at_least_one_uppercase_letter_and_one_lowercase_letter": "The password must contain at least one uppercase letter and one lowercase letter.",
          "the_password_must_contain_at_least_one_symbol": "The password must contain at least one symbol.",
          "the_password_must_contain_at_least_one_number": "The password must contain at least one number.",
          "the_confirm_password_must_match_the_password": "The confirm password must match the password."
        },
        "charts": {
          "no_data_available": "No data available",
          "insider_list_information": "Insider List Information",
          "insider_list_statements": "Insider List Statements",
          "insider_list_count": "Insider List Count",
          "insider_list_user_count": "Insider List User Count",
          "market_sounding": "Market sounding"
        }
      },
      "pages": {
        "classification": {
          "general": {
            "short_name": "Short Name of Insider Information",
            "date_identified": "Date when identified",
            "date_of_delay": "Date of Delay",
            "delay_occured": "Delay occured",
            "ceased": "Ceased",
            "statement_is": "Statement is",
            "fulfilled": "fulfilled",
            "not_fulfilled": "not fulfilled",
            "reason_for_delay": "Reason for Delay"
          },
          "current_insider_info_tab": {
            "make_public": "Make public",
            "delete": "Delete",
            "do_you_want_to_cease": "Do you want to Cease the Insider Information?",
            "back": "Back",
            "cease": "Cease",
            "edit": "Edit",
            "disable": "Disable"
          },
          "discontinued_insider_info_tab": {
            "delayed": "Delayed",
            "published_ceased": "Published / Ceased",
            "published": "Published",
            "delay_not_occured": "Delay not occured"
          },
          "information_not_classified_tab": {
            "reclassification_of_information": "Reclassification of information",
            "date_of_qualification": "Date of Qualification"
          },
          "qualification_form": {
            "conceptual_elements_of_inside_information": "Conceptual Elements of Inside Information",
            "add_comment": "Add Comment"
          },
          "static_carousel_content": {
            "comment": "Comment"
          },
          "table_body_details": {
            "decision_information": "Decision informations",
            "download_file": "Download file",
            "export": "Export",
            "modify": "Modify"
          }
        },
        "common": {
          "general": {
            "name": "Name",
            "email": "E-mail",
            "position": "Position",
            "phone_number": "Phone number",
            "zip_code": "Zip code",
            "city": "City",
            "address": "Address",
            "select_contract": "Select contract",
            "subscriptions": "Subscriptions",
            "contracts": "Contracts",
            "invoices": "Invoices",
            "view_client": "View Client",
            "client": "Client",
            "create_billingo_partner": "Create Billingo Partner",
            "invoice_number": "Invoice Number",
            "download_invoice": "Download Invoice",
            "new_invoice": "New Invoice",
            "save_new_invoice": "Save New Invoice",
            "date": "Date",
            "download_contract": "Download Contract",
            "new_contract": "New Contract",
            "upload_contract": "Upload Contract",
            "package": "Package",
            "period": "Period",
            "monthly": "Monthly",
            "yearly": "Yearly",
            "start_date": "Start Date",
            "end_date": "End Date",
            "new_subscription": "New Subscription",
            "finalize_subscription": "Finalize Subscription (Won't renew)",
            "save": "Save",
            "delay_occurred": "Delay occurred",
            "do_you_want_to_delete_the_insider_information": "Do you want to Delete the Insider Information?",
            "create_confidential_list": "Create Confidential List",
            "discard": "Discard"
          },
          "company_info": {
            "name": "Name",
            "company_registration_number": "Company Registration Number",
            "headquarters": "Headquarters",
            "tax_number": "Tax Number",
            "lei_number": "LEI Number",
            "data_protection_officer": "Data Protection Officer"
          },
          "email_attachment": {
            "date_sent": "Date Sent",
            "download": "Download"
          },
          "email_template": {
            "subject": "Subject",
            "body": "Body",
            "save": "Save",
            "cancel": "Cancel",
            "edit": "Edit",
            "send": "Send"
          },
          "permission": {
            "modify": "Modify",
            "remove_access": "Remove access",
            "pdmr_pca_transactions": "PDMR / PCA transactions",
            "user_permission_successfully_removed": "User permission successfully removed",
            "no_access": "No Access",
            "read_only": "Read Only",
            "full_acccess": "Full Access"
          },
          "text_area_attachment": {
            "attachments": "Attachments"
          }
        },
        "data_room": {
          "general": {
            "grant_access": "Grant Access",
            "people_with_access": "People with Access",
            "take_away_access_from_everyone": "Take Away Access From Everyone",
            "take_away_access": "Take Away Access",
            "email_notifications": "E-mail Notification(s)",
            "acknowledgement": "Acknowledgement",
            "download": "Download",
            "download_list_of_people_with_access": "Download List of People with Access",
            "download_notification_emails_and_statements": "Download Notification Emails and Statements",
            "personal_data": "Personal Data",
            "notification_status": "Notification Status",
            "statement_of_consent_status": "Statement of Consent Status",
            "termination_of_access": "Termination of access",
            "name": "Name",
            "email_address": "Email Address",
            "telephone_number": "Telephone Number",
            "dt_placeholder": "dd-mm-yyyy; hh:mm"
          },
          "uploaded_files_tab": {
            "upload_new_documents": "Upload New Document(s)",
            "delete": "Delete",
            "download": "Download",
            "download_document_list": "Download Document List",
            "download_all_files_zip": "Download All Files (.zip)",
            "file_name": "File Name",
            "time_of_upload": "Time of Upload",
            "folder_name": "Folder Name",
            "open_folder": "Open Folder",
            "create_new_folder": "Create New Folder",
            "create": "Create",
            "discard": "Discard",
            "rename": "Rename"
          }
        },
        "insider_details": {
          "insider_list": {
            "date_placeholder": "DD/MM/YYYY",
            "dt_of_creation_of_permanent_insider_section": "(Date & Time of creation of the permanent insiders section)",
            "dt_of_last_update": "(Date & Time of the last update)",
            "date_of_transmission_to_competent_authority": "(Date of transmission to the competent authority)",
            "project_name": "Project Name",
            "inside_information": "Inside Information",
            "add_new_person": "Add New Person",
            "export_insider_list": "Export Insider List",
            "delete": "Delete",
            "first_name": "First Name",
            "surname": "Surname",
            "birth_surname": "Birth Surname",
            "professional_telephone_number": "Professional Telephone Number",
            "companys_name": "Company's Name",
            "companys_address": "Company's Address",
            "function_and_reason_for_being_insider": "Function and reason for being insider",
            "function_and_reason_for_being_insider_title": "Text describing the role, function and reason for inclusion in this list",
            "function_and_reason_for_being_insider_placeholder": "Function and Reason",
            "included": "Included",
            "obtained": "Obtained",
            "ceased": "Ceased",
            "birthdate": "Birthdate",
            "national_id_card_number": "National ID Card Number",
            "personal_telephone_number": "Personal Telephone Number",
            "personal_full_home_address": "Personal Full Home Address",
            "personal_full_home_address_title": "Country, postcode, city, street, house number",
            "discard": "Discard",
            "save_data": "Save Data",
            "this_insider_information_was_disclosed": "This inside information was disclosed in the course of market sounding.",
            "sending_the_notice_is_a_legal_obligation": "Sending the notice is a legal obligation. Are you sure you do not want to send the notification?",
            "name_of_the_insider": "Name of the Insider",
            "data_completeness": "Data Completeness",
            "last_login": "Last Login",
            "do_you_want_to_delete_this_insider": "Do you want to delete this insider?",
            "back": "Back"
          },
          "insider_notifications": {
            "download_all_acknowledgements": "Download All Acknowledgements",
            "download_all_notification_emails": "Download All Notification Emails",
            "name_of_insider": "Name of Insider",
            "email_address": "Email Address"
          }
        },
        "packages": {
          "general": {
            "request": "Request",
            "package_name": "Package Name",
            "function_module": "Function Module"
          },
          "package_feature_collapse": {
            "optional": "Optional"
          }
        },
        "pdma": {
          "general": {
            "modify": "Modify",
            "discard": "Discard",
            "save": "Save",
            "delete": "Delete",
            "date_placeholder": "yyyy.mm.dd"
          },
          "insider_card": {
            "project_name": "Project Name",
            "first_name": "First Name",
            "surname": "Surname",
            "birth_surname": "Birth Surname",
            "professional_telephone_number": "Professional Telephone Number",
            "companys_name": "Company's Name",
            "companys_address": "Company's Address",
            "function_and_reason_for_being_insider": "Function and reason for being insider",
            "function_and_reason_for_being_insider_title": "Text describing the role, function and reason for inclusion in this list",
            "function_and_reason_for_being_insider_placeholder": "Reason",
            "obtained": "Obtained",
            "ceased": "Ceased",
            "birthdate": "Birthdate",
            "national_id_card_number": "National ID Card Number",
            "personal_telephone_number": "Personal Telephone Number",
            "personal_full_home_address": "Personal Full Home Address",
            "notification_email": "Notification Email",
            "acknowledgement": "Acknowledgement"
          },
          "legal_form": {
            "company_name": "Company Name",
            "headquarters": "Headquarters",
            "company_registration_number": "Company Registration Number",
            "type_of_relationship": "Type of Relationship",
            "email_address": "Email Address",
            "phone_number": "Phone number",
            "delete_pca": "Delete PCA",
            "download_notification": "Download Notification"
          },
          "natural_form": {
            "name": "Name",
            "type_of_relationship": "Type of Relationship",
            "email_address": "Email Address",
            "phone_number": "Phone number",
            "id_number": "ID Number",
            "place_of_birth": "Place of Birth",
            "date_of_birth": "Date of Birth",
            "time_of_inclusion": "Time of Inclusion",
            "download_notification": "Download Notification"
          },
          "notification_tab": {
            "name_of_the_person": "Name of the Person",
            "email_address": "Email Address",
            "no_data_to_show": "No data to show",
            "download_all_notifications": "Download All Notification"
          },
          "pca_card": {
            "pca_name": "PCA Name",
            "pca_email_address": "PCA Email Address",
            "natual_person": "Natual Person",
            "legal_person": "Legal Person"
          },
          "pdmr_list_tab": {
            "add_new": "Add New",
            "add_new_pdmr": "Add New PDMR",
            "add_new_pca": "Add New PCA",
            "position_at_client": "Position at Client",
            "phone_number": "Phone Number",
            "place_of_birth": "Place of Birth",
            "date_of_birth": "Date of Birth",
            "id_number": "ID Number",
            "date_of_inclusion": "Date of Inclusion",
            "registered_seat": "Registered Seat",
            "registration_number": "Registration Number",
            "download_list": "Download List",
            "pdmr_pca_data": "PDMR/PCA Data",
            "date_completeness": "Data Completeness",
            "notification_status": "Notification Status",
            "successful_modification": "Successful Modification",
            "successful_deletion": "Successful Deletion",
            "modify": "Modify",
            "save": "Save",
            "discard": "Discard",
            "delete": "Delete",
            "back": "Back",
            "first_name": "First Name",
            "last_name": "Last Name",
            "email_address": "Email Address",
            "sync_data": "Sync Data",
            "attention_data_is_not_up_to_date": "Attention! Data is not up to date.",
            "do_you_want_to_delete_this_pdmr_pca": "Do you want to delete this PDMR/PCA?"
          }
        },
        "permanent_insider_list": {
          "general": {
            "name_of_insider": "Name of Insider",
            "email_address": "E-mail Address",
            "date_placeholder": "DD/MM/YYYY",
            "delete": "Delete",
            "discard": "Discard",
            "back": "Back",
            "do_you_want_to_delete_this_insider": "Do you want to delete this insider?"
          },
          "insider_collapse": {
            "notification_emails": "Notification Email(s)",
            "acknowledgements": "Acknowledgements"
          },
          "notification_and_acknowledgement": {
            "download_all_acknowledgements": "Download All Acknowledgements",
            "download_all_notification_emails": "Download All Notification Emails"
          },
          "permanent_insider_list": {
            "dt_of_creation_of_permanent_insider_section": "(Date & Time of creation of the permanent insiders section)",
            "dt_of_last_update": "(Date & Time of the last update)",
            "date_of_transmission_to_competent_authority": "(Date of transmission to the competent authority)",
            "export_the_permanent_insider_list": "Export the Permanent Insider List",
            "first_name": "First Name",
            "surname": "Surname",
            "birth_surname": "Birth Surname",
            "professional_telephone_number": "Professional Telephone Number",
            "company_name": "Company Name",
            "company_address": "Company Address",
            "function_and_reason_for_being_insider": "Function and reason for being insider",
            "function_and_reason_for_being_insider_title": "Text describing the role, function and reason for inclusion in this list",
            "function_and_reason_for_being_insider_placeholder": "Function and Reason",
            "included": "Included",
            "dt_of_persons_inclusion": "The date and time of the person's inclusion in the permanent insiders section (yyyy-mm-dd, hh:mm UTC).",
            "birthdate": "Birthdate",
            "national_id_card_number": "National ID Card Number",
            "personal_telephone_number": "Personal Telephone Number",
            "personal_full_home_address": "Personal Full Home Address",
            "personal_full_home_address_title": "Country, postcode, city, street, house number",
            "save_data": "Save Data",
            "name_of_the_insider": "Name of the Insider",
            "data_completeness": "Data Completeness",
            "last_login": "Last Login",
            "mar_18_notifications": "MAR 18(2) Notifications",
            "mar_18_acknowledgements": "MAR 18(2) Acknowledgements",
            "not_logged_in_yet": "Not logged in yet",
            "select_version": "Select Version"
          }
        },
        "serviceprovider": {
          "client_card": {
            "package_subscribed_and_extras": "Package subscribed & Extras",
            "modify_data_and_subscribed_package": "Modify data and subscribed package",
            "invoices_sent_to_customer": "Invoices Sent To Customer",
            "download_invoice": "Download Invoice",
            "download_document": "Download Document",
            "invoice_number": "Invoice Number",
            "contracts": "Contracts",
            "contract_name": "Contract Name",
            "contract_date": "Contract Date",
            "upload_a_new_contract": "Upload a new contract"
          }
        }
      },
      "tools": {
        "alert": {
          "this_list_is_empty": "This list is empty"
        },
        "attachment": {
          "dt_when_persons_access_ceased": "Date and time when the person's access to inside information ceased (yyyy-mm-dd, hh: dd UTC)"
        },
        "carousel": {
          "previous": "Previous",
          "next": "Next"
        },
        "chart": {
          "view_statistic": "View Statistic"
        },
        "custom_date": {
          "dt_placeholder": "yyyy.mm.dd hh.mm",
          "date_placeholder": "yyyy.mm.dd"
        },
        "custom_dropdown": {
          "choose": "Choose"
        },
        "editable_table": {
          "volume": "Volume",
          "price": "Price",
          "cur": "CUR",
          "add_row": "Add row"
        },
        "progess_bar": {
          "step": "Step"
        },
        "slider_table": {
          "previous": "Previous",
          "next": "Next"
        }
      }
    },
    "modals": {
      "general": {
        "first_name": "First Name",
        "surname": "Surname",
        "last_name": "Last Name",
        "email_address": "Email Address",
        "email": "E-mail",
        "position": "Position",
        "discard": "Discard",
        "confirm": "Confirm",
        "phone_number": "Phone Number",
        "save": "Save",
        "add": "Add",
        "next": "Next",
        "birth_last_name": "Birth Last Name",
        "inclusion_email_attachments": "Attachments (These files can be downloaded and attached to the notification email)"
      },
      "classification": {
        "information_form": {
          "please_provide_information": "Please provide information on the disclosure of inside information.",
          "link": "Link",
          "k_number": "K-Number",
          "date_and_time_of_publication": "Date and time of publication",
          "upload_file": "Upload File",
          "approve": "Approve"
        },
        "information_notification": {
          "pursuant_to_article_17_4": "Pursuant to Article 17(4) of MAR, where an issuer has delayed the disclosure of inside information, it shall inform the competent authority that disclosure of the information was delayed and shall provide a written explanation of how the conditions set out in Article 17(4) were met, immediately after the information is disclosed to the public."
        }
      },
      "data_rooms": {
        "general": {
          "yes": "Yes",
          "no": "No"
        },
        "grant_access_form": {
          "address": "Address",
          "grant_access": "Grant Access"
        },
        "grant_access_modal": {
          "the_person_to_be_added": "The person to be added to this data room is not yet on the insider list in respect of the following inside information",
          "name_of_inside_information": "Name of Inside Information",
          "notification_and_insider_listing": "Notification & Insider Listing"
        },
        "insider_individuals": {
          "the_following_individuals": "The following individuals are not on the respective insider lists",
          "name_of_inside_information": "Name of Inside Information",
          "name": "Name",
          "notification_and_insider_listing": "Notification & Insider Listing",
          "email_address": "Email Address",
          "discard": "Discard"
        },
        "market_sounding_check": {
          "the_market_sounding_check": "The market sounding will involve the disclosure of inside information.",
          "pursuant_of_article_11": "Pursuant to Article 11 (3) of the MAR, you must justify the reasons for the above conclusion.",
          "create_a_data_room": "Create a Data Room",
          "discard": "Discard",
          "yes": "Yes",
          "no": "No",
          "comment": "Comment"
        },
        "multiple_files_indider": {
          "please_select_the_inside_information": "Please select the inside information from the drop-down list and assign them to the files selected!",
          "select_insider_information_from_the_list": "Select Insider Information from the List!",
          "select_all": "Select All",
          "file_name": "File Name",
          "select_insider_information": "Select Insider Information"
        },
        "new_data_room": {
          "name_of_the_data_room": "Name of the data room",
          "the_information_is_transferred": "The information is transferred in the course of a market sounding.",
          "view_a_guide": "View A Guide",
          "create": "Create"
        },
        "transcription_description": {
          "description_of_the_transaction_subject": "Description of the Transaction Subject to Market Sounding"
        },
        "upload_file_confirm": {
          "file_name": "File Name",
          "this_file_contains_inside_information": "This file contains inside information.",
          "select_insider_information_from_the_list": "Select Insider Information from the List!",
          "insider_information_related_to_the_file": "Insider information related to the file.",
          "upload_file": "Upload File",
          "discard": "Discard",
          "yes": "Yes",
          "no": "No"
        },
        "upload_multiple_files": {
          "please_select_the_files": "Please select the files that contain inside information!",
          "upload_files": "Upload Files",
          "discard": "Discard"
        },
        "upload_multiple_files_confirm": {
          "please_select_the_file_list": "Please check the file list before uploading!",
          "file_name": "File Name",
          "name": "Name",
          "email_address": "Email Address",
          "upload_files": "Upload Files",
          "discard": "Discard"
        }
      },
      "pdmr_pca": {
        "general": {
          "place_of_birth": "Place of Birth",
          "date_of_birth": "Date of Birth",
          "id_number": "ID Number",
          "date_of_inclusion": "Date of Inclusion"
        },
        "new_pca_form": {
          "add_new_pca": "Add New PCA",
          "related_pdmr": "Related PDMR",
          "please_choose": "Please Choose...",
          "default_select_example": "Default select example",
          "nature_of_the_relationship": "Nature of the relationship",
          "please_choose_a_pdmr": "Please choose a PDMR and a type of relation!"
        },
        "new_pca_legal": {
          "add_new_pca": "Add New PCA",
          "company_name": "Company Name",
          "registered_seat": "Registered Seat",
          "companys_registration_number": "Company's Registration Number",
          "date_of_inclusion": "Date of Inclusion",
          "notify_pdrm": "Notify PDMR of his/her obligations under MAR.",
          "entry_of_the_pca_in_the_register": "Entry of the PCA in the register",
          "pursuant_to_article_19_5": "Pursuant to Article 19(5), issuers shall notify the PDMRs of their obligations under MAR in writing. Are you sure you do not want to send this notification?",
          "email_address": "Email Address",
          "phone_number": "Phone Number"
        },
        "new_pca_natural": {
          "add_new_pca": "Add New PCA",
          "notify_pdmr": "Notify PDMR of his/her obligations under MAR.",
          "entry_of_the_pca_in_the_register": "Entry of the PCA in the register",
          "notification_to_mar_is_a_legal": "Notification to MAR is a legal obligation to send. Are you sure you do not want to send this notification?"
        },
        "new_pdmr_form": {
          "add_new_pdrm": "Add New PDMR",
          "position_at_client": "Position at Client",
          "send_a_mar_notification": "Send a MAR notification at the same time as recording",
          "entry_of_the_pdrm_in_the_register": "Entry of the PDMR in the register",
          "notification_to_mar_is_a_legal": "Notification to MAR is a legal obligation to send. Are you sure you do not want to send this notification?"
        },
        "pdmr_pca_options": {
          "add_new_pdrm_pca": "Add New PDMR / PCA",
          "add_new_pdrm": "Add New PDMR",
          "add_new_pca": "Add New PCA"
        },
        "previous_notifications": {
          "pdrm_name": "PDMR Name",
          "date_sent": "Date Sent",
          "dt_placeholder": "yyyy.mm.dd hh:mm",
          "notification_email": "Notification Email"
        }
      },
      "add_new_person_insider": {
        "obtained": "Obtained",
        "dt_of_the_entry": "Date and time of the entry of the person into the permanent insider stage (yyyy-mm-dd, hh: dd UTC)",
        "function_and_reason_for_being_insider": "Function and reason for being insider",
        "reason_for_listing": "Reason for Listing",
        "add_the_person_as_central_contract_point": "Add the person as central contact point.",
        "yes": "Yes",
        "no": "No",
        "notify_the_insider": "Notify the insider about the inclusion in the insider list, the legal and regulatory duties entailed, and the sanctions applicable to insider dealing and unlawful disclosure of inside information as per Article 18(2) of MAR."
      },
      "change_data_form": {
        "change_data": "Change Data",
        "name": "Name"
      },
      "insider_date_form": {
        "please_indicate_the_date": "Please indicate the date the {title} listing was created!",
        "creating_dt": "Creating Date & Time",
        "permanent_insider_list_created": "Permanent insider list successfully created!",
        "permanent_insider_list_updated": "Permanent insider list successfully updated!"
      },
      "notification_alert": {
        "no": "No",
        "yes": "Yes"
      },
      "provide_link": {
        "link": "Link"
      }
    },
    "views": {
      "pages": {
        "admin": {
          "new_contract": {
            "contact_details": "Contact Details",
            "clients_name": "Client's name",
            "clients_address": "Client's address",
            "clients_company_registration_number": "Client's Company Registration Number",
            "investor_contacts_name": "Investor Contact's Name",
            "investor_contacts_position": "Investor Contact's Position",
            "investor_contacts_email_address": "Investor Contact's Email Address",
            "investor_contacts_phone_number": "Investor Contact's Phone Number",
            "subscribed_package": "Subscribed Package",
            "regulated_market": "Regulated Market",
            "sme": "SME",
            "advisory": "Advisory",
            "discard": "Discard",
            "save": "Save",
            "email_address": "Email Address",
            "tax_number": "Tax Number",
            "data_protection_officer": "Data Protection Officer"
          },
          "service_provider_control_panel": {
            "clients": "Clients",
            "add_a_new_client": "Add a New Client",
            "search_by_name": "Search by Name",
            "actions": "Actions",
            "package_num": "Package {num}",
            "send_email_to_customers": "Send Email to Customers",
            "automated_emails": "Automated E-mails",
            "pdmr_emails": "PDMR Emails",
            "pca_emails": "PDMR Emails",
            "emails_related_to_a_permanent_insider_list": "Emails Related to a Permanent Insider List",
            "emails_related_to_a_transaction_specific_insider_list": "Emails Related to a Transaction-Specific Insider List",
            "emails_related_to_data_rooms": "Emails Related to Data Rooms",
            "open_folder": "Open Folder",
            "folder_name": "Folder Name",
            "template_name": "Template Name",
            "email_templates": "Email Templates",
            "edit_template": "Edit Template",
            "category": "Category",
            "type": "Type",
            "template_name_en": "Template Name (EN)",
            "template_name_hu": "Template Name (HU)",
            "subject_en": "Subject (EN)",
            "subject_hu": "Subject (HU)",
            "body_en": "Body (EN)",
            "body_hu": "Body (HU)",
            "category_required": "Category is required",
            "type_required": "Type is required",
            "template_name_hu_required": "Template Name (HU) is required",
            "template_name_en_required": "Template Name (EN) is required",
            "subject_hu_required": "Subject (HU) is required",
            "subject_en_required": "Subject (EN) is required",
            "body_hu_required": "Body (HU) is required",
            "body_en_required": "Body (EN) is required",
            "template_saved_successfully": "Template saved successfully"
          }
        },
        "authentication": {
          "general": {
            "login_to_mabureg": "Login to MABUREG",
            "create_new_client": "Create new MABUREG client",
            "new_client_created_successfully": "New client created successfully",
            "this_is_your_account_uid": "This is your account UID:"
          },
          "login": {
            "enter_your_email_below": "Enter your email below",
            "email_address_phone_number": "Email Address / Phone Number",
            "email_address": "Email Address",
            "log_in": "Log in",
            "password": "Password",
            "forgot_password": "Forgot password?",
            "new_client": "Create new client",
            "confirm_password": "Confirm password",
            "phone_number": "Phone number",
            "register": "Register"
          },
          "login_code": {
            "a_verification_code_has_been_sent": "A verification code has been sent to your email / phone. This code is valid for 10 minutes.",
            "code": "Code",
            "verify_login": "Verify & Login"
          },
          "login_code_verification": {
            "please_wait_while_we_verify_your_token": "Please wait, while we verify your token!"
          },
          "market_data_room_access_person": {
            "download_the_document_list": "Download The Document List",
            "download_all_files": "Download All Files",
            "download_document": "Download Document",
            "file_name": "File Name",
            "uploaded": "Uploaded"
          },
          "new_password": {
            "reset_your_password": "Reset your password",
            "enter_your_registered_email_address": "Enter your registered E-mail & we will send to you a password reset link.",
            "new_password": "New password",
            "reset_password": "Reset password",
            "email_address": "Email Address",
            "enter_your_new_password": "Enter your new password",
            "confirm_password": "Confirm password",
            "repeat_password": "Repeat password",
            "dont_have_an_account": "Don't have an account?",
            "sign_up": "Sign up"
          },
          "password_code": {
            "reset_your_password": "Reset your password",
            "enter_your_registered_email_address": "Enter your registered E-mail & we will send to you a password reset link.",
            "code": "Code",
            "enter_code": "Enter Code",
            "continue": "Continue",
            "didnt_receive_code_yet": "Didn't receive code yet?",
            "resend_link": "Resend link"
          },
          "terms": {
            "please_read_the_terms_carefully": "Please read the terms carefully",
            "please_provide_us_with_the_following": "Please provide us with the following data",
            "phone_number": "Phone Number",
            "address": "Address",
            "go_to_the_data_room": "Go To The Data Room"
          },
          "user_logins": {
            "my_logins": "My Logins",
            "other_logins": "Other Logins",
            "search_by_name_email": "Search by Name, E-mail",
            "name_and_email_address": "Name & E-mail Address",
            "device": "Device"
          }
        },
        "classification": {
          "classification_insider_info": {
            "qualification_and_delay_of_inside_information": "Qualification and Delay of Inside Information",
            "add_new_information": "Add New Information",
            "current_inside_information": "Current Inside Information",
            "previous_inside_information": "Previous Inside Information",
            "sensitive_information": "Sensitive Information"
          },
          "delay_insider_information": {
            "delaying_inside_information": "Delaying Insider Information",
            "delaying_the_disclosure_of_inside_information": "Delaying the Disclosure of Inside Information",
            "current_date_and_time": "Current Date & Time",
            "it_can_be_modified": "(it can be modified)",
            "uploaded_files": "Uploaded Files",
            "upload_file": "Upload File",
            "attention_the_selected_files_are_uploading": " Attention! The selected files are uploading to the server, please do not close the window, or proceed anywhere!",
            "discard": "Discard",
            "save": "Save",
            "the_conditions_of_delaying": "The conditions of delaying the disclosure of inside information as prescribed by Article 17(4) of MAR are not met. Please disclose the inside information as soon as possible!",
            "back": "Back",
            "publish": "Publish",
            "the_recorded_delay_data_will_not_be_clumped": "The recorded delay data will not be clumped, but insider information will be saved!",
            "proceed": "Proceed",
            "attention_you_have_not_qualified": "Attention! You have not qualified all the options! Please fill out every necessary fields!",
            "ok": "Ok",
            "files_uploaded_successfully": "Files uploaded successfully!"
          },
          "information_re_qualification": {
            "requalification_of_information": "Requalification of Information",
            "project_name": "Project Name",
            "please_indicate_the_name_of_the_project": "Please indicate the name of the project (e.g. merger, project MABU, etc.). It is important that this field cannot contain any inside information.",
            "description_of_the_information": "Description of the Information to be Qualified",
            "current_date_and_time": "Current Date & Time",
            "it_can_be_modified": "(it can be modified)",
            "uploaded_files": "Uploaded Files",
            "upload_file": "Upload File",
            "attention_the_selected_files_are_uploading": " Attention! The selected files are uploading to the server, please do not close the window, or proceed anywhere!",
            "discard": "Discard",
            "save": "Save",
            "the_conditions_of_delaying": "The conditions of delaying the disclosure of inside information as prescribed by Article 17(4) of MAR are not met. Please disclose the inside information as soon as possible!",
            "back": "Back",
            "publish": "Publish",
            "the_recorded_delay_data_will_not_be_clumped": "The recorded delay data will not be clumped, but insider information will be saved!",
            "proceed": "Proceed",
            "attention_you_have_not_qualified": "Attention! You have not qualified all the options! Please fill out every necessary fields!",
            "ok": "Ok",
            "files_uploaded_successfully": "Files uploaded successfully!"
          },
          "new_information_qualification": {
            "reclassify_the_information": "Reclassify The Information",
            "requalification_of_information": "Qualification of New Information",
            "project_name": "Project Name",
            "please_indicate_the_name_of_the_project": "Please indicate the name of the project (e.g. merger, project MABU, etc.). It is important that this field cannot contain any inside information.",
            "description_of_the_information": "Description of the Information to be Qualified",
            "current_date_and_time": "Current Date & Time",
            "it_can_be_modified": "(it can be modified)",
            "uploaded_files": "Uploaded Files",
            "upload_file": "Upload File",
            "attention_the_insider_information_is_being_created": "Attention! This insider information is being created, please do not close the window, or proceed anywhere!",
            "attention_the_selected_files_are_uploading": "Attention! The selected files are uploading to the server, please do not close the window, or proceed anywhere!",
            "discard": "Discard",
            "classify_information": "Classify The Information",
            "what_do_you_want_to_do_with_the_information": "What do you want to do with the information?",
            "delay": "Delay",
            "immediate_publication": "Immediate Publication",
            "this_information_does_not_qualify": "This information does not qualify as inside information. Do you want to create a confidential list?",
            "no": "No",
            "yes": "Yes",
            "attention_you_have_not_qualified": "Attention! You have not qualified all the options! Please fill out every necessary fields!",
            "ok": "Ok",
            "files_uploaded_successfully": "Files uploaded successfully!",
            "successfully_created": "Successfully created!",
            "edit_the_information": "Edit the information",
            "confirm_edit_the_information": "Confirm Edit the information",
            "do_you_want_to_Reclassify_the_information": "Do you want to Reclassify the information",
            "save_changes": "Save Changes"
          }
        },
        "confidential": {
          "confidential_details": {
            "confidential_list": "Confidential list",
            "date_placeholder": "DD/MM/YYYY",
            "date_and_time_of_creation": "(Date & Time of creation of the permanent insiders section)",
            "dt_of_last_update": "(Date & Time of the last update)",
            "a_brief_description_of_the_confidential_information": "A Brief Description of the Confidential Information",
            "confidential_list_long_description": "Confidential List Long Description",
            "add_new_person": "Add New Person",
            "delete": "Delete",
            "this_confidential_list_is_empty": "This Confidential List is empty. Please add at least one person to it!",
            "export_confidential_list": "Export Confidential List",
            "convert_to_a_deal_specific_insider_list": "Convert to a Deal-Specific Insider List",
            "name": "Name",
            "position": "Position",
            "email": "E-mail",
            "edit": "Edit",
            "disable_editing": "Disable Editing"
          },
          "confidential_list": {
            "closed_confidential_list": "Closed Confidential List",
            "confidential_list": "Confidential list",
            "create_new_confidential_list": "Create New Confidential List",
            "download": "Download",
            "details": "Details",
            "project_name": "Project Name",
            "date_and_time_of_creation": "Date and Time of Creation",
            "number_of_people": "Number of People",
            "short_name_of_information": "Short Name of Information",
            "dt_placeholder": "dd-mm-yyyy; hh:mm"
          },
          "new_confidential_form": {
            "confidential_list": "Confidential list",
            "project_name": "Project Name",
            "description_of_the_sensitive_information": "Description of the Sensitive Information",
            "current_date_and_time": "Current Date & Time",
            "it_can_be_modified": "(it can be modified)",
            "save": "Save",
            "cancel": "Cancel"
          }
        },
        "current_insider": {
          "list_of_transaction_specifi_insiders": {
            "closed_insider_lists": "Closed insider lists",
            "current_insider_lists": "Current insider lists",
            "search": "Search",
            "download": "Download",
            "details": "Details",
            "inside_information": "Inside Information",
            "project_name": "Project Name",
            "time_of_creation": "Time of Creation",
            "number_of_persons_included": "Number of Persons Included",
            "date_of_closure": "Date of Closure",
            "short_name_of_insider_information": "Short Name of Insider Information",
            "dt_placeholder": "dd-mm-yyyy; hh:mm",
            "number_of_people": "Number of People"
          },
          "specific_insider_details": {
            "deal_specific_insider_list": "Deal-Specific Insider List",
            "insider_list": "Insider list",
            "notifications_and_acknowledgements": "Notifications and Acknowledgments"
          }
        },
        "data_room": {
          "data_room_details": {
            "market_sounding_data_room": "Market Sounding Data Room - { dataroom }",
            "non_market_sounding_data_room": "Non-Market Sounding Data Room - { dataroom }",
            "uploaded_files": "Uploaded Files",
            "people_with_access": "People with Access"
          },
          "data_rooms": {
            "create_a_new_data_room": "Create a New Data Room",
            "market_sounding_data_room": "Market Sounding Data Room",
            "non_market_sounding_data_room": "Non-Market Sounding Data Room"
          }
        },
        "pdmr_pca": {
          "pca": {
            "name_and_email_address_of_the_person_discharging": "Name and e-mail address of the person discharging managerial responsibilities (PDMR) with whom you are closely associated.",
            "pdmr_name": "PDRM Name",
            "pdmr_email_address": "PDMR Email Address",
            "the_data_of_persons_closely_associated": "The data of persons closely associated (PCA) with the PDMR.",
            "pca_name": "PCA Name",
            "pca_email_address": "PCA Email Address",
            "new_transaction": "New Transactions",
            "download_application": "Download Application",
            "market_sounding_data_room": "Market Sounding Data Room",
            "data_room_name": "Data Room Name",
            "non_market_sounding_data_room": "Non-Market Sounding Data Room",
            "transaction_details": "Transaction Details",
            "notification_date": "Notification Date",
            "basic_transaction_details": "Basic Transaction Details"
          },
          "pdmr": {
            "pdmr_information": "PDMR Information",
            "name": "Name",
            "position_at_client": "Position at Client",
            "email_address": "Email Address",
            "phone_number": "Phone Number",
            "id_number": "ID Number",
            "place_of_birth": "Place of Birth",
            "date_of_birth": "Date of Birth",
            "time_of_inclusion": "Time of Inclusion",
            "modify": "Modify",
            "discard": "Discard",
            "save": "Save",
            "add_a_new_pca": "Add a New PCA",
            "download_all_notifications": "Download All Notifications",
            "pca_name": "PCA Name",
            "pca_email_address": "PCA Email Address",
            "reported_transactions": "Reported Transactions",
            "notify_new_transaction": "Notify New Transaction",
            "download_application": "Download Application",
            "periods_covered_by_the_trade_ban": "Periods Covered by The Trade Ban",
            "requests_on_trading_ban_exemption": "Requests on Trading Ban Exemption",
            "new_application": "New Application",
            "download": "Download",
            "insider_list": "Insider list",
            "market_sounding_data_room": "Market Sounding Data Room",
            "non_market_sounding_data_room": "Non-Market Sounding Data Room",
            "data_room_name": "Data Room Name",
            "transaction_details": "Transaction Details",
            "notification_date": "Notification Date",
            "request_id": "Request ID",
            "date": "Date",
            "approved_rejected": "Approved/Rejected"
          },
          "pdmr_list": {
            "pdmr_pca_list": "PDMR / PCA List",
            "notifications": "Notifications"
          },
          "published_reports": {
            "unpublished_pdmr_pca_reports": "Unpublished PDMR / PCA reports",
            "search": "Search",
            "provide_publishing_information": "Provide Publishing Information",
            "published_pdmr_pca_reports": "Published PDMR / PCA reports",
            "posted_link": "Posted Link",
            "name_of_applicant": "Name of Applicant",
            "time_of_notification": "Time of Notification",
            "pdmr_pca": "PDRM / PCA",
            "view_form": "View Form",
            "dt_placeholder": "yyyy.mm.dd hh:mm"
          },
          "report_transaction_form": {
            "name": "Name",
            "email": "E-mail",
            "position": "Position",
            "phone_number": "Phone Number",
            "this_form_is_required_for_disclosure": "This form is required for disclosure of transactions under Article 19 of Regulation (EU) No 596/2014 of the European Parliament and of the Council of 16 April 2014 on market abuse (Market Abuse Regulation)",
            "details_of_the_person_discharging": "1. Details of the person discharging managerial responsibilities/person closely associated",
            "natural_person": "Natural Person",
            "first_name": "First Name",
            "last_name": "Last Name",
            "legal_person": "Legal Person",
            "full_name": "Full Name",
            "modify": "Modify",
            "reason_for_the_notification": "2. Reason for the notification",
            "position_status": "Position/Status",
            "initial_notification": "Initial Notification",
            "amendment_to_prior_notification": "Amendment to Prior Notifications",
            "description": "Description",
            "details_of_the_issuer": "3. Details of the issuer, emission allowance market participant, auction platform, auctioneer or auction monitor",
            "full_name_of_the_entity": "FUll Name of the Entity",
            "lei_legal_entity_identifier": "LEI / Legal Entity Identifier",
            "details_of_the_transaction": "4. Details of the transaction(s): section to be repeated for (i) each type of instrument; (ii) each type of transaction; (iii) each date; and (iv) each place where transactions have been conducted",
            "details_of_the_transaction_section": "4.{idx}. Details of the transaction(s): section to be repeated for (i) each type of instrument; (ii) each type of transaction; (iii) each date; and (iv) each place where transactions have been conducted",
            "description_of_the_finincial_instrument": "Description of the financial instrument, type of instrument",
            "identification_code": "Identification Code",
            "nature_of_the_transaction": "Nature of the transaction",
            "description_of_the_transaction": "Description of the transaction type using, where applicable, the type of transaction identified in Article 10 of the Commission Delegated Regulation (EU) 2016/5221 adopted under Article 19(14) of Regulation (EU) No 596/2014 or a specific example set out in Article 19(7) of Regulation (EU) No 596/2014. Pursuant to Article 19(6)(e) of Regulation (EU) No 596/2014, it shall be indicated whether the transaction is linked to the exercise of a share option programme.",
            "prices_and_volumes": "Price(s) and Volume(s)",
            "aggregated_volume_and_price": "Aggregated Volume & Price",
            "time_of_the_transaction": "Time of the Transaction",
            "transaction_date_and_time": "Transaction Date & Time",
            "place_of_the_transaction": "Place of The Transaction",
            "name_and_code_to_identify_the_mifid": "Name and code to identify the MiFID trading venue, the systematic internaliser or the organised trading platform outside of the Union where the transaction was executed as defined under Commission Delegated Regulation supplementing Regulation (EU) No 600/2014 of the European Parliament and of the Council with regard to regulatory technical standards for the reporting of transactions to competent authorities adopted under Article 26 of Regulation (EU) No 600/2014, or if the transaction was not executed on any of the above mentioned venues, please mention 'outside a trading venue'.",
            "additional_information": "Additional Information",
            "submit_form": "Submit Form",
            "where_more_then_one_transaction": "Where more than one transaction of the same nature (purchases, sales, lendings, borrows, …) on the same financial instrument or emission allowance are executed on the same day and on the same place of transaction, prices and volumes of these transactions shall be reported in this field, in a two columns form as presented above, inserting as many lines as needed.",
            "new_detail": "Add new detail"
          }
        },
        "data_room_control_panel": {
          "market_sounding_data_room": "Market Sounding Data Room",
          "data_room_name": "Data Room Name",
          "non_market_sounding_data_room": "Non-Market Sounding Data Room",
          "this_room_contains_insider_information": "This room contains insider information"
        },
        "insider_list_control_panel": {
          "insider_list": "Insider list",
          "market_sounding_data_room": "Market Sounding Data Room",
          "non_market_sounding_data_room": "Non-Market Sounding Data Room",
          "data_room_name": "Data Room Name"
        },
        "packages": {
          "subscribed_package_and_additional_services": "Subscribed Package and Additional Services",
          "other_package_offers": "Other Package Offers"
        },
        "period_ban": {
          "fixing_the_period_covered_by_the_trading_ban": "Fixing the period covered by the trading ban",
          "start_date_of_publication_of_half_yearly": "Start date of publication of half-yearly financial reports:",
          "end_date_of_publication_of_half_yearly": "End date of publication of half-yearly financial reports:",
          "start_date_of_publication_of_annual": "Start date of publication of annual financial reports:",
          "end_date_of_publication_of_annual": "End date of publication of annual financial reports:",
          "save": "Save",
          "send_emails_automatically": "Send emails automatically",
          "days_before_the_start_of_closed_period": "day(s) before the start of closed period.",
          "list_of_persons_to_be_notified": "List of persons to be notified",
          "date_sent": "Date Sent",
          "previous_notifications": "Previous Notifications",
          "requests_on_trade_ban_excemptions": "Requests on Trading Ban Exemption",
          "reject": "Reject",
          "accept": "Accept",
          "are_you_sure_you_want_to_accept_the_excemption": "Are you sure you want to accept the exemption?",
          "are_you_sure_you_want_to_reject_the_excemption": "Are you sure you want to reject the exemption?",
          "back": "Back",
          "pdmr_name": "PDRM Name",
          "email_address": "Email Address",
          "time_of_notification": "Time of Notification",
          "automatic_email_notifications": "Automatic Email Notifications",
          "name": "Name",
          "request_link": "Request Link",
          "dt_placeholder": "yyyy.mm.dd hh:mm",
          "successfully_updated_period": "Successfully updated period!",
          "ban_period_successfully_updated": "Ban period successfully updated!",
          "successfully_updated_notification_state": "Successfully updated notification state!",
          "successfully_accepted_excemption_request": "Successfully accepted exemption request!",
          "successfully_rejected_excemption_request": "Successfully rejected exemption request!",
          "there_is_no_notification": "There is no notification to show"
        },
        "permanent_insider_list": {
          "permanent_insider_list": "Permanent Insider List",
          "insider": "insider",
          "notifications_and_acknowledgements": "Notifications and Acknowledgments"
        },
        "roles_select_page": {
          "select_role": "Select Role",
          "please_select_role": "Please select role",
          "investor_contact": "Investor Contact",
          "admin": "Admin",
          "pdmr": "PDRM",
          "pca": "PCA",
          "person_on_deal_specific_insider_list": "Person on Deal-Specific Insider List",
          "person_invited_only_to_data_rooms": "Person invited only to data room(s)"
        },
        "settings": {
          "company_name": "Company Name",
          "company_headquarters": "Company Headquarters",
          "automatically_log_off_users_after": "Automatically log off users after",
          "input": "Input",
          "minutes_of_inactivity": "minutes of inactivity.",
          "change_data": "Change Data",
          "change_password": "Change Password",
          "client_decrypt_request": "Client Decrypt Request",
          "login_history": "Login history",
          "permanently_delete_profile": "Permanently Delete Profile",
          "delete_my_account": "Delete My Account",
          "site_documents": "Site documents",
          "document_title": "Document Title",
          "document_type": "Document Type",
          "document_content": "Document Content",
          "date": "Date",
          "edit_document": "Edit document",
          "from_date": "From Date",
          "document_title_required": "Document title is required",
          "from_date_required": "From date is required",
          "document_content_required": "Document content is required",
          "create_document": "Create document",
          "delete_document": "Delete document"
        },
        "subscriptions": {
          "subscribed_package": "Subscribed Package",
          "subscribed_package_name": "Subscribed Package Name",
          "download_content": "Download Content",
          "change_subscription": "Change Subscription",
          "my_accounts": "My Accounts",
          "seach_accounts_by_number": "Search accounts by Number",
          "account_number": "Account Number",
          "date_of_issue": "Date of Issue",
          "date_of_completion": "Date of Completion",
          "download_request": "Download Request",
          "download_invoice": "Download Invoice"
        },
        "upload_application": {
          "upload_application": "Upload Application",
          "file_name": "File Name",
          "proceed": "Proceed"
        },
        "declaration_of_recognition_share": {
          "document_and_information_disclosure": "I acknowledge that the documents and information shared in the data room will be disclosed in the context of market sounding under Article 11 of Regulation (EU) No 596/2014 on market abuse.",
          "communication": "I confirm that the Client is communicating with the person entrusted by the potential investor to receive the market sounding.",
          "judge_for_self": "I acknowledge that if I agree to receive the market sounding, I will receive information that the Client considers to be inside information. I acknowledge that, in each case, I must judge for myself whether I am in possession of inside information and at what point in time the information in my possession ceases to be inside information.",
          "cease_inside_info": "I understand that if the inside information provided ceases to be inside information, I will be informed of this fact by e-mail.",
          "financial_instruments": "I acknowledge that I am prohibited from using the inside information provided, or attempting to use that information, by acquiring or disposing of, for my own account or for the account of a third party, directly or indirectly, financial instruments relating to that information.",
          "amend_cancel": "I acknowledge that I am prohibited from using the inside information provided, or attempting to use that information, by cancelling or amending an order which has already been placed concerning a financial instrument to which the information relates.",
          "confidentiality_obl": "I understand that by agreeing to receive inside information I am obliged to keep the inside information confidential.",
          "info_receive": "I expressly consent to receive inside information in the course of market sounding.",
          "data_room_separation": "I acknowledge that the data room contains all information provided for the purpose of market sounding, separately identifying the information that is considered by the Client to be inside information.",
          "privacy_policy": "I have read the privacy notice and I accept the privacy policy { link }"
        },
        "declaration_of_recognition_no_share": {
          "document_and_information_disclosure": "I acknowledge that the documents and information shared in the data room will be disclosed in the context of market sounding under Article 11 of Regulation (EU) No 596/2014 on market abuse.",
          "communication": "I confirm that the Client is communicating with the person entrusted by the potential investor to receive the market sounding.",
          "not_inside_information": "I acknowledge that if I agree to receive the market sounding, I will receive information that the Client considers not to be inside information. I acknowledge that, in each case, I must judge for myself whether I am in possession of inside information and at what point in time the information in my possession ceases to be inside information.",
          "info_receive": "I expressly consent to receive inside information in the course of market sounding.",
          "data_room_all": "I acknowledge that the data room contains all information provided for the purpose of market sounding.",
          "privacy_policy": "I have read the privacy notice and I accept the privacy policy { link }"
        },
        "insider_first_login": {
          "legal_and_regulatory_duties": "I expressly acknowledge that I have been informed by the Client of the legal and regulatory duties entailed with inclusion on the insider list and of the sanctions applicable to insider dealing and unlawful disclosure of inside information.",
          "retain_insider_list": "I acknowledge that the Client is obliged to retain the insider list for at least five years after it is drawn up or updated and to submit it to the Hungarian National Bank as soon as possible upon request.",
          "privacy_policy": "I have read the privacy notice and I accept the privacy policy { link }"
        },
        "normal_first_login": {
          "privacy_policy": "I have read the privacy notice and I accept the privacy policy { link }"
        }
      }
    },
    "aside": {
      "classification_and_delay_of_inside_info": "Classification and Delay of Inside Info.",
      "insider_list": {
        "title": "Insider List",
        "submenu": {
          "permanent_insider_list": "Permanent Insider List",
          "transaction-specific_insiders_list": "Transaction-Specific Insiders List",
          "confidential_list": "Confidential List"
        }
      },
      "management_transactions": {
        "title": "Management Transactions",
        "submenu": {
          "record-keeping": "Record-keeping",
          "transaction_reporting_for_executives_and_close_associates": "Transaction reporting for executives and close associates",
          "trading_ban_period": "Trading Ban Period"
        }
      },
      "transfer_of_inside_info": "Transfer of Inside Info.",
      "transaction_form": "Transaction form",
      "dashboard": "Dashboard"
    },
    "dropdown": {
      "settings": "Settings",
      "pdmr": "PDMR",
      "pca": "PCA",
      "dashboard": "Dashboard",
      "my_subscription": "My Subscription",
      "era_form_filler": "ERA Form Filler",
      "billing_data": "Billing Data",
      "billing": "Billing",
      "change_password": "Change password",
      "login_history": "Login history"
    },
    "util": {
      "decrypt_request": "Decrypt Request",
      "request_sent": "Request sent",
      "up_to_date": "Up to date",
      "loading": "Loading",
      "save_selected_representatives": "Save selected representatives",
      "items_per_page": "Items per page",
      "page": "Page",
      "board_member": "Board member",
      "representative": "representative"
    }
  },
  "header": {
    "logout": "Logout",
    "log_in": "Log in",
    "create_master_key": "Create Master Key",
    "edit_master_key": "Edit Master Key",
    "billing_data": "Billing Data",
    "settings": "Settings",
    "enter_your_master_password": "Enter your master password",
    "master_password": "Master Password",
    "wrong_master_password": "Wrong Master Password",
    "save": "Save",
    "the_password_must_contain_at_least_one_uppercase_letter_and_one_lowercase_letter": "The password must contain at least one uppercase letter and one lowercase letter.",
    "the_password_must_contain_at_least_one_symbol": "The password must contain at least one symbol.",
    "the_password_must_contain_at_least_one_number": "The password must contain at least one number.",
    "the_confirm_password_must_match_the_password": "The confirm password must match the password.",
    "discard": "Discard",
    "old_password": "Old Password",
    "password": "Password",
    "confirm_password": "Confirm Password",
    "enter_your_email": "Enter your email",
    "email_address": "Email Address"
  },
  "inclusion_emails": {
    "select_language": "Select Email language",
    "english": "English",
    "hungarian": "Hungarian"
  },
  "info-requalification": {
    "requalification_of_information": "Requalification of Information",
    "project_name": "Project Name",
    "please_indicate_the_name_of_the_project": "Please indicate the name of the project (e.g. merger, project MABU, etc.). It is important that this field cannot contain any inside information.",
    "description_of_the_information": "Description of the Information to be Qualified",
    "current_date_and_time": "Current Date & Time",
    "it_can_be_modified": "(it can be modified)",
    "uploaded_files": "Uploaded Files",
    "upload_file": "Upload File",
    "attention_the_selected_files_are_uploading": " Attention! The selected files are uploading to the server, please do not close the window, or proceed anywhere!",
    "discard": "Discard",
    "save": "Save",
    "the_conditions_of_delaying": "The conditions of delaying the disclosure of inside information as prescribed by Article 17(4) of MAR are not met. Please disclose the inside information as soon as possible!",
    "back": "Back",
    "publish": "Publish",
    "the_recorded_delay_data_will_not_be_clumped": "The recorded delay data will not be clumped, but insider information will be saved!",
    "proceed": "Proceed",
    "attention_you_have_not_qualified": "Attention! You have not qualified all the options! Please fill out every necessary fields!",
    "ok": "Ok",
    "files_uploaded_successfully": "Files uploaded successfully!",
    "date_of_qualification": "Date of Qualification"
  },
  "insider-list": {
    "name_of_insider": "Name of Insider",
    "email_address": "E-mail Address",
    "date_placeholder": "DD/MM/YYYY",
    "delete": "Delete",
    "discard": "Discard",
    "back": "Back",
    "do_you_want_to_delete_this_insider": "Do you want to delete this insider?",
    "notification_emails": "Notification Email(s)",
    "acknowledgements": "Acknowledgements",
    "download_all_acknowledgements": "Download All Acknowledgements",
    "download_all_notification_emails": "Download All Notification Emails",
    "dt_of_creation_of_permanent_insider_section": "(Date & Time of creation of the permanent insiders section)",
    "dt_of_last_update": "(Date & Time of the last update)",
    "date_of_transmission_to_competent_authority": "(Date of transmission to the competent authority)",
    "export_the_permanent_insider_list": "Export the Permanent Insider List",
    "first_name": "First Name",
    "surname": "Surname",
    "birth_surname": "Birth Surname",
    "professional_telephone_number": "Professional Telephone Number",
    "company_name": "Company Name",
    "company_address": "Company Address",
    "function_and_reason_for_being_insider": "Function and reason for being insider",
    "function_and_reason_for_being_insider_title": "Text describing the role, function and reason for inclusion in this list",
    "function_and_reason_for_being_insider_placeholder": "Function and Reason",
    "included": "Included",
    "dt_of_persons_inclusion": "The date and time of the person's inclusion in the permanent insiders section (yyyy-mm-dd, hh:mm UTC).",
    "birthdate": "Birthdate",
    "national_id_card_number": "National ID Card Number",
    "personal_telephone_number": "Personal Telephone Number",
    "personal_full_home_address": "Personal Full Home Address",
    "personal_full_home_address_title": "Country, postcode, city, street, house number",
    "save_data": "Save Data",
    "name_of_the_insider": "Name of the Insider",
    "data_completeness": "Data Completeness",
    "last_login": "Last Login",
    "mar_18_notifications": "MAR 18(2) Notifications",
    "mar_18_acknowledgements": "MAR 18(2) Acknowledgements",
    "not_logged_in_yet": "Not logged in yet",
    "select_version": "Select Version",
    "permanent_insider_list": "Permanent Insider List",
    "insider": "insider",
    "notifications_and_acknowledgements": "Notifications and Acknowledgments",
    "add_new_person": "Add New Person",
    "edit": "Edit",
    "disable_editing": "Disable Editing",
    "email": "Email",
    "last_name": "Last Name",
    "birth_last_name": "Birth Last Name",
    "obtained": "Obtained",
    "obtained_info": "Obtained info",
    "dt_of_the_entry": "Date and time of the entry of the person into the permanent insider stage (yyyy-mm-dd, hh: dd UTC)",
    "reason_for_listing": "Reason for Listing",
    "add_the_person_as_central_contract_point": "Will be added as a central contract?",
    "add_the_person_as_central_contract_point_info": "Info",
    "yes": "Yes",
    "no": "No",
    "notify_the_insider": "Notify the insider about the inclusion in the insider list, the legal and regulatory duties entailed, and the sanctions applicable to insider dealing and unlawful disclosure of inside information as per Article 18(2) of MAR.",
    "confirm": "Confirm",
    "download": "Download",
    "date_sent": "Date Sent",
    "subject": "Subject",
    "body": "Body",
    "inclusion_email_attachments": "Inclusion Email Attachments",
    "uploaded_files": "Uploaded Files",
    "upload_file": "Upload File",
    "send": "Send"
  },
  "login-code-verification": {
    "test": ""
  },
  "login-email-notification": {
    "login_to_mabureg": "Login to MABUREG",
    "your_one_time_use_link_has_been_sent": "Your one time use link has been sent to your email address {email}! Please check your emails, and use the link to log-in."
  },
  "login-password": {
    "enter_your_password_below": "Enter your password below",
    "password": "Password",
    "forgot_password": "Forgot password?",
    "log_in": "Log in",
    "old_password": "Old password",
    "login_to_mabureg": "Login to MABUREG"
  },
  "login": {
    "login_to_mabureg": "Login to MABUREG",
    "email_address": "Email address",
    "phone_number": "Phone number",
    "password": "Password",
    "enter_your_email_below": "Enter your email below",
    "email_address_phone_number": "Email Address / Phone Number",
    "log_in": "Log in",
    "forgot_password": "Forgot password?",
    "new_client": "Create new client",
    "register": "Register"
  },
  "main": {
    "clients": "Clients",
    "search_by_name": "Search by name",
    "name": "Name",
    "invoice": "Invoice",
    "contracts": "Contracts",
    "subscriptions": "Subscriptions",
    "view_client": "View client",
    "automated_emails": "Automated emails",
    "folder_name": "Folder name",
    "open_folder": "Open folder",
    "email_templates": "Email templates",
    "template_name": "Template name",
    "edit_template": "Edit template",
    "category": "Category",
    "type": "Type",
    "template_name_en": "Template Name (EN)",
    "template_name_hu": "Template Name (HU)",
    "subject_en": "Subject (EN)",
    "subject_hu": "Subject (HU)",
    "body_en": "Body (EN)",
    "body_hu": "Body (HU)",
    "category_required": "Category is required",
    "type_required": "Type is required",
    "template_name_hu_required": "Template Name (HU) is required",
    "template_name_en_required": "Template Name (EN) is required",
    "subject_hu_required": "Subject (HU) is required",
    "subject_en_required": "Subject (EN) is required",
    "body_hu_required": "Body (HU) is required",
    "body_en_required": "Body (EN) is required",
    "template_saved_successfully": "Template saved successfully",
    "save": "Save",
    "cancel": "Cancel"
  },
  "market-data-room-details": {
    "market_sounding_data_room": "Market Sounding Data Room - { dataroom }",
    "non_market_sounding_data_room": "Non-Market Sounding Data Room - { dataroom }",
    "uploaded_files": "Uploaded Files",
    "people_with_access": "People with Access",
    "upload_new_documents": "Upload New Document(s)",
    "delete": "Delete",
    "download": "Download",
    "download_document_list": "Download Document List",
    "download_all_files_zip": "Download All Files (.zip)",
    "file_name": "File Name",
    "time_of_upload": "Time of Upload",
    "folder_name": "Folder Name",
    "open_folder": "Open Folder",
    "create_new_folder": "Create New Folder",
    "create": "Create",
    "discard": "Discard",
    "rename": "Rename",
    "this_file_contains_inside_information": "This file contains inside information.",
    "select_insider_information_from_the_list": "Select Insider Information from the List!",
    "insider_information_related_to_the_file": "Insider information related to the file.",
    "upload_file": "Upload File",
    "yes": "Yes",
    "no": "No",
    "please_select_the_files": "Please select the files that contain inside information!",
    "upload_files": "Upload Files",
    "please_select_the_file_list": "Please check the file list before uploading!",
    "name": "Name",
    "email_address": "Email Address",
    "grant_access": "Grant Access",
    "take_away_access_from_everyone": "Take Away Access From Everyone",
    "take_away_access": "Take Away Access",
    "email_notifications": "E-mail Notification(s)",
    "acknowledgement": "Acknowledgement",
    "download_list_of_people_with_access": "Download List of People with Access",
    "download_notification_emails_and_statements": "Download Notification Emails and Statements",
    "personal_data": "Personal Data",
    "notification_status": "Notification Status",
    "statement_of_consent_status": "Statement of Consent Status",
    "termination_of_access": "Termination of access",
    "telephone_number": "Telephone Number",
    "dt_placeholder": "dd-mm-yyyy; hh:mm",
    "the_person_to_be_added": "The person to be added to this data room is not yet on the insider list in respect of the following inside information",
    "name_of_inside_information": "Name of Inside Information",
    "notification_and_insider_listing": "Notification & Insider Listing",
    "address": "Address",
    "subject": "Subject",
    "body": "Body",
    "email": "Email",
    "inclusion_email_attachments": "Attachments (These files can be downloaded and attached to the notification email)",
    "send": "Send",
    "first_name": "First Name",
    "last_name": "Last Name",
    "phone_number": "Phone Number"
  },
  "my-logins": {
    "test": ""
  },
  "new-client": {
    "login_to_mabureg": "Login to MABUREG",
    "create_new_client": "Create new MABUREG client",
    "new_client_created_successfully": "New client created successfully",
    "this_is_your_account_uid": "This is your account UID:",
    "email_address": "Email address",
    "phone_number": "Phone number",
    "password": "Password",
    "confirm_password": "Confirm password",
    "register": "Register",
    "log_in": "Log in"
  },
  "new-confidential": {
    "confidential_list": "Confidential list",
    "project_name": "Project Name",
    "description_of_the_sensitive_information": "Description of the Sensitive Information",
    "current_date_and_time": "Current Date & Time",
    "it_can_be_modified": "(it can be modified)",
    "save": "Save",
    "cancel": "Cancel"
  },
  "new-contact": {
    "contact_details": "Contact Details",
    "clients_name": "Client's name",
    "clients_address": "Client's address",
    "clients_company_registration_number": "Client's Company Registration Number",
    "investor_contacts_name": "Investor Contact's Name",
    "investor_contacts_position": "Investor Contact's Position",
    "investor_contacts_email_address": "Investor Contact's Email Address",
    "investor_contacts_phone_number": "Investor Contact's Phone Number",
    "subscribed_package": "Subscribed Package",
    "regulated_market": "Regulated Market",
    "sme": "SME",
    "advisory": "Advisory",
    "discard": "Discard",
    "save": "Save",
    "email_address": "Email Address",
    "tax_number": "Tax Number",
    "data_protection_officer": "Data Protection Officer",
    "next": "Next",
    "loading": "Loading",
    "save_selected_representatives": "Save selected representatives",
    "items_per_page": "Items per page",
    "page": "Page",
    "board_member": "Board member",
    "representative": "representative",
    "position_at_client": "Position at Client",
    "first_name": "First Name",
    "last_name": "Last Name",
    "phone_number": "Phone Number",
    "id_number": "ID Number",
    "place_of_birth": "Place of Birth",
    "date_of_birth": "Date of Birth",
    "date_of_inclusion": "Date of Inclusion",
    "send_a_mar_notification": "Send a MAR notification at the same time as recording"
  },
  "new-info-qualifications": {
    "reclassify_the_information": "Reclassify The Information",
    "requalification_of_information": "Qualification of New Information",
    "project_name": "Project Name",
    "please_indicate_the_name_of_the_project": "Please indicate the name of the project (e.g. merger, project MABU, etc.). It is important that this field cannot contain any inside information.",
    "description_of_the_information": "Description of the Information to be Qualified",
    "current_date_and_time": "Current Date & Time",
    "it_can_be_modified": "(it can be modified)",
    "uploaded_files": "Uploaded Files",
    "upload_file": "Upload File",
    "attention_the_insider_information_is_being_created": "Attention! This insider information is being created, please do not close the window, or proceed anywhere!",
    "attention_the_selected_files_are_uploading": "Attention! The selected files are uploading to the server, please do not close the window, or proceed anywhere!",
    "discard": "Discard",
    "classify_information": "Classify The Information",
    "what_do_you_want_to_do_with_the_information": "What do you want to do with the information?",
    "delay": "Delay",
    "immediate_publication": "Immediate Publication",
    "this_information_does_not_qualify": "This information does not qualify as inside information. Do you want to create a confidential list?",
    "no": "No",
    "yes": "Yes",
    "attention_you_have_not_qualified": "Attention! You have not qualified all the options! Please fill out every necessary fields!",
    "ok": "Ok",
    "files_uploaded_successfully": "Files uploaded successfully!",
    "successfully_created": "Successfully created!",
    "edit_the_information": "Edit the information",
    "confirm_edit_the_information": "Confirm Edit the information",
    "do_you_want_to_Reclassify_the_information": "Do you want to Reclassify the information",
    "save_changes": "Save Changes"
  },
  "new-password": {
    "test": ""
  },
  "non-market-data-room-details": {
    "market_sounding_data_room": "Market Sounding Data Room - { dataroom }",
    "non_market_sounding_data_room": "Non-Market Sounding Data Room - { dataroom }",
    "uploaded_files": "Uploaded Files",
    "people_with_access": "People with Access",
    "upload_new_documents": "Upload New Document(s)",
    "delete": "Delete",
    "download": "Download",
    "download_document_list": "Download Document List",
    "download_all_files_zip": "Download All Files (.zip)",
    "file_name": "File Name",
    "time_of_upload": "Time of Upload",
    "folder_name": "Folder Name",
    "open_folder": "Open Folder",
    "create_new_folder": "Create New Folder",
    "create": "Create",
    "discard": "Discard",
    "rename": "Rename",
    "this_file_contains_inside_information": "This file contains inside information.",
    "select_insider_information_from_the_list": "Select Insider Information from the List!",
    "insider_information_related_to_the_file": "Insider information related to the file.",
    "upload_file": "Upload File",
    "yes": "Yes",
    "no": "No",
    "please_select_the_files": "Please select the files that contain inside information!",
    "upload_files": "Upload Files",
    "please_select_the_file_list": "Please check the file list before uploading!",
    "name": "Name",
    "email_address": "Email Address",
    "grant_access": "Grant Access",
    "take_away_access_from_everyone": "Take Away Access From Everyone",
    "take_away_access": "Take Away Access",
    "email_notifications": "E-mail Notification(s)",
    "acknowledgement": "Acknowledgement",
    "download_list_of_people_with_access": "Download List of People with Access",
    "download_notification_emails_and_statements": "Download Notification Emails and Statements",
    "personal_data": "Personal Data",
    "notification_status": "Notification Status",
    "statement_of_consent_status": "Statement of Consent Status",
    "termination_of_access": "Termination of access",
    "telephone_number": "Telephone Number",
    "dt_placeholder": "dd-mm-yyyy; hh:mm",
    "the_person_to_be_added": "The person to be added to this data room is not yet on the insider list in respect of the following inside information",
    "name_of_inside_information": "Name of Inside Information",
    "notification_and_insider_listing": "Notification & Insider Listing",
    "address": "Address",
    "subject": "Subject",
    "body": "Body",
    "email": "Email",
    "inclusion_email_attachments": "Attachments (These files can be downloaded and attached to the notification email)",
    "send": "Send",
    "first_name": "First Name",
    "last_name": "Last Name",
    "phone_number": "Phone Number"
  },
  "packages": {
    "subscribed_package_and_additional_services": "Subscribed Package and Additional Services",
    "other_package_offers": "Other Package Offers",
    "request": "Request",
    "package_name": "Package Name",
    "function_module": "Function Module",
    "optional": "Optional"
  },
  "pca": {
    "pdmr_pca_list": "PDMR / PCA List",
    "pdmr_information": "PDMR Information",
    "name": "Name",
    "position_at_client": "Position at Client",
    "email_address": "Email Address",
    "phone_number": "Phone Number",
    "id_number": "ID Number",
    "place_of_birth": "Place of Birth",
    "date_of_birth": "Date of Birth",
    "time_of_inclusion": "Time of Inclusion",
    "modify": "Modify",
    "discard": "Discard",
    "save": "Save",
    "add_a_new_pca": "Add a New PCA",
    "download_all_notifications": "Download All Notifications",
    "pca_name": "PCA Name",
    "pca_email_address": "PCA Email Address",
    "reported_transactions": "Reported Transactions",
    "notify_new_transaction": "Notify New Transaction",
    "download_application": "Download Application",
    "periods_covered_by_the_trade_ban": "Periods Covered by The Trade Ban",
    "requests_on_trading_ban_exemption": "Requests on Trading Ban Exemption",
    "new_application": "New Application",
    "download": "Download",
    "insider_list": "Insider list",
    "market_sounding_data_room": "Market Sounding Data Room",
    "non_market_sounding_data_room": "Non-Market Sounding Data Room",
    "data_room_name": "Data Room Name",
    "transaction_details": "Transaction Details",
    "notification_date": "Notification Date",
    "request_id": "Request ID",
    "date": "Date",
    "approved_rejected": "Approved/Rejected",
    "request_sent": "Request Sent",
    "request_link": "Request Link",
    "function_and_reason_for_being_insider_placeholder": "Function and Reason",
    "new_transaction": "New Transactions"
  },
  "pdmr-list": {
    "pdmr_pca_list": "PDMR / PCA List",
    "notifications": "Notifications",
    "add_new": "Add New",
    "add_new_pdmr": "Add New PDMR",
    "add_new_pca": "Add New PCA",
    "position_at_client": "Position at Client",
    "phone_number": "Phone Number",
    "place_of_birth": "Place of Birth",
    "date_of_birth": "Date of Birth",
    "id_number": "ID Number",
    "date_of_inclusion": "Date of Inclusion",
    "registered_seat": "Registered Seat",
    "registration_number": "Registration Number",
    "download_list": "Download List",
    "pdmr_pca_data": "PDMR/PCA Data",
    "date_completeness": "Data Completeness",
    "notification_status": "Notification Status",
    "successful_modification": "Successful Modification",
    "successful_deletion": "Successful Deletion",
    "modify": "Modify",
    "save": "Save",
    "discard": "Discard",
    "delete": "Delete",
    "back": "Back",
    "first_name": "First Name",
    "last_name": "Last Name",
    "email_address": "Email Address",
    "sync_data": "Sync Data",
    "attention_data_is_not_up_to_date": "Attention! Data is not up to date.",
    "do_you_want_to_delete_this_pdmr_pca": "Do you want to delete this PDMR/PCA?",
    "related_pdmr": "Related PDMR",
    "please_choose": "Please Choose...",
    "default_select_example": "Default select example",
    "nature_of_the_relationship": "Nature of the relationship",
    "please_choose_a_pdmr": "Please choose a PDMR and a type of relation!",
    "next": "Next",
    "email": "Email",
    "subject": "Subject",
    "body": "Body",
    "inclusion_email_attachments": "Inclusion Email Attachments",
    "uploaded_files": "Uploaded Files",
    "upload_file": "Upload File",
    "send": "Send",
    "company_name": "Company Name",
    "companys_registration_number": "Company's Registration Number",
    "notify_pdrm": "Notify PDMR of his/her obligations under MAR.",
    "entry_of_the_pca_in_the_register": "Entry of the PCA in the register",
    "pursuant_to_article_19_5": "Pursuant to Article 19(5), issuers shall notify the PDMRs of their obligations under MAR in writing. Are you sure you do not want to send this notification?",
    "decrypt_request": "Decrypt Request",
    "request_sent": "Request sent",
    "up_to_date": "Up to date",
    "loading": "Loading",
    "save_selected_representatives": "Save selected representatives",
    "items_per_page": "Items per page",
    "page": "Page",
    "board_member": "Board member",
    "representative": "representative",
    "name_of_the_person": "Name of the Person",
    "no_data_to_show": "No data to show",
    "download_all_notifications": "Download All Notification"
  },
  "pdmr": {
    "pdmr_pca_list": "PDMR / PCA List",
    "pdmr_information": "PDMR Information",
    "name": "Name",
    "position_at_client": "Position at Client",
    "email_address": "Email Address",
    "phone_number": "Phone Number",
    "id_number": "ID Number",
    "place_of_birth": "Place of Birth",
    "date_of_birth": "Date of Birth",
    "time_of_inclusion": "Time of Inclusion",
    "modify": "Modify",
    "discard": "Discard",
    "save": "Save",
    "add_a_new_pca": "Add a New PCA",
    "download_all_notifications": "Download All Notifications",
    "pca_name": "PCA Name",
    "pca_email_address": "PCA Email Address",
    "reported_transactions": "Reported Transactions",
    "notify_new_transaction": "Notify New Transaction",
    "download_application": "Download Application",
    "periods_covered_by_the_trade_ban": "Periods Covered by The Trade Ban",
    "requests_on_trading_ban_exemption": "Requests on Trading Ban Exemption",
    "new_application": "New Application",
    "download": "Download",
    "insider_list": "Insider list",
    "market_sounding_data_room": "Market Sounding Data Room",
    "non_market_sounding_data_room": "Non-Market Sounding Data Room",
    "data_room_name": "Data Room Name",
    "transaction_details": "Transaction Details",
    "notification_date": "Notification Date",
    "request_id": "Request ID",
    "date": "Date",
    "approved_rejected": "Approved/Rejected",
    "request_sent": "Request Sent",
    "request_link": "Request Link",
    "function_and_reason_for_being_insider_placeholder": "Function and Reason"
  },
  "period-ban": {
    "fixing_the_period_covered_by_the_trading_ban": "Fixing the period covered by the trading ban",
    "start_date_of_publication_of_half_yearly": "Start date of publication of half-yearly financial reports:",
    "date_of_publication_of_half_yearly": "Date of publication of half-yearly figures:",
    "end_date_of_publication_of_half_yearly": "End date of publication of half-yearly financial reports:",
    "start_date_of_publication_of_annual": "Start date of publication of annual financial reports:",
    "date_of_publication_of_annual": "Date of publication of annual figures:",
    "end_date_of_publication_of_annual": "End date of publication of annual financial reports:",
    "save": "Save",
    "send_emails_automatically": "Send emails automatically",
    "days_before_the_start_of_closed_period": "day(s) before the start of closed period.",
    "list_of_persons_to_be_notified": "List of persons to be notified",
    "date_sent": "Date Sent",
    "previous_notifications": "Previous Notifications",
    "requests_on_trade_ban_excemptions": "Requests on Trading Ban Exemption",
    "reject": "Reject",
    "accept": "Accept",
    "are_you_sure_you_want_to_accept_the_excemption": "Are you sure you want to accept the exemption?",
    "are_you_sure_you_want_to_reject_the_excemption": "Are you sure you want to reject the exemption?",
    "back": "Back",
    "pdmr_name": "PDRM Name",
    "email_address": "Email Address",
    "time_of_notification": "Time of Notification",
    "automatic_email_notifications": "Automatic Email Notifications",
    "name": "Name",
    "request_link": "Request Link",
    "dt_placeholder": "yyyy.mm.dd hh:mm",
    "successfully_updated_period": "Successfully updated period!",
    "ban_period_successfully_updated": "Ban period successfully updated!",
    "successfully_updated_notification_state": "Successfully updated notification state!",
    "successfully_accepted_excemption_request": "Successfully accepted exemption request!",
    "successfully_rejected_excemption_request": "Successfully rejected exemption request!",
    "there_is_no_notification": "There is no notification to show",
    "time_period_should_be_updated": "Time period should be updated"
  },
  "privacy-policy": {
    "test": ""
  },
  "published-reports": {
    "unpublished_pdmr_pca_reports": "Unpublished PDMR / PCA reports",
    "search": "Search",
    "provide_publishing_information": "Provide Publishing Information",
    "published_pdmr_pca_reports": "Published PDMR / PCA reports",
    "posted_link": "Posted Link",
    "name_of_applicant": "Name of Applicant",
    "time_of_notification": "Time of Notification",
    "pdmr_pca": "PDRM / PCA",
    "view_form": "View Form",
    "dt_placeholder": "yyyy.mm.dd hh:mm"
  },
  "reset-password": {
    "reset_your_password": "Reset your password",
    "enter_your_registered_email_address": "Enter your registered E-mail & we will send to you a password reset link.",
    "new_password": "New password",
    "reset_password": "Reset password",
    "email_address": "Email Address",
    "enter_your_new_password": "Enter your new password",
    "confirm_password": "Confirm password",
    "repeat_password": "Repeat password",
    "dont_have_an_account": "Don't have an account?",
    "sign_up": "Sign up",
    "email_hone_number": "e-mail / phone number",
    "email_address_phone_number": "Email Address / Phone Number",
    "send_code": "Send Code",
    "didnt_receive_code_yet": "Didn't receive code yet?",
    "resend_link": "Resend link",
    "a_reset_link_has_been_sent_to_your_email_address": "A reset link has been sent to your email address."
  },
  "reset-sent": {
    "reset_your_password": "Reset your password",
    "didnt_receive_code_yet": "Didn't receive code yet?",
    "resend_link": "Resend link",
    "a_reset_link_has_been_sent_to_your_email_address": "A reset link has been sent to your email address."
  },
  "serviceprovider": {
    "test": ""
  },
  "settings": {
    "change_password": "Change Password",
    "old_password": "Old Password",
    "password": "Password",
    "confirm_password": "Confirm Password",
    "company_name": "Company Name",
    "company_headquarters": "Company Headquarters",
    "automatically_log_off_users_after": "Automatically log off users after",
    "input": "Input",
    "minutes_of_inactivity": "minutes of inactivity.",
    "change_data": "Change Data",
    "client_decrypt_request": "Client Decrypt Request",
    "login_history": "Login history",
    "permanently_delete_profile": "Permanently Delete Profile",
    "delete_my_account": "Delete My Account",
    "site_documents": "Site documents",
    "document_title": "Document Title",
    "document_type": "Document Type",
    "document_content": "Document Content",
    "date": "Date",
    "edit_document": "Edit document",
    "from_date": "From Date",
    "document_title_required": "Document title is required",
    "from_date_required": "From date is required",
    "document_content_required": "Document content is required",
    "create_document": "Create document",
    "delete_document": "Delete document",
    "name": "Name",
    "position": "Position",
    "email": "Email",
    "phone_number": "Phone Number",
    "company_registration_number": "Company Registration Number",
    "headquarters": "Headquarters",
    "tax_number": "Tax Number",
    "lei_number": "LEI Number",
    "data_protection_officer": "Data Protection Officer",
    "client_name": "Client Name"
  },
  "sidebar": {
    "classification_and_delay_of_inside_info": "Classification and Delay of Inside Info.",
    "insider_list": {
      "title": "Insider List",
      "submenu": {
        "permanent_insider_list": "Permanent Insider List",
        "transaction-specific_insiders_list": "Transaction-Specific Insiders List",
        "confidential_list": "Confidential List"
      }
    },
    "management_transactions": {
      "title": "Management Transactions",
      "submenu": {
        "record-keeping": "Record-keeping",
        "transaction_reporting_for_executives_and_close_associates": "Transaction reporting for executives and close associates",
        "trading_ban_period": "Trading Ban Period"
      }
    },
    "transfer_of_inside_info": "Transfer of Inside Info.",
    "transaction_form": "Transaction form",
    "dashboard": "Dashboard"
  },
  "signup": {
    "signup_to_mabureg": "Signup to MABUREG",
    "welcome_aboard_lets_begin": "Welcome aboard, Let’s begin.",
    "investor_contacts_email": "Investor Contact's email",
    "investor_contacts_password": "Investor Contact's password",
    "clients_name": "Client's name",
    "clients_address": "Client's address",
    "investor_contacts_name": "Investor Contact's Name",
    "investor_contacts_position": "Investor Contact's Position",
    "investor_contacts_phone_number": "Investor Contact's Phone Number",
    "company_registration_number": "Company Registration Number",
    "tax_number": "Tax Number",
    "lei_number": "LEI Number",
    "delegate_access_to_other_people": "Delegate access to other people",
    "add_decision_maker": "Add Decision Maker",
    "next": "Next"
  },
  "site-document-create": {
    "document_title": "Document Title",
    "document_type": "Document Type",
    "document_content": "Document Content",
    "date": "Date",
    "create_document": "Create document",
    "from_date": "From Date",
    "document_title_required": "Document title is required",
    "from_date_required": "From date is required",
    "document_content_required": "Document content is required",
    "save": "Save",
    "cancel": "Cancel",
    "privacy_policy": "Privacy Policy",
    "terms_and_conditions": "Terms and Conditions"
  },
  "site-document-edit": {
    "document_title": "Document Title",
    "document_type": "Document Type",
    "document_content": "Document Content",
    "date": "Date",
    "edit_document": "Edit document",
    "from_date": "From Date",
    "document_title_required": "Document title is required",
    "from_date_required": "From date is required",
    "document_content_required": "Document content is required",
    "save": "Save",
    "cancel": "Cancel",
    "privacy_policy": "Privacy Policy",
    "terms_and_conditions": "Terms and Conditions"
  },
  "site-documents": {
    "site_documents": "Site documents",
    "create_document": "Create document",
    "edit": "Edit",
    "delete": "Delete",
    "delete_document": "Delete document",
    "cancel": "Cancel",
    "document_title": "Document title",
    "document_type": "Document type",
    "date": "Date"
  },
  "specific-insider-details": {
    "short_name_of_insider_information": "Short Name of Insider Information",
    "date_placeholder": "DD/MM/YYYY",
    "dt_of_creation_of_permanent_insider_section": "(Date & Time of creation of the permanent insiders section)",
    "dt_of_last_update": "Date & Time of the last update",
    "date_of_transmission_to_competent_authority": "Date of transmission to the competent authority",
    "project_name": "Project Name",
    "inside_information": "Inside Information",
    "add_new_person": "Add New Person",
    "export_insider_list": "Export Insider List",
    "delete": "Delete",
    "first_name": "First Name",
    "surname": "Surname",
    "birth_surname": "Birth Surname",
    "professional_telephone_number": "Professional Telephone Number",
    "companys_name": "Company's Name",
    "companys_address": "Company's Address",
    "function_and_reason_for_being_insider": "Function and reason for being insider",
    "function_and_reason_for_being_insider_title": "Text describing the role, function and reason for inclusion in this list",
    "function_and_reason_for_being_insider_placeholder": "Function and Reason",
    "included": "Included",
    "obtained": "Obtained",
    "ceased": "Ceased",
    "birthdate": "Birthdate",
    "national_id_card_number": "National ID Card Number",
    "personal_telephone_number": "Personal Telephone Number",
    "personal_full_home_address": "Personal Full Home Address",
    "personal_full_home_address_title": "Country, postcode, city, street, house number",
    "discard": "Discard",
    "save_data": "Save Data",
    "this_insider_information_was_disclosed": "This inside information was disclosed in the course of market sounding.",
    "sending_the_notice_is_a_legal_obligation": "Sending the notice is a legal obligation. Are you sure you do not want to send the notification?",
    "name_of_the_insider": "Name of the Insider",
    "data_completeness": "Data Completeness",
    "last_login": "Last Login",
    "do_you_want_to_delete_this_insider": "Do you want to delete this insider?",
    "back": "Back",
    "modify": "Modify",
    "deal_specific_insider_list": "Deal-Specific Insider List",
    "insider_list": "Insider list",
    "notifications_and_acknowledgements": "Notifications and Acknowledgments",
    "mar_18_notifications": "MAR 18(2) Notifications",
    "mar_18_acknowledgements": "MAR 18(2) Acknowledgements",
    "download_all_acknowledgements": "Download All Acknowledgements",
    "download_all_notification_emails": "Download All Notification Emails",
    "name_of_insider": "Name of Insider",
    "email_address": "Email Address",
    "date_and_time_of_creation": "Date and Time of Creation"
  },
  "specific-insiders": {
    "closed_insider_lists": "Closed insider lists",
    "current_insider_lists": "Current insider lists",
    "search": "Search",
    "download": "Download",
    "details": "Details",
    "inside_information": "Inside Information",
    "project_name": "Project Name",
    "time_of_creation": "Time of Creation",
    "number_of_persons_included": "Number of Persons Included",
    "date_of_closure": "Date of Closure",
    "short_name_of_insider_information": "Short Name of Insider Information",
    "dt_placeholder": "dd-mm-yyyy; hh:mm",
    "number_of_people": "Number of People"
  },
  "subscriptions": {
    "subscribed_package": "Subscribed Package",
    "subscribed_package_name": "Subscribed Package Name",
    "download_content": "Download Content",
    "change_subscription": "Change Subscription",
    "my_accounts": "My Accounts",
    "seach_accounts_by_number": "Search accounts by Number",
    "account_number": "Account Number",
    "date_of_issue": "Date of Issue",
    "date_of_completion": "Date of Completion",
    "download_request": "Download Request",
    "download_invoice": "Download Invoice"
  },
  "terms-and-conditions": {
    "test": ""
  },
  "terms": {
    "please_read_the_terms_carefully": "Please read the terms carefully",
    "please_provide_us_with_the_following": "Please provide us with the following data",
    "phone_number": "Phone Number",
    "address": "Address",
    "go_to_the_data_room": "Go To The Data Room"
  },
  "thank-you": {
    "thank_you": "Thank you!",
    "i_agree_to_privacy_policy": "I agree to Privacy Policy { link }",
    "back": "Back",
    "next": "Next"
  },
  "unauthorized": {
    "unauthorized": "Unauthorized",
    "it_seems_like_you_dont_have_access": "It seems like you don't have access to this page",
    "go_back": "Go back",
    "report": "Report"
  },
  "upload-application": {
    "upload_application": "Upload Application",
    "file_name": "File Name",
    "proceed": "Proceed"
  },
  "verify-code": {
    "login_to_mabureg": "Login to MABUREG",
    "a_verification_code_has_been_sent": "A verification code has been sent to your email / phone. This code is valid for 10 minutes.",
    "code": "Code",
    "verify_login": "Verify & Login"
  }
}